import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  Upload,
  message,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PER_ADD_USER, PER_DELETE_USER } from "../../common/cms_const";
import { hasPermission, renderHTML } from "../../common/common_utils";
import { HolidayModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addHoliday,
  deleteHoliday,
  getlistHoliday,
  holidaySelector,
  setRequestStatus,
  updateHoliday,
} from "../../store/slices/holiday-slide";

const Holiday: React.FC = () => {
  const dispatch = useDispatch();
  const { appLoading, permissions } = useSelector(appSelector);
  const { listHoliday, requestStatus } = useSelector(holidaySelector);
  const [fileList, setFileList] = useState<any[]>([]);
  const [formTitle, setFormTitle] = useState("");
  const [detailInfo, setDetailInfo] = useState<any>();
  const [viewVisible, setViewVisible] = useState(false);
  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
  });

  const handleFileChange = (uploadData: any) => {
    console.log(uploadData);
    setFileList(uploadData.fileList);
  };

  useEffect(() => {
    dispatch(getlistHoliday(requestParams));
  }, []);

  useEffect(() => {
    if (requestStatus) {
      dispatch(getlistHoliday(requestParams));
      setVisible(false);
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderImage = (record: any) => {
    if (record.images) {
      return (
        <img style={{ width: "50px" }} src={process.env.REACT_APP_API_SERVER_URL + record.images} />
      );
    } else {
      return "";
    }
  };

  const onFinish = (data: any) => {
    console.log(data);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    if (data.images) {
      data.images = data.images.file;
    } else {
      if (fileList.length > 0) {
        data.images = fileList[0].name;
      } else {
        data.images = undefined;
      }
    }

    data.timing = moment(data.timing).format("YYYY-MM-DD");
    if (data.id) {
      dispatch(updateHoliday(data, config));
    } else {
      dispatch(addHoliday(data, config));
    }
  };

  const renderTiming = (record: any) => {
    if (record.timing) {
      const timing = moment(record.timing);
      var month = timing.format("MM");
      var day = timing.format("DD");

      let today = moment();
      var cMonth = today.format("MM");
      var cDay = today.format("DD");
      var cYear = today.format("YYYY");
      let fDate = "";

      let year = "";
      if (parseInt(cMonth) < parseInt(month)) {
        year = cYear;
      } else if (parseInt(cMonth) == parseInt(month)) {
        if (parseInt(cDay) <= parseInt(day)) {
          year = cYear;
        } else {
          year = parseInt(cYear) + 1 + "";
        }
      } else {
        year = cYear;
      }

      fDate = year + "-" + month + "-" + day;
      today = today.add(-1, "days");
      const timingCompare = moment(fDate, "YYYY-MM-DD");
      let days = timingCompare.diff(today, "days");
      if (days < 0) {
        days = 365 + days;
      }

      let color = "green";
      let text = `Còn ${days} ngày`;
      return (
        <>
          {moment(record.timing).format("DD/MM")}
          <br />
          <Tag color={color}>{text}</Tag>
        </>
      );
    } else {
      return "";
    }
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    setVisible(true);
    setFormTitle("Update");

    let listImg: any[] = [];
    if (data.images) {
      const fileData = {
        status: "done",
        name: data.images,
        thumbUrl: process.env.REACT_APP_API_SERVER_URL + data.images,
        url: process.env.REACT_APP_API_SERVER_URL + data.images,
      };
      listImg.push(fileData);
    }
    setFileList(listImg);

    form.setFieldsValue({
      id: data.id,
      name: data.name,
      description: data.description,
      quotes: data.quotes,
      timing: moment(data.timing),
      country: data.country,
      images: undefined,
      activity: data.activity,
    });
  };

  const columns: ColumnsType<HolidayModel> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 80,
      responsive: ["md"],
    },
    {
      title: "Action",
      width: 80,
      render: (_, record) => (
        <>
          <EyeOutlined onClick={() => showDetail(record)} />
          <EditOutlined style={{ marginLeft: 10 }} onClick={() => loadUpdate(record)} />
        </>
      ),
    },
    {
      title: "Tên",
      width: 250,
      dataIndex: "name",
      render: (_, record) => (
        <>
          <a onClick={() => loadUpdate(record)}>{record.name}</a>
        </>
      ),
    },
    {
      title: "Thời gian",
      width: 100,
      render: (_, record) => <>{renderTiming(record)}</>,
    },
    {
      width: 600,
      title: "Ý nghĩa",
      dataIndex: "description",
    },
    {
      title: "Quotes",
      dataIndex: "quotes",
    },
    {
      title: "Các hoạt động",
      dataIndex: "activity",
    },
    {
      title: "Ảnh mãu",
      width: 100,
      dataIndex: "group_name",
      render: (_, record) => <>{renderImage(record)}</>,
    },
    {
      width: 150,
      title: "Quốc gia",
      dataIndex: "country",
    },
  ];

  const onChange: TableProps<HolidayModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
    setFormTitle("Thêm mới");
    setFileList([]);
    form.setFieldsValue({
      id: "",
      name: "",
      activity: "",
      description: "",
      quotes: "",
      timing: "",
      country: "",
      images: undefined,
    });
  };

  const onClose = () => {
    setVisible(false);
    setViewVisible(false);
  };

  const showDetail = (data: any) => {
    setViewVisible(true);
    setFormTitle("Chi tiết");
    setDetailInfo(data);
    console.log(data);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một item.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        dispatch(deleteHoliday(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onSearch = (keyword: string) => {
    console.log("");
    const searchRequest = {
      keyword: keyword,
    };
    dispatch(getlistHoliday(searchRequest));
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Danh sách các ngày lễ</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            {hasPermission(permissions, PER_ADD_USER) && (
              <Button
                onClick={showDrawer}
                type="primary"
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
              >
                Thêm mới
              </Button>
            )}

            {hasPermission(permissions, PER_DELETE_USER) && (
              <Button
                type="primary"
                onClick={showConfirm}
                style={{ marginBottom: 16 }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            )}
          </Space>
          <Space direction="horizontal" style={{ float: "right" }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
          </Space>

          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listHoliday}
            onChange={onChange}
            scroll={{ x: 2200 }}
          />

          <Drawer
            title={formTitle}
            width={900}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col md={24} xs={24}>
                    <Form.Item
                      name="name"
                      label="Tên"
                      rules={[{ required: true, message: "Tên là thông tin bắt buộc." }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="timing"
                      label="Thời điểm diễn ra"
                      rules={[{ required: true, message: "Thời điểm là thông tin bắt buộc." }]}
                    >
                      <DatePicker
                        placeholder="Chọn thời điểm"
                        format={"YYYY/MM/DD"}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item name="country" label="Quốc gia">
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col md={24} xs={24}>
                    <Form.Item name="description" label="Ý nghĩa">
                      <Input.TextArea rows={4} placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item name="activity" label="Các hoạt động">
                      <Input.TextArea rows={3} placeholder="" />
                    </Form.Item>
                  </Col>

                  <Col md={12} xs={24}>
                    <Form.Item name="quotes" label="Quotes">
                      <Input.TextArea rows={3} placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <div className="clearfix">
                      <Form.Item name="images" label="Ảnh mẫu">
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          beforeUpload={() => false}
                          onChange={handleFileChange}
                        >
                          {fileList.length >= 1 ? null : (
                            <div>
                              <PlusOutlined />
                              <div className="ant-upload-text">Upload</div>
                            </div>
                          )}
                        </Upload>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>

          <Drawer
            title={formTitle}
            width={1024}
            onClose={onClose}
            visible={viewVisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Descriptions bordered labelStyle={{ fontWeight: "600" }}>
              <Descriptions.Item label="Tên">{detailInfo?.name}</Descriptions.Item>
              <Descriptions.Item label="Thời gian">
                {moment(detailInfo?.timing).format("DD/MM/YYYY")}
              </Descriptions.Item>
              <Descriptions.Item label="Quốc gia">{detailInfo?.country}</Descriptions.Item>
              <Descriptions.Item label="Ảnh mẫu" span={3}>
                <Image width={80} src={process.env.REACT_APP_API_SERVER_URL + detailInfo?.images} />
              </Descriptions.Item>
              <Descriptions.Item label="Ý nghĩa" span={3}>
                {renderHTML(detailInfo?.description)}
              </Descriptions.Item>
              <Descriptions.Item label="Quotes" span={3}>
                {renderHTML(detailInfo?.quotes)}
              </Descriptions.Item>
              <Descriptions.Item label="Các hoạt động" span={3}>
                {renderHTML(detailInfo?.activity)}
              </Descriptions.Item>
            </Descriptions>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default Holiday;

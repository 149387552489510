import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04, MSG_05 } from "../../common/cms_message";
import { getMessage, show_error_msg } from "../../common/common_utils";
import { ImportTaskModel, IProductResponse, ITaskResponse, ProductModel } from "../../models/models";
import api from "../../services/api";
import { setLoading, setPermalinkBase } from "./app-slice";

export interface IProduct {
  requestStatus: boolean,
  addProductStatus: boolean,
  listProducts: ProductModel[]
  totalProduct: number,
  updateStatus: boolean;
  listTasks: ImportTaskModel[]
}

// Khởi tạo state cho slice
const initialState: IProduct = {
  requestStatus: false,
  addProductStatus: false,
  listProducts: [],
  totalProduct: 0,
  updateStatus: false,
  listTasks: []
};

// Cấu hình slice
export const productSlice = createSlice({
  name: "product",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setUpdateStatus: (state, action: PayloadAction<boolean>) => {
      state.updateStatus = action.payload
    },
    setAddProductStatus: (state, action: PayloadAction<boolean>) => {
      state.addProductStatus = action.payload
    },
    setListProducts: (state, action: PayloadAction<ProductModel[]>) => {
      state.listProducts = action.payload
    },
    setTotalProducts: (state, action: PayloadAction<number>) => {
      state.totalProduct = action.payload
    },
    setListImportTasks: (state, action: PayloadAction<any>) => {
      state.listTasks = action.payload
    },
  }
});

export const productSelector = (state: RootState): IProduct => state.product

// =========================
/// Actions
// =========================
export const {
  setListProducts,
  setAddProductStatus,
  setRequestStatus,
  setTotalProducts,
  setUpdateStatus,
  setListImportTasks
} = productSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListProducts = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<IProductResponse>(`/api/product/${data.storeId}`, {
      params: data
    });
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))

    dispatch(setTotalProducts(response.data.total))
    dispatch(setPermalinkBase(response.data.permalink_base))
    dispatch(setListProducts(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const addProduct = (data: ProductModel): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IProductResponse>(`/api/product/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["product"]));
      dispatch(setRequestStatus(true));
      dispatch(setAddProductStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateProduct = (data: ProductModel): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.patch<IProductResponse>(`/api/product/${data.id}/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["product"]));
      dispatch(setRequestStatus(true));
      dispatch(setAddProductStatus(true));
    } else {
      show_error_msg(response.data)
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}


export const bulkUpdateProduct = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IProductResponse>(`/api/product/bulk_update/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["product"]));
      dispatch(setRequestStatus(true));
      dispatch(setAddProductStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateProductStatus = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IProductResponse>(`/api/product/update_product_status/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["product"]));
      dispatch(setUpdateStatus(true));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const schedulePost = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IProductResponse>(`/api/product/schedule/`, data);
    if (response.data.result == "ok") {
      dispatch(setRequestStatus(true));
      message.success(getMessage(MSG_05));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteProduct = (selectedStore: string, param: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.delete<IProductResponse>(`/api/product/${selectedStore}`, {
      data: {
        list_id: param
      }
    });
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["product"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const uploadMedia = (
  file: any,
): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))

    const formData = new FormData()
    formData.append('file', file.originFileObj)
    formData.append('status', file.status)

    // Call api
    const response = await api.post("/api/products/media/", formData)
    if (response.data.result == "ok") {
      message.success('This is a success message');
      // dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const listTasksApi = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<ITaskResponse>(`/api/product/list-tasks`);
    if (response.data.result == "ok") {
      console.log(response.data)
      dispatch(setListImportTasks(response.data.data))
      
      // message.success(getMessage(MSG_03, ["product"]));
      // dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default productSlice.reducer;

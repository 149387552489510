import {
  ApartmentOutlined, ContainerOutlined, PieChartOutlined, QuestionOutlined, RedditOutlined, SettingOutlined, SkinOutlined, SolutionOutlined, UserOutlined
} from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu, Select, SelectProps, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import { PER_ADD_PRODUCT, PER_VIEW_FULLFILL, PER_VIEW_PRODUCT, PER_VIEW_STORE, PER_VIEW_USER, ROLE_ADMIN, ROLE_CONTENT_CREATOR, ROLE_DESIGNER, ROLE_IDEA, ROLE_MANAGER } from './common/cms_const';
import { hasPermission, hasRole } from './common/common_utils';
import { PrivateRoute } from './config/PrivateRoute';
import ApprovedIdea from './pages/business-management/approved_idea';
import CommonTask from './pages/business-management/common_task';
import ContentManager from './pages/business-management/content';
import Design from './pages/business-management/design';
import IdeaSummary from './pages/business-management/ideaSummary';
import ListIdeas from './pages/business-management/listIdea';
import DescriptionComponent from './pages/common-info/description';
import Holiday from './pages/common-info/holiday';
import Niche from './pages/common-info/niche';
import ProductType from './pages/common-info/productType';
import Trending from './pages/common-info/trending';
import Dashboard from './pages/dashboard/dashboard';
import GuideDetailComponent from './pages/guideline/guide_detail';
import GuidelineComponent from './pages/guideline/guideline';
import TrainingComponent from './pages/guideline/training';
import TrainingDetailComponent from './pages/guideline/training_detail';
import Login from './pages/login/login';
import OrderComponent from './pages/order/export_order';
import FullFillComponent from './pages/order/fullfill';
import CategoryComponent from './pages/product/category';
import ImportProductComponent from './pages/product/import_product';
import Product from './pages/product/product';
import SettingDropbox from './pages/setting/setting_dropbox';
import SettingStore from './pages/setting/setting_store';
import PermissionComponent from './pages/user/group';
import ProfileComponent from './pages/user/my_profile';
import User from './pages/user/user';
import { default as TokenService, default as tokenService } from "./services/token.service";
import { appSelector, setDropboxStatus, setPermissions, setUserInfo, updateSelectedStore } from './store/slices/app-slice';
import { authSelector, setAuthenticated } from './store/slices/auth-slide';
import { getListStores, storesSelector } from './store/slices/setting-slide';

const { Header, Content, Footer, Sider } = Layout;


const App: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(authSelector)
  const { appLoading, permissions, userInfo } = useSelector(appSelector)
  const dispatch = useDispatch()
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const { listStores, } = useSelector(storesSelector)
  const [selectedStore, setSelectedStore] = useState<any>();
  const [selectedMenu, setSelectedMenu] = useState<string[]>(["dashboard"]);

  const getRootMenuKey = () => {
    const pathname = window.location.pathname.substring(1)
    if (pathname == 'category' || pathname == 'product'
      || pathname == 'import' || pathname == 'description') {
      return 'product_root'
    }
    else if (pathname == 'user' || pathname == 'permission') {
      return 'user_root'
    }
    else if (pathname == 'fullfill' || pathname == 'order') {
      return 'order_root'
    }
    else if (pathname == 'guideline' || pathname == 'training') {
      return 'guide_root'
    }
    else if (pathname == 'trending' || pathname == 'holiday'
      || pathname == 'niche' || pathname == 'product-type' || pathname == 'description') {
      return 'common_root'
    }
    else if (pathname == 'tasks' || pathname == 'content' || pathname == 'design') {
      return 'business_root'
    }
    else if (pathname == 'idea-summary' || pathname == 'list-idea' || pathname == 'approved-idea') {
      return 'idea_root'
    }
    return ""
  }

  const [selectedParrentMenu, setSelectedParrentMenu] = useState<string[]>([getRootMenuKey()]);


  useEffect(() => {
    console.log("chck1 ", isAuthenticated)
    const pathname = window.location.pathname.substring(1);
    setSelectedMenu([pathname])

    const pers = tokenService.getUserPermissions();
    dispatch(setPermissions(pers))
    const userInfo = localStorage.getItem("user_info");
    if (userInfo) {
      const user = JSON.parse(userInfo);
      dispatch(setUserInfo(user))

      dispatch(getListStores({}));
    }
    const status = localStorage.getItem("dropboxStatus");
    if (status) {
      dispatch(setDropboxStatus(status));
    }
  }, [])

  useEffect(() => {
    console.log("login", isAuthenticated)
    if (isAuthenticated) {
      const pers = tokenService.getUserPermissions();
      dispatch(setPermissions(pers))
      const userInfo = localStorage.getItem("user_info");
      if (userInfo) {
        const user = JSON.parse(userInfo);
        dispatch(setUserInfo(user))
        dispatch(getListStores({}));
      }
      const status = localStorage.getItem("dropboxStatus");
      if (status) {
        dispatch(setDropboxStatus(status));
      }
      // navigate("/dashboard");
    }
  }, [isAuthenticated])



  useEffect(() => {
    if (listStores && listStores.length > 0) {
      const options: SelectProps['options'] = [];
      for (let i = 0; i < listStores.length; i++) {
        options.push({
          label: listStores[i].store_url,
          value: listStores[i].id,
        });
      }
      setOptions(options)

      const data = localStorage.getItem("selectedStore");
      if (data) {
        const selectedStoreLocal = JSON.parse(data);
        let validStore = false;
        for (let i = 0; i < listStores.length; i++) {
          if (listStores[i].id == selectedStoreLocal.value
            && listStores[i].store_url == selectedStoreLocal.label) {
            validStore = true;
            break;
          }
        }
        if (validStore) {
          dispatch(updateSelectedStore(selectedStoreLocal.label, selectedStoreLocal.value));
          setSelectedStore(selectedStoreLocal);
        } else {
          setSelectedStore("");
          dispatch(updateSelectedStore("", ""));
        }
      } else {
        setSelectedStore("");
        dispatch(updateSelectedStore("", ""));
      }
    }
  }, [listStores])

  const handleStoreChange = (value: { value: string; label: string }) => {
    if (value) {
      console.log(value)
      setSelectedStore(value);
      dispatch(updateSelectedStore(value.label, value.value));
      localStorage.setItem("selectedStore", JSON.stringify(value));
    }
  };

  const handleLogout = () => {
    console.log("logout")
    tokenService.removeUser()
    dispatch(setAuthenticated(false))
    navigate('/login');
  };

  const handleUserClick = (data: string) => {
    console.log(data)
    setSelectedMenu([data]);
    if (data === 'user') {
      navigate('/user');
    } else if (data === 'dashboard') {
      navigate('/dashboard');
    } else if (data === 'setting') {
      navigate('/setting');
    } else if (data === 'product') {
      navigate('/product');
    } else if (data === 'order') {
      navigate('/order');
    } else if (data === 'category') {
      navigate('/category');
    } else if (data === 'fullfill') {
      navigate('/fullfill');
    } else if (data === 'guideline') {
      navigate('/guideline');
    } else if (data === 'training') {
      navigate('/training');
    } else if (data === 'import') {
      navigate('/import');
    } else if (data === 'permission') {
      navigate('/permission');
    } else if (data === 'profile') {
      navigate('/profile');
    } else if (data === 'description') {
      navigate('/description');
    } else if (data === 'trending') {
      navigate('/trending');
    } else if (data === 'holiday') {
      navigate('/holiday');
    } else if (data === 'niche') {
      navigate('/niche');
    } else if (data === 'product-type') {
      navigate('/product-type');
    } else if (data === 'idea-summary') {
      navigate('/idea-summary');
    } else if (data === 'list-idea') {
      navigate('/list-idea');
    } else if (data === 'approved-idea') {
      navigate('/approved-idea');
    } else if (data === 'content') {
      navigate('/content');
    } else if (data === 'design') {
      navigate('/design');
    } else if (data === 'tasks') {
      navigate('/tasks');
    } else if (data === 'setting-dropbox') {
      navigate('/setting-dropbox');
    }
  }

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Space onClick={() => handleUserClick("profile")}>
              My profile
            </Space>
          ),
        },
        {
          key: '2',
          label: (
            <Space onClick={handleLogout}>
              Logout
            </Space>
          ),
        },
      ]}
    />
  );

  const daa = TokenService.getUser();
  // const isLoggedIn: boolean = true; // TokenService.getUser() != null;
  const isLoggedIn: boolean = TokenService.getUser() != null;

  return isLoggedIn ? (
    <Layout>
      <Header className="header">
        <Space style={{ float: 'right' }}>
          <Select labelInValue placeholder="Chọn 1 store" value={selectedStore} style={{ width: 200 }} onChange={handleStoreChange} options={options} />
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomLeft"
          >
            <div onClick={e => e.preventDefault()}>
              {/* <Avatar size="large" icon={<UserOutlined />} /> */}
              <Avatar style={{ backgroundColor: '#87d068', marginBottom: 5, marginLeft: 20 }} icon={<UserOutlined />} />
            </div>
          </Dropdown>
        </Space>

      </Header>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider breakpoint="lg"
          collapsedWidth="0"
          className="site-layout-background" style={{ backgroundColor: 'white' }} >
          <Menu mode="inline" defaultSelectedKeys={selectedMenu} selectedKeys={selectedMenu} defaultOpenKeys={selectedParrentMenu}>
            <Menu.Item key="dashboard" onClick={() => handleUserClick("dashboard")}>
              <PieChartOutlined />
              <span> Dashboard</span>
            </Menu.Item>
            {
              hasPermission(permissions, PER_VIEW_STORE) && (
                <Menu.SubMenu title="Setting" key="setting_root" icon={<SettingOutlined />} >
                  <Menu.Item key="setting" onClick={() => handleUserClick("setting")}>Setting Store</Menu.Item>
                  {userInfo.groups.indexOf("Admin") > -1 && (
                    <Menu.Item key="setting-dropbox" onClick={() => handleUserClick("setting-dropbox")}>Setting dropbox</Menu.Item>
                  )}
                </Menu.SubMenu>
              )
            }

            {
              hasPermission(permissions, PER_VIEW_USER) && (
                <Menu.SubMenu title="Quản trị hệ thống" key="user_root" icon={<UserOutlined />} >
                  <Menu.Item key="user" onClick={() => handleUserClick("user")}>Quản lý User</Menu.Item>
                  <Menu.Item key="permission" onClick={() => handleUserClick("permission")}>Quản lý quyền</Menu.Item>
                </Menu.SubMenu>
              )
            }

            {
              // hasPermission(permissions, PER_VIEW_USER) && (
              <Menu.SubMenu title="Thông tin chung" key="common_root" icon={<ContainerOutlined />} >
                <Menu.Item key="trending" onClick={() => handleUserClick("trending")}>Trending</Menu.Item>
                <Menu.Item key="holiday" onClick={() => handleUserClick("holiday")}>Các ngày lễ</Menu.Item>
                <Menu.Item key="niche" onClick={() => handleUserClick("niche")}>Niche</Menu.Item>
                <Menu.Item key="product-type" onClick={() => handleUserClick("product-type")}>Loại sản phẩm</Menu.Item>
                <Menu.Item key="description" onClick={() => handleUserClick("description")}>Description template</Menu.Item>
              </Menu.SubMenu>
              // )
            }

            {
              // hasPermission(permissions, PER_VIEW_USER) && (
              <Menu.SubMenu title="Business Managerment" key="business_root" icon={<ApartmentOutlined />} >
                <Menu.Item key="tasks" onClick={() => handleUserClick("tasks")}>Công việc chung</Menu.Item>
                {
                  (hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER, ROLE_CONTENT_CREATOR)) && (
                    <Menu.Item key="content" onClick={() => handleUserClick("content")}>Content</Menu.Item>
                  )
                }
                {
                  (hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER, ROLE_DESIGNER)) && (
                    <Menu.Item key="design" onClick={() => handleUserClick("design")}>Design</Menu.Item>
                  )
                }

              </Menu.SubMenu>
              // )
            }

            {
              (hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER, ROLE_IDEA)) && (
                <Menu.SubMenu title="Idea" key="idea_root" icon={<RedditOutlined />} >
                  <Menu.Item key="idea-summary" onClick={() => handleUserClick("idea-summary")}>Tổng quan</Menu.Item>
                  <Menu.Item key="list-idea" onClick={() => handleUserClick("list-idea")}>Danh sách idea</Menu.Item>
                  <Menu.Item key="approved-idea" onClick={() => handleUserClick("approved-idea")}>Idea đã duyệt</Menu.Item>
                </Menu.SubMenu>
              )
            }

            {
              (hasPermission(permissions, PER_VIEW_PRODUCT) || hasRole(userInfo?.groups, ROLE_DESIGNER)) && (
                <Menu.SubMenu title="Product" key="product_root" icon={<SkinOutlined />}>
                  {
                    !hasRole(userInfo?.groups, ROLE_DESIGNER) && (
                      <Menu.Item key="category" onClick={() => handleUserClick("category")}>Quản lý category</Menu.Item>
                    )
                  }
                  
                  <Menu.Item key="product" onClick={() => handleUserClick("product")}>Danh sách sản phẩm</Menu.Item>
                  {
                    hasPermission(permissions, PER_ADD_PRODUCT) && (
                      <Menu.Item key="import" onClick={() => handleUserClick("import")}>Import product</Menu.Item>
                    )
                  }
                  {/* {
                    (hasPermission(permissions, PER_ADD_PRODUCT)) && (
                      <Menu.Item key="description" onClick={() => handleUserClick("description")}>Product description</Menu.Item>
                    )
                  } */}

                </Menu.SubMenu>
              )
            }
            {
              hasPermission(permissions, PER_VIEW_FULLFILL) && (
                <Menu.SubMenu title="Order" key="order_root" icon={<SolutionOutlined />} >
                  <Menu.Item key="fullfill" onClick={() => handleUserClick("fullfill")}>Fullfill</Menu.Item>
                  <Menu.Item key="order" onClick={() => handleUserClick("order")}>Export order</Menu.Item>
                </Menu.SubMenu>
              )
            }
            <Menu.SubMenu title="Guide - Training" key="guide_root" icon={<QuestionOutlined />}>
              <Menu.Item key="guideline" onClick={() => handleUserClick("guideline")}>Guide</Menu.Item>
              <Menu.Item key="training" onClick={() => handleUserClick("training")}>Training</Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Spin spinning={appLoading}>
            <Header className="site-layout-background" style={{ padding: 0 }} />
            <Content style={{ margin: '0 16px' }}>
              <Routes >
                <Route path="/user" element={<PrivateRoute> <User /> </PrivateRoute>} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/setting" element={<SettingStore />} />
                <Route path="/product" element={<Product />} />
                <Route path="/order" element={<OrderComponent />} />
                <Route path="/fullfill" element={<FullFillComponent />} />
                <Route path="/category" element={<CategoryComponent />} />
                <Route path="/guideline" element={<GuidelineComponent />} />
                <Route path="/training" element={<TrainingComponent />} />
                <Route path="/import" element={<ImportProductComponent />} />
                <Route path="/permission" element={<PermissionComponent />} />
                <Route path="/guide-detail" element={<GuideDetailComponent />} />
                <Route path="/training-detail" element={<TrainingDetailComponent />} />
                <Route path="/profile" element={<ProfileComponent />} />
                <Route path="/description" element={<DescriptionComponent />} />
                <Route path="/trending" element={<Trending />} />
                <Route path="/holiday" element={<Holiday />} />
                <Route path="/niche" element={<Niche />} />
                <Route path="/product-type" element={<ProductType />} />
                <Route path="/idea-summary" element={<IdeaSummary />} />
                <Route path="/list-idea" element={<ListIdeas />} />
                <Route path="/approved-idea" element={<ApprovedIdea />} />
                <Route path="/content" element={<ContentManager />} />
                <Route path="/design" element={<Design />} />
                <Route path="/tasks" element={<CommonTask />} />
                <Route path="/setting-dropbox" element={<SettingDropbox />} />
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </Layout>
  ) : (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout className="site-layout">
        <Spin spinning={appLoading}>
          <Content style={{ margin: '0 16px' }}>
            <Routes >
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Content>
        </Spin>
      </Layout>
    </Layout>
  );
};

export default App;

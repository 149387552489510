import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Row,
  Space,
  Statistic,
} from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IdeaService from "../../services/idea.service";
import OrderService from "../../services/order.service";
import { appSelector } from "../../store/slices/app-slice";
import { authSelector } from "../../store/slices/auth-slide";

const { RangePicker } = DatePicker;

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(authSelector);
  // const { ideaSummary } = useSelector(ideaSelector)
  const [ideaInfo, setIdeaInfo] = useState<any>();
  const [selectedDay, setSelectedDay] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [selectedYear, setSelectedYear] = useState<any>();
  const [financialReport, setFinancialReport] = useState<any>();
  const { selectedStoreId } = useSelector(appSelector);
  const [dateRange, setDateRange] = useState<any>([]);

  useEffect(() => {
    var currentDate = moment(new Date());
    var preDate = moment(currentDate).add(-1, "M");
    setDateRange([preDate, currentDate]);
    getSummaryInfo(preDate, currentDate);
    loadInitData();
  }, []);

  useEffect(() => {
    loadInitData();
  }, [selectedStoreId]);

  const getSummaryInfo = async (preDate: any, currentDate: any) => {
    const param = {
      start_date: moment(preDate).format("YYYY-MM-DD"),
      end_date: moment(currentDate).format("YYYY-MM-DD"),
    };
    const data = await IdeaService.getSummaryInfo(param);
    setIdeaInfo(data);
  };

  const loadInitData = async () => {
    console.log("loadInitData", selectedStoreId);
    if (!selectedStoreId) {
      return;
    }
    const currentDate = new Date();
    setSelectedMonth(moment(currentDate));
    const param = {
      store_id: selectedStoreId,
      filter_date: moment(currentDate).format("YYYY-MM"),
      filter_type: "month",
    };
    const financialData = await OrderService.getFinancialReport(param);
    setFinancialReport(financialData);
  };

  useEffect(() => {
    if (ideaInfo) {
      setIdeaInfo(ideaInfo);
    }
  }, [ideaInfo]);

  const getFinancialReport = async (param: any) => {
    const financialData = await OrderService.getFinancialReport(param);
    setFinancialReport(financialData);
  };

  const onChange = (date: any, dateString: any, type: any) => {
    console.log(date, dateString);
    if (type == "day") {
      setSelectedDay(date);
      setSelectedMonth(null);
      setSelectedYear(null);
    } else if (type == "month") {
      setSelectedDay(null);
      setSelectedMonth(date);
      setSelectedYear(null);
    } else {
      setSelectedDay(null);
      setSelectedMonth(null);
      setSelectedYear(date);
    }

    const param = { store_id: selectedStoreId, filter_date: dateString, filter_type: type };
    getFinancialReport(param);
  };

  const clearFilterFinancialReport = () => {
    setSelectedDay(null);
    setSelectedMonth(null);
    setSelectedYear(null);
    setSelectedDay(undefined);

    const param = { store_id: selectedStoreId };
    getFinancialReport(param);
  };

  const onDateChange: RangePickerProps["onChange"] = async (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      const param = {
        start_date: moment(dates[0]).format("YYYY-MM-DD"),
        end_date: moment(dates[1]).format("YYYY-MM-DD"),
      };
      const data = await IdeaService.getSummaryInfo(param);
      setIdeaInfo(data);
    } else {
      console.log("Clear");
    }
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Title level={4}>BÁO CÁO TỔNG HỢP</Title>
        <RangePicker onChange={onDateChange} value={dateRange} format="DD/MM/YYYY" />
        {/* <Divider /> */}
        <div className="site-statistic-demo-card" style={{ marginTop: 20 }}>
          <Row gutter={16}>
            <Col span={10}>
              <Row>
                <Col span={12}>
                  <Card>
                    <Statistic
                      title="Tổng số idea"
                      value={ideaInfo?.total_idea.value}
                      valueStyle={{ color: ideaInfo?.total_idea.increase ? "#3f8600" : "red" }}
                      prefix={
                        ideaInfo?.total_idea.increase ? <ArrowUpOutlined /> : <ArrowDownOutlined />
                      }
                      suffix="idea"
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <Statistic
                      title="Idea đã duyệt"
                      value={ideaInfo?.total_approved_idea?.value}
                      valueStyle={{
                        color: ideaInfo?.total_approved_idea?.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_approved_idea?.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="idea"
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="Idea chưa duyệt"
                      value={ideaInfo?.total_unapproved_idea?.value}
                      valueStyle={{
                        color: ideaInfo?.total_unapproved_idea?.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_unapproved_idea?.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="idea"
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="Thiết kế mockup"
                      value={ideaInfo?.total_mockup?.value}
                      valueStyle={{ color: ideaInfo?.total_mockup?.increase ? "#3f8600" : "red" }}
                      prefix={
                        ideaInfo?.total_mockup?.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="idea"
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="Thiết kế file in"
                      value={ideaInfo?.total_file_print?.value}
                      valueStyle={{
                        color: ideaInfo?.total_file_print?.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_file_print?.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="idea"
                    />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col span={14}>
              <Row>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="Tổng số design"
                      value={ideaInfo?.total_design.value}
                      valueStyle={{ color: ideaInfo?.total_design.increase ? "#3f8600" : "red" }}
                      prefix={
                        ideaInfo?.total_design.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="design"
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="Design đã duyệt"
                      value={ideaInfo?.total_approved_design.value}
                      valueStyle={{
                        color: ideaInfo?.total_approved_design.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_approved_design.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="design"
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="Design chưa duyệt"
                      value={ideaInfo?.total_unapproved_design.value}
                      valueStyle={{
                        color: ideaInfo?.total_unapproved_design.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_unapproved_design.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="design"
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="Đã duyệt"
                      value={ideaInfo?.total_approved_mockup.value}
                      valueStyle={{
                        color: ideaInfo?.total_approved_mockup.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_approved_mockup.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="mockup"
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="Chưa duyệt"
                      value={ideaInfo?.total_unapproved_mockup.value}
                      valueStyle={{
                        color: ideaInfo?.total_unapproved_mockup.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_unapproved_mockup.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="mockup"
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="Đã duyệt"
                      value={ideaInfo?.total_approved_file_print.value}
                      valueStyle={{
                        color: ideaInfo?.total_approved_file_print.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_approved_file_print.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="file in"
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="Chưa duyệt"
                      value={ideaInfo?.total_unapproved_file_print.value}
                      valueStyle={{
                        color: ideaInfo?.total_unapproved_file_print.increase ? "#3f8600" : "red",
                      }}
                      prefix={
                        ideaInfo?.total_unapproved_file_print.increase ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="file in"
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Divider />
        <Title level={4}>BÁO CÁO DOANH THU</Title>
        <Space direction="horizontal">
          <DatePicker
            value={selectedDay}
            onChange={(date: any, dateString: any) => onChange(date, dateString, "day")}
          />
          <DatePicker
            value={selectedMonth}
            onChange={(date: any, dateString: any) => onChange(date, dateString, "month")}
            picker="month"
          />
          <DatePicker
            value={selectedYear}
            onChange={(date: any, dateString: any) => onChange(date, dateString, "year")}
            picker="year"
          />
          <Button onClick={clearFilterFinancialReport}>ALL</Button>
        </Space>
        <Descriptions layout="vertical" bordered column={5} style={{ marginTop: 20 }}>
          <Descriptions.Item label="Tổng doanh thu">
            {financialReport?.total_profit}
          </Descriptions.Item>
          <Descriptions.Item label="Payout fee">{financialReport?.payout_fee}</Descriptions.Item>
          <Descriptions.Item label="Phí fulfill">{financialReport?.fullfill_fee}</Descriptions.Item>
          <Descriptions.Item label="Phí phát design">
            {financialReport?.design_fee}
          </Descriptions.Item>
          <Descriptions.Item label="Profit">{financialReport?.profit}</Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};

export default Dashboard;

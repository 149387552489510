import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import jwt_decode from "jwt-decode";
import { AppThunk, RootState } from "..";
import { IUserProfile } from "../../models/models";
import storeService from "../../services/store.service";
import tokenService from "../../services/token.service";
import { setLoading, setPermissions, setUserInfo } from "./app-slice";

export interface IAuth {
  isAuthenticated?: boolean
  userProfile?: IUserProfile
}

const initialState: IAuth = {
  isAuthenticated: false,
  userProfile: undefined,
}

// Cấu hình slice
export const authSlide = createSlice({
  name: "auth",  
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setAuthUser: (state, action: PayloadAction<IUserProfile>) => {
      state.userProfile = action.payload
    },
  }
});

// Hàm này có 1 tham số là root state là toàn bộ state trong store, chạy thử console.log(state) trong nội dung hàm để xem chi tiết
export const authSelector = (state: RootState): IAuth => state.auth

// =========================
/// Actions
// =========================
export const {
  setAuthUser,
  setAuthenticated,
} = authSlide.actions


// =========================
/// Dispatch Actions
// =========================
export const doLogin = (data: any): AppThunk => async dispatch => {
  try {

    // Call api
    dispatch(setLoading(true))
    const response = await storeService.loginApi(data);
    if (response.data.access) {
      tokenService.setUser(response.data);
      
      dispatch(setAuthUser(response.data))
      dispatch(setAuthenticated(true))

      const decoded: any = jwt_decode(response.data.access);
      dispatch(setUserInfo(decoded.user_info))
      tokenService.setUserInfo(decoded.user_info);
      dispatch(setPermissions(decoded.permissions))
      tokenService.setUserPermissions(decoded.permissions);
      tokenService.setDropboxStatus(decoded.dropbox_status);
      
    } else {
      message.error('Username hoặc password không đúng.');
    }
    
  } catch (error) {
    console.log(error);
    // message.error(error + "");
    message.error('Username hoặc password không đúng.');
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default authSlide.reducer;



import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Pagination,
  PaginationProps,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  Upload,
  message,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { PER_DELETE_PRODUCT, PER_UPDATE_PRODUCT } from "../../common/cms_const";
import {
  buildSelectOptions,
  getCategoriesByIds,
  hasPermission,
  renderHTML,
} from "../../common/common_utils";
import SeachableCategory from "../../components/SeachableCategory";
import SeachableTag from "../../components/SeachableTag";
import TextEditor from "../../components/TextEditor";
import {
  ICategoryResponse,
  ProductCategoryModel,
  ProductImageModel,
  ProductModel,
  ProductTagModel,
} from "../../models/models";
import api from "../../services/api";
import { appSelector } from "../../store/slices/app-slice";
import { categorySelector, getListCategories } from "../../store/slices/category-slide";
import { descriptionSelector, getListDesciptions } from "../../store/slices/description-slide";
import {
  addProduct,
  bulkUpdateProduct,
  deleteProduct,
  getListProducts,
  productSelector,
  schedulePost,
  setAddProductStatus,
  setRequestStatus,
  setUpdateStatus,
  updateProduct,
  updateProductStatus,
} from "../../store/slices/product-slide";
import { getListTags, tagSelector } from "../../store/slices/tag-slide";

const { Option } = Select;

const SettingStore: React.FC = () => {
  const dispatch = useDispatch();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ProductModel>>({});
  const { listProducts, totalProduct, requestStatus, addProductStatus, updateStatus } =
    useSelector(productSelector);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [categoryFilterOptions, setCategoryFilterOptions] = useState<any>([]);
  const { listCategories } = useSelector(categorySelector);
  const { listTags } = useSelector(tagSelector);
  const { selectedStoreId, userInfo, permalinkBase } = useSelector(appSelector);
  const [productStatus, setProductStatus] = useState("publish");
  const [selectedCategoties, setSelectedCategoties] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [scheduleTime, setScheduleTime] = useState("");
  const [isDraft, setDraft] = useState(false);
  const { appLoading, permissions } = useSelector(appSelector);
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [updateStatusOptions, setUpdateStatusOptions] = useState<SelectProps["options"]>([]);
  const [visible, setVisible] = useState(false);
  const [getProductParams, setGetProductParams] = useState<any>({
    keyword: "",
    // status: '1',
    storeId: "",
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "createTime",
    sortOrder: "DESC",
    category: "",
    productStatus: "",
    completeStatus: "",
    status: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [filterCategory, setFilterCategory] = useState("");
  const [filterCompleteStatus, setfilterCompleteStatus] = useState("");
  const [filterApproveStatus, setFilterApproveStatus] = useState("");
  const [regularPrice, setRegularPrice] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  const handleInputChange = (value: any) => {
    setRegularPrice(value);
  };
  const [descriptionOptions, setDescriptionOptions] = useState<SelectProps["options"]>([]);
  const { listDescriptions } = useSelector(descriptionSelector);
  useEffect(() => {
    if (listCategories) {
      const first_option = {
        label: "Chọn danh mục",
        value: "",
      };
      const categoriesFilterOptions = buildSelectOptions(listCategories, first_option);
      setCategoryFilterOptions(categoriesFilterOptions);
    }
  }, [listCategories]);

  useEffect(() => {
    console.log(selectedRowKeys);
    if (selectedRowKeys.length == 0) {
      setEnableSchedule(true);
      setDisableCancelScheduleBtn(true);
      setDisablePublishBtn(true);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    if (selectedStoreId) {
      const searchRequest = {
        ...getProductParams,
        pageIndex: 1,
        storeId: selectedStoreId,
      };
      setGetProductParams(searchRequest);
      dispatch(getListProducts(searchRequest));

      const param = {
        pageIndex: 1,
        pageSize: 100,
        storeId: selectedStoreId,
      };
      dispatch(getListCategories(param));
    }
  }, [selectedStoreId]);

  useEffect(() => {
    if (listDescriptions) {
      const optionsData: SelectProps["options"] = [];
      for (let i = 0; i < listDescriptions.length; i++) {
        optionsData.push({
          label: listDescriptions[i].title,
          value: listDescriptions[i].id,
        });
      }
      setDescriptionOptions(optionsData);
    }
  }, [listDescriptions]);

  useEffect(() => {
    if (selectedStoreId && requestStatus) {
      if (!updateStatus) {
        setVisible(false);
      }
      const searchRequest = {
        ...getProductParams,
        // pageIndex: 1,
        storeId: selectedStoreId,
      };
      setGetProductParams(searchRequest);
      dispatch(getListProducts(searchRequest));
      dispatch(setRequestStatus(false));
      dispatch(setUpdateStatus(false));
      setSelectedRowKeys([]);
      setModalUpdateStatusVisible(false);
    }
  }, [requestStatus]);

  useEffect(() => {
    if (addProductStatus) {
      setFileUpload([]);
      setFileList([]);
      dispatch(setAddProductStatus(false));
    }
  }, [addProductStatus]);

  const onStatusChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setProductStatus(e.target.value);
  };

  const getCategoryInfo = (values: any[]) => {
    let data = "<ul>";
    for (let i = 0; i < values.length; i++) {
      data += "<li>" + values[i].name + "</li>";
    }
    data += "</ul>";
    return data;
  };

  const getTagInfo = (values: any[]) => {
    let data = "";
    for (let i = 0; i < values.length; i++) {
      data += values[i].name + ", ";
    }
    return data;
  };

  const getRankMath = (values: any) => {
    for (let i = 0; i < values.length; i++) {
      if (values[i].key === "rank_math_focus_keyword") {
        return values[i].value;
      }
    }
    return "";
  };

  const renderPrice = (row: ProductModel) => {
    return (
      <>
        <Row>
          <Col>
            <b>Regular:</b> {row.regular_price}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Sale:</b> {row.sale_price}
          </Col>
        </Row>
      </>
    );
  };

  const renderApprove = (row: ProductModel) => {
    if (row.status != "draft") {
      return "";
    }

    let colorApprove = "volcano";
    let colorStatus = "volcano";
    let approveTxt = "";
    let completeTxt = "";
    if (row.approval_status == 1) {
      approveTxt = "Duyệt bởi " + row.approve_user;
      colorApprove = "green";
    } else {
      approveTxt = "Chưa duyệt";
    }

    if (row.complete_status == 1) {
      completeTxt = "Hoàn thiện bởi " + row.completed_user;
      colorStatus = "green";
    } else {
      completeTxt = "Chưa hoàn thiện";
    }
    return (
      <>
        <Row justify="space-around" align="middle">
          <Col>
            <Tag color={colorStatus} key={row.status}>
              {completeTxt}
            </Tag>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col>
            <Tag color={colorApprove} key={row.status}>
              {approveTxt}
            </Tag>
          </Col>
        </Row>
      </>
    );
  };

  const renderStatus = (row: ProductModel) => {
    let color = "volcano";

    if (row.status == "publish") {
      color = "green";
    }

    if (row.status == "future") {
      return (
        <>
          <Row justify="space-around" align="middle">
            <Col>
              <Tag color={"cyan"} key={row.status}>
                {row.status.toUpperCase()}
              </Tag>
            </Col>
          </Row>
          <Row justify="space-around" align="middle">
            <Col>Schedule time</Col>
          </Row>
          <Row justify="space-around" align="middle">
            <Col>{moment(row.date_created).isValid()
              ? moment(row.date_created).format("DD/MM/YYYY HH:mm:ss")
              : "Invalid Date"}</Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row justify="space-around" align="middle">
            <Col>
              <Tag color={color} key={row.status}>
                {row.status.toUpperCase()}
              </Tag>
            </Col>
          </Row>
          <Row justify="space-around" align="middle">
            <Col>
              <b>Last modified</b>
            </Col>
          </Row>
          <Row justify="space-around" align="middle">
            <Col>{moment(row.date_modified).isValid()
              ? moment(row.date_modified).format("DD/MM/YYYY HH:mm:ss")
              : "Invalid Date"}</Col>
          </Row>
        </>
      );
    }
  };

  const loadCategories = async (cateIds: any[]) => {
    const result: any[] = await getCategoriesByIds(selectedStoreId, cateIds);
    setSelectedCategoties(result);
  };

  const getTagsByIds = async (tagIds: any[]) => {
    const arr: any = [];
    if (tagIds && tagIds.length > 0) {
      const qParam = { store_id: selectedStoreId, pageIndex: 1, pageSize: 100, list_id: tagIds };
      const response = await api.post<ICategoryResponse>(`/api/tags/list/`, qParam);
      for (let index = 0; index < response.data.data.length; index++) {
        const element = response.data.data[index];
        console.log(element);
        arr.push({
          label: element.name,
          value: element.id,
        });
      }
    }
    setSelectedTags(arr);
  };

  const [form] = Form.useForm();
  const loadUpdate = async (row: ProductModel) => {
    if (listDescriptions) {
    }
    dispatch(getListDesciptions({ content_type: 2, storeId: selectedStoreId }));
    if (!listCategories || listCategories.length <= 0) {
      const param = {
        pageIndex: 1,
        pageSize: 100,
        storeId: selectedStoreId,
      };
      dispatch(getListCategories(param));
    }
    if (!listTags || listTags.length <= 0) {
      dispatch(getListTags(selectedStoreId));
    }

    let tagIds = row.tags.map((a) => a.id);
    let cateIds = row.categories.map((a) => a.id);
    loadCategories(cateIds);
    getTagsByIds(tagIds);

    const focus_keyword = getRankMath(row.meta_data);
    let arrKeys = [];
    if (focus_keyword) {
      arrKeys = focus_keyword.split(",");
    }
    // const arrKeys = focus_keyword.split(",")

    setVisible(true);
    form.setFieldsValue({
      id: row.id,
      description: row.description,
      name: decodeURI(row.name),
      regular_price: row.regular_price,
      sale_price: row.sale_price,
      status: row.status,
      focus_keyword: arrKeys,
      product_status: !row.complete_status ? "0" : row.complete_status + "",
      approve_status: !row.approval_status ? "0" : row.approval_status + "",
      tags: tagIds,
      slug: row.slug,
      categories: cateIds,
    });
    setRegularPrice(row.regular_price);

    if (row.status == "draft") {
      setDraft(true);
    } else {
      setDraft(false);
    }

    setProductStatus(row.status);

    let listImg: any[] = [];
    let productImg;
    for (let index = 0; index < row.images.length; index++) {
      const element = row.images[index];
      const fileData = {
        status: "done",
        name: element.name,
        thumbUrl: element.src,
        id: element.id,
        position: element.position,
        url: element.src,
      };
      if (element.position == 0) {
        setProductFileList([fileData]);
      } else {
        listImg.push(fileData);
      }
    }
    setFileUpload(listImg);
    setFileList(listImg);
  };

  const listProductColumns: ColumnsType<ProductModel> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      ellipsis: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
    },
    {
      title: "Thumbnail",
      dataIndex: "name",
      width: 100,
      render: (_, record) => <img style={{ width: "50px" }} src={record.images[0]?.src} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      // ellipsis: true,
      render: (_, record) => <a onClick={() => loadUpdate(record)}>{renderHTML(record.name)}</a>,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Price ($)",
      dataIndex: "price",
      width: 120,
      ellipsis: true,
      render: (_, record) => <>{renderPrice(record)}</>,
    },
    {
      title: "Categories",
      width: 200,
      key: "categories",
      dataIndex: "categories",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.categories.length - b.categories.length,
      sortOrder: sortedInfo.columnKey === "categories" ? sortedInfo.order : null,
      render: (_, record) => <span>{renderHTML(getCategoryInfo(record.categories))}</span>,
    },
    {
      title: "Tags",
      render: (_, record) => <span>{renderHTML(getTagInfo(record.tags))}</span>,
    },
    {
      title: "Description",
      key: "description",
      width: 110,
      ellipsis: true,
      render: (_, record) => (
        <Popover
          content={renderHTML(record.description)}
          trigger="click"
          overlayStyle={{
            width: "80%",
          }}
          title="Description"
        >
          <a type="primary">Detail</a>
        </Popover>
      ),
    },
    {
      title: "Focus Keyword",
      render: (_, record) => <span>{getRankMath(record.meta_data)}</span>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      render: (_, record) => <>{renderStatus(record)}</>,
    },
    {
      title: "Approve status",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (_, record) => <>{renderApprove(record)}</>,
    },
    {
      title: "Date created",
      key: "date_created",
      dataIndex: "date_created",
      sorter: (a, b) => moment(a.date_created).unix() - moment(b.date_created).unix(), //a.date_created.length - b.date_created.length,
      sortOrder: sortedInfo.columnKey === "date_created" ? sortedInfo.order : null,
      render: (_, record) => <>{moment(record.date_created).format("YYYY/MM/DD HH:mm:ss")}</>,
    },
    {
      title: "URL",
      // dataIndex: 'permalink',
      width: 70,
      ellipsis: true,
      render: (_, record) => (
        <Popover
          content={record.permalink}
          overlayStyle={{
            width: "30vw",
          }}
          title="Product URL"
        >
          <a type="primary" target="_blank" href={record.permalink}>
            View
          </a>
        </Popover>
      ),
    },
  ];

  const onChange: TableProps<ProductModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setSortedInfo(sorter as SorterResult<ProductModel>);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = (product: ProductModel) => {
    // setLoading(true);
    console.log("Success:", product);
    product.status = productStatus;
    product.store_id = selectedStoreId;

    const categories: ProductCategoryModel[] = [];
    for (let i = 0; i < selectedCategoties.length; i++) {
      const data: ProductCategoryModel = { id: selectedCategoties[i].value };
      categories.push(data);
    }
    product.categories = categories;

    if (product.regular_price) {
      product.regular_price = String(product.regular_price);
    }

    if (product.sale_price) {
      product.sale_price = String(product.sale_price);
    }

    const tags: ProductTagModel[] = [];
    for (let i = 0; i < selectedTags.length; i++) {
      const tag = selectedTags[i];
      let tagObj: ProductTagModel = {};
      if (typeof tag.value === "string") {
        tagObj = { name: selectedTags[i].value };
      } else {
        tagObj = { id: selectedTags[i].value };
      }
      tags.push(tagObj);
    }
    product.tags = tags;

    console.log("fileUpload", fileUpload);
    if (fileUpload || productFileList.length > 0) {
      const images: ProductImageModel[] = [];
      let pos = 1;
      if (productFileList.length > 0) {
        images.push({ id: productFileList[0].id, src: productFileList[0].url, position: 0 });
      }
      if (fileUpload && fileUpload.length > 0) {
        for (let i = 0; i < fileUpload.length; i++) {
          const img: ProductImageModel = {
            id: fileUpload[i].id,
            src: fileUpload[i].url,
            position: pos++,
          };
          images.push(img);
        }
      }
      if (images.length > 0) {
        product.images = images;
      }
    }

    // meta
    if (product.focus_keyword) {
      const rank = [];
      let rank_val = "";
      for (let i = 0; i < product.focus_keyword.length; i++) {
        if (i == product.focus_keyword.length - 1) {
          rank_val += product.focus_keyword[i];
        } else {
          rank_val += product.focus_keyword[i] + ",";
        }
      }
      const meta_data = {
        key: "rank_math_focus_keyword",
        value: rank_val,
      };
      rank.push(meta_data);
      product.meta_data = rank;
    }

    if (product.id) {
      dispatch(updateProduct(product));
    } else {
      dispatch(addProduct(product));
    }
  };

  const [enableSchedule, setEnableSchedule] = useState(true);
  const [disableCancelScheduleBtn, setDisableCancelScheduleBtn] = useState(true);
  const [disablePublishBtn, setDisablePublishBtn] = useState(true);

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: ProductModel[]) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    console.log(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
    let scheduleFlag = false;
    let cancelScheduleFlag = false;
    let publishFlag = false;
    if (selectedRows.length == 0) {
      scheduleFlag = true;
      cancelScheduleFlag = true;
      publishFlag = false;
    } else {
      for (let index = 0; index < selectedRows.length; index++) {
        const element = selectedRows[index];
        if (element.status != "draft") {
          scheduleFlag = true;
        }
        if (element.status != "future") {
          cancelScheduleFlag = true;
        }
        if (element.status == "publish") {
          publishFlag = true;
        }
      }
    }
    setEnableSchedule(scheduleFlag);
    setDisableCancelScheduleBtn(cancelScheduleFlag);
    setDisablePublishBtn(publishFlag);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => {
    console.log("");
    setPreviewVisible(false);
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const [productFileList, setProductFileList] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileUpload, setFileUpload] = useState<any[]>();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  let lastFile = useRef(undefined);

  const handleProductGalleryChange = (uploadData: any) => {
    console.log(uploadData);
    // fileList.file
    setFileList(uploadData.fileList);
    lastFile = uploadData.file;
    setFileUpload(uploadData.fileList);
  };

  const handleProductImgChange = (uploadData: any) => {
    console.log(uploadData);
    // fileList.file
    setProductFileList(uploadData.fileList);
    lastFile = uploadData.file;
  };

  const onCategoryChange = (value: number[]) => {
    setSelectedCategoties(value);
  };

  const onFilterCategoryChange = (value: string) => {
    const searchRequest = {
      ...getProductParams,
      pageIndex: 1,
      storeId: selectedStoreId,
      category: value,
    };
    setFilterCategory(value);
    setGetProductParams(searchRequest);
    dispatch(getListProducts(searchRequest));
  };

  const onFilterCompleteStatusChange = (value: string) => {
    const searchRequest = {
      ...getProductParams,
      pageIndex: 1,
      storeId: selectedStoreId,
      status: value,
    };
    setfilterCompleteStatus(value);
    setGetProductParams(searchRequest);
    dispatch(getListProducts(searchRequest));
  };

  const onFilterApproveStatusChange = (value: string) => {
    const searchRequest = {
      ...getProductParams,
      pageIndex: 1,
      storeId: selectedStoreId,
      productStatus: value,
    };
    setFilterApproveStatus(value);
    setGetProductParams(searchRequest);
    dispatch(getListProducts(searchRequest));
  };

  const onClearFilter = () => {
    const searchRequest = {
      ...getProductParams,
      pageIndex: 1,
      storeId: selectedStoreId,
      category: "",
      productStatus: "",
      completeStatus: "",
    };
    setFilterCategory("");
    setfilterCompleteStatus("");
    setFilterApproveStatus("");
    setGetProductParams(searchRequest);
    dispatch(getListProducts(searchRequest));
  };

  const onUpdateStatusChange = (value: any) => {
    setSelectedStatus(value);
  };

  const onTagsChange = (value: number[]) => {
    setSelectedTags(value);
  };

  const handleChangeQuill = (html: any) => {};

  const uploadProductImage = async (options: any) => {
    uploadImage(options, "ProductImage");
  };

  const uploadProductGallery = async (options: any) => {
    uploadImage(options, "ProductGallery");
  };

  const [progress, setProgress] = useState(0);
  const uploadImage = async (options: any, type: string) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    fmData.append("status", file.status);
    fmData.append("store", selectedStoreId);
    try {
      const res = await api.post("/api/products/media/", fmData, config);
      onSuccess("Ok");
      if (type == "ProductImage") {
        setProductFileList([{ url: res.data.data.source_url, id: res.data.data.id }]);
      } else {
        const new2 = { ...lastFile, url: res.data.data.source_url, id: res.data.data.id };
        if (fileUpload) {
          fileUpload.push(new2);
          setFileUpload(fileUpload);
        } else {
          const arr = [];
          arr.push(new2);
          setFileUpload(arr);
        }
      }
      console.log("server res: ", res);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };

  // model
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUpdateStatusVisible, setModalUpdateStatusVisible] = useState(false);

  const showModal = () => {
    if (!selectedStoreId) {
      message.warning("Please select a store.");
    } else if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một sản phẩm.");
    } else {
      setIsModalVisible(true);
    }
  };

  const showModalUpdateStatus = () => {
    if (!selectedStoreId) {
      message.warning("Please select a store.");
    } else if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một sản phẩm.");
    } else {
      setModalUpdateStatusVisible(true);
    }

    console.log(userInfo);
    const optionsData: SelectProps["options"] = [];

    if (userInfo?.groups?.indexOf("Admin") > -1) {
      optionsData.push({
        label: "Đã duyệt",
        value: "1",
      });
      optionsData.push({
        label: "Chưa duyệt",
        value: "0",
      });
    }

    if (userInfo?.groups?.indexOf("Listing") > -1) {
      optionsData.push({
        label: "Đã hoàn thành",
        value: "1",
      });
      optionsData.push({
        label: "Chưa hoàn thành",
        value: "0",
      });
    }
    setUpdateStatusOptions(optionsData);
  };

  const handleOk = () => {
    dispatch(
      schedulePost({
        store_id: selectedStoreId,
        list_id: selectedRowKeys,
        publish_time: moment(scheduleTime, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
      })
    );
    dispatch(getListProducts(getProductParams));
    setIsModalVisible(false);
  };

  const handleUpdateStatus = () => {
    if (!selectedStatus) {
      message.warning("Chọn status.");
      return;
    }
    const data = {
      list_id: selectedRowKeys,
      complete_status: "",
      approval_status: "",
      store_id: selectedStoreId,
    };

    if (selectedStatus.label == "Đã duyệt" || selectedStatus.label == "Chưa duyệt") {
      data.approval_status = selectedStatus.value;
    }
    if (selectedStatus.label == "Đã hoàn thành" || selectedStatus.label == "Chưa hoàn thành") {
      data.complete_status = selectedStatus.value;
    }

    dispatch(updateProductStatus(data));
  };

  const onScheduleChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
    setScheduleTime(dateString);
  };

  const handleCancelModel = () => {
    setIsModalVisible(false);
  };

  const handlePageChange: PaginationProps["onChange"] = (pageNumber, pageSize) => {
    console.log(pageNumber, pageSize);
    const searchRequest = {
      ...getProductParams,
      pageIndex: pageNumber == 0 ? 1 : pageNumber,
      pageSize: pageSize,
      storeId: selectedStoreId,
    };
    setGetProductParams(searchRequest);
    dispatch(getListProducts(searchRequest));
    setCurrentPage(pageNumber);
  };

  const { confirm } = Modal;
  const showConfirm = (type: string) => {
    if (!selectedStoreId) {
      message.warning("Please select a store.");
      return;
    } else if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một sản phẩm.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa sản phẩm đã chọn không?";
    if (type == "PUBLISH") {
      msgConfirm = "Bạn có muốn publish các sản phẩm đã chọn?";
    } else if (type == "CANCEL_SCHEDULE") {
      msgConfirm = "Bạn có muốn hủy schedule cho các sản phẩm đã chọn?";
    } else if (type == "AUTO_SCHEDULE") {
      msgConfirm = "Bạn có muốn auto schedule cho các sản phẩm đã chọn?";
    }
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      //   content: 'Some descriptions',
      onOk() {
        console.log("OK");
        if (type == "PUBLISH") {
          const data = {
            store_id: selectedStoreId,
            list_id: selectedRowKeys,
            action: "publish",
          };
          dispatch(bulkUpdateProduct(data));
        } else if (type == "CANCEL_SCHEDULE") {
          const data = {
            store_id: selectedStoreId,
            list_id: selectedRowKeys,
            action: "cancel_schedule",
          };
          dispatch(bulkUpdateProduct(data));
        } else if (type == "DELETE") {
          dispatch(deleteProduct(selectedStoreId, selectedRowKeys));
        } else if (type == "AUTO_SCHEDULE") {
          dispatch(
            schedulePost({
              store_id: selectedStoreId,
              list_id: selectedRowKeys,
              publish_time: "",
            })
          );
        }
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onApproveStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio2 checked", value);

    const data = {
      list_id: [form.getFieldValue("id")],
      approval_status: value,
      store_id: selectedStoreId,
    };
    dispatch(updateProductStatus(data));
  };

  const onCompleteStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio2 checked", value);
    const data = {
      list_id: [form.getFieldValue("id")],
      complete_status: value,
      store_id: selectedStoreId,
    };
    dispatch(updateProductStatus(data));
  };

  const onSearch = (value: string) => {
    console.log(value.trim());
    const searchRequest = {
      ...getProductParams,
      keyword: value,
    };
    setGetProductParams(searchRequest);
    dispatch(getListProducts(searchRequest));
  };

  const onClick = () => {
    // filterBy(key);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const getPermalinkBase = () => {
    let newPerma = permalinkBase;
    const last = permalinkBase.slice(permalinkBase.length - 1);
    if (last != "/") {
      newPerma = permalinkBase + "/";
    }
    return newPerma;
  };

  const onDesciptionChange = (value: number) => {
    console.log(value);
    for (let i = 0; i < listDescriptions.length; i++) {
      if (listDescriptions[i].id == value) {
        form.setFieldsValue({
          description: listDescriptions[i].content,
        });
        break;
      }
    }
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Quản lý sản phẩm</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360, paddingBottom: 80 }}
      >
        <div>
          <Space direction="horizontal">
            {hasPermission(permissions, PER_DELETE_PRODUCT) && (
              <Button
                type="primary"
                disabled={selectedRowKeys.length == 0}
                onClick={() => showConfirm("DELETE")}
                style={{ marginBottom: 16 }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            )}

            {hasPermission(permissions, PER_UPDATE_PRODUCT) && (
              <>
                <Button
                  type="primary"
                  disabled={enableSchedule}
                  onClick={() => showConfirm("AUTO_SCHEDULE")}
                  style={{ marginBottom: 16 }}
                >
                  Auto Schedule
                </Button>
                <Button
                  type="primary"
                  disabled={disableCancelScheduleBtn}
                  style={{ marginBottom: 16 }}
                  onClick={() => showConfirm("CANCEL_SCHEDULE")}
                >
                  Cancel Schedule
                </Button>
                <Button
                  type="primary"
                  disabled={disablePublishBtn}
                  style={{ marginBottom: 16 }}
                  onClick={() => showConfirm("PUBLISH")}
                >
                  Publish
                </Button>
                <Button
                  type="primary"
                  disabled={selectedRowKeys.length == 0}
                  style={{ marginBottom: 16 }}
                  onClick={showModalUpdateStatus}
                >
                  Update status
                </Button>
              </>
            )}
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right", marginBottom: 16 }}
            />
            <FilterFilled style={{ marginBottom: 16 }} onClick={handleShowFilter} />
          </Space>

          {showFilter && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Select
                  showSearch
                  value={filterCategory}
                  defaultValue={""}
                  style={{ minWidth: 200 }}
                  placeholder="Chọn danh mục"
                  options={categoryFilterOptions}
                  onChange={onFilterCategoryChange}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>

                <Select
                  defaultValue={""}
                  value={filterCompleteStatus}
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Chọn trạng thái"
                  onChange={onFilterCompleteStatusChange}
                >
                  <Option value="">Chọn Status</Option>
                  <Option value="publish">Publish</Option>
                  <Option value="draft">Draft</Option>
                  <Option value="future">Schedule</Option>
                </Select>

                <Select
                  defaultValue={""}
                  value={filterApproveStatus}
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Chọn trạng thái approve"
                  onChange={onFilterApproveStatusChange}
                >
                  <Option value="">Chọn approve status</Option>
                  <Option value="P_1">Đã hoàn thành</Option>
                  <Option value="P_0">Chưa hoàn thành</Option>
                  <Option value="1">Duyệt</Option>
                  <Option value="0">Chưa duyệt</Option>
                </Select>

                <Button type="link" onClick={onClearFilter}>
                  Xóa bộ lọc
                </Button>
              </Col>
            </Row>
          )}

          <Modal
            title="Chọn thời gian publish cho sản phẩm"
            visible={isModalVisible}
            onOk={handleOk}
            okText="Schedule"
            onCancel={handleCancelModel}
          >
            <Row gutter={16}>
              <Col span={24}>
                Chỉ định thời gian theo múi giờ của store.
                <Form.Item required name="ownerx" label="">
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder=""
                    showTime
                    onChange={onScheduleChange}
                    format="DD/MM/YYYY HH:mm:ss"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Modal>

          <Modal
            title="Update status"
            visible={isModalUpdateStatusVisible}
            onOk={handleUpdateStatus}
            okText="Update"
            onCancel={() => setModalUpdateStatusVisible(false)}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item required name="owner" label="Chọn trạng thái">
                  <Select
                    labelInValue
                    value={selectedStatus}
                    onChange={onUpdateStatusChange}
                    style={{ width: "100%" }}
                    options={updateStatusOptions}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Modal>

          <Table
            columns={listProductColumns}
            rowSelection={rowSelection}
            dataSource={listProducts}
            onChange={onChange}
            pagination={false}
            scroll={{ x: 2200, y: 600 }}
          />
          <Pagination
            style={{ marginTop: 15, marginBottom: 20, float: "right" }}
            current={currentPage}
            showSizeChanger={true}
            showTotal={(total) => `Total ${totalProduct} items`}
            defaultCurrent={1}
            total={totalProduct}
            onChange={handlePageChange}
          />

          <Drawer
            title="Update thông tin sản phẩm"
            width={1024}
            onClose={onClose}
            maskClosable={false}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>

                {isDraft && (
                  <>
                    <Row gutter={16}>
                      {userInfo?.groups?.indexOf("Listing") > -1 && (
                        <Col span={12}>
                          <Form.Item label="Tình trạng sản phẩm" name="product_status">
                            <Radio.Group onChange={onCompleteStatusChange}>
                              <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                              <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      )}
                      {userInfo?.groups?.indexOf("Admin") > -1 && (
                        <>
                          <Col span={12}>
                            <Form.Item label="Tình trạng sản phẩm" name="product_status">
                              <Radio.Group onChange={onCompleteStatusChange}>
                                <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                                <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Phê duyệt" name="approve_status">
                              <Radio.Group onChange={onApproveStatusChange}>
                                <Radio.Button value="1">Duyệt</Radio.Button>
                                <Radio.Button value="0">Chưa duyệt</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="name"
                      label="Tên sản phẩm"
                      rules={[{ required: true, message: "Tên sản phẩm là thông tin bắt buộc." }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="slug" label="Permalink">
                      <Input addonBefore={getPermalinkBase()} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="categories" label="Categories">
                      <SeachableCategory
                        optionsVal={selectedCategoties}
                        onChange={onCategoryChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="tags" label="Tags">
                      <SeachableTag optionsVal={selectedTags} onChange={onTagsChange} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item name="regular_price" label="Regular price">
                      <InputNumber
                        value={regularPrice}
                        onChange={handleInputChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="sale_price"
                      label="Sale price"
                      rules={[
                        {
                          message: "Nhập Regular price",
                          validator: (_, value) => {
                            if (value) {
                              if (regularPrice) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("Invalid");
                              }
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                        {
                          message: "Sale price phải nhỏ hơn Regular price",
                          validator: (_, value) => {
                            if (value) {
                              if (regularPrice && value > regularPrice) {
                                return Promise.reject("Invalid");
                              } else {
                                return Promise.resolve();
                              }
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="focus_keyword" label="Focus keyword">
                      <Select mode="tags" style={{ width: "100%" }}>
                        {/* {children} */}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Status" name="status">
                      <Radio.Group value={productStatus} onChange={onStatusChange}>
                        <Radio value="publish">Publish</Radio>
                        <Radio value="draft">Draft</Radio>
                        <Radio value="pending">Pending</Radio>
                        <Radio value="private">Private</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Select
                      style={{ width: "100%", marginBottom: 5 }}
                      placeholder="Chọn description từ template"
                      options={descriptionOptions}
                      onChange={onDesciptionChange}
                    ></Select>
                    <Form.Item name="description" label="Mô tả">
                      <TextEditor
                        value={""}
                        placeholder={""}
                        onChange={handleChangeQuill}
                      ></TextEditor>
                      {/* <Input.TextArea rows={4} placeholder="please enter url description" /> */}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <div className="clearfix">
                      Product image
                      <Upload
                        customRequest={uploadProductImage}
                        listType="picture-card"
                        fileList={productFileList}
                        onPreview={handlePreview}
                        onChange={handleProductImgChange}
                      >
                        {productFileList.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div className="ant-upload-text">Upload</div>
                          </div>
                        )}
                      </Upload>
                      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                        <img alt="example" style={{ width: "100%" }} src={previewImage} />
                      </Modal>
                    </div>
                  </Col>

                  <Col span={12}>
                    <div className="clearfix">
                      Product gallery
                      <Upload
                        customRequest={uploadProductGallery}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleProductGalleryChange}
                      >
                        <div>
                          <PlusOutlined />
                          <div className="ant-upload-text">Upload</div>
                        </div>
                      </Upload>
                      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                        <img alt="example" style={{ width: "100%" }} src={previewImage} />
                      </Modal>
                    </div>
                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default SettingStore;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { IIdeaResponse, IdeaModel, ICommonTaskResponse } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface ITrending {
  requestStatus: boolean,
  updateStatus: boolean,
  listIdea: IdeaModel[],
}

const initialState: ITrending = {
  requestStatus: false,
  updateStatus: false,
  listIdea: [],
};

export const userSlice = createSlice({
  name: "idea",
  initialState,
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setlistIdea: (state, action: PayloadAction<IdeaModel[]>) => {
      state.listIdea = action.payload
    },
    setUpdateStatus: (state, action: PayloadAction<boolean>) => {
      state.updateStatus = action.payload
    },
  }
});

export const ideaSelector = (state: RootState): ITrending => state.idea

// =========================
/// Actions
// =========================
export const {
  setlistIdea,
  setRequestStatus,
  setUpdateStatus
} = userSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getlistIdea = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.get<IIdeaResponse>(`/api/idea`, { params: data });
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setlistIdea(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}


export const addIdea = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IIdeaResponse>(`/api/idea/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["idea"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateIdea = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.patch<IIdeaResponse>(`/api/idea/${data.id}/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["idea"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteIdea = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.post<IIdeaResponse>(`/api/idea/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["idea"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateIdeaStatus = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IIdeaResponse>(`/api/idea/update-status/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["idea"]));
      dispatch(setUpdateStatus(true));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const assignTask = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IIdeaResponse>(`/api/idea/assign-task/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["idea"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}


export const updateContent = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IIdeaResponse>(`/api/idea/update-content/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["content"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const approveContent = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IIdeaResponse>(`/api/idea/approve-content/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["content"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const approveDesign = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IIdeaResponse>(`/api/idea/approve-design/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["design"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const getSummaryIdea = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.get<IIdeaResponse>(`/api/idea/summary-report`, { params: data });
    console.log(response.data.data)

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateDesign = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<ICommonTaskResponse>(`/api/idea/update-design/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["idea"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export default userSlice.reducer;

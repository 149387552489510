import { message, SelectProps } from "antd";
import React from "react";
import { ICategoryResponse } from "../models/models";
import api from "../services/api";
import { MSG_04 } from "./cms_message";


// declare global {
//     interface StringConstructor {
//       format(s: string, length: number): string;
//     }
//   }

//   String.padZero = (s: string, length: number) => {
//     while (s.length < length) {
//       s = '0' + s;
//     }
//     return s;
//   };

export function getMessage(msg: string, replacements: any = []) {

    if (replacements.length > 0) {
        return msg.replace(/\{(\d+)\}/g, function () {
            return replacements[arguments[1]];
        });
    } else {
        return msg;
    }
}


export const buildSelectOptions = (listData: any, first_opt: any = null) => {
    const optionsData: SelectProps['options'] = [];

    if (first_opt) {
        optionsData.push(first_opt);
    }
    for (let i = 0; i < listData.length; i++) {
        optionsData.push({
            label: renderHTML(listData[i].name),
            value: listData[i].id,
        });
    }
    return optionsData;
};

export const buildSelectUserOptions = (listData: any, first_opt: any = null, role: string = "") => {
    const optionsData: SelectProps['options'] = [];

    if (first_opt) {
        optionsData.push(first_opt);
    }
    for (let i = 0; i < listData.length; i++) {
        if (role) {
            if (listData[i].groups[0] && listData[i].groups[0].name == role) {
                optionsData.push({
                    label: renderHTML(listData[i].first_name),
                    value: listData[i].id,
                });
            }
        } else {
            optionsData.push({
                label: renderHTML(listData[i].first_name),
                value: listData[i].id,
            });
        }
    }
    return optionsData;
};

export const renderHTML = (rawHTML: any) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

export const genFileName = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;      // "+ 1" becouse the 1st month is 0
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const secconds = date.getSeconds()

    const fileName = month + '.' + day + '.' + year + ' - ' + hour + ':' + minutes + ':' + secconds;
    return fileName;
}

export const formatDate = (date: string) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const formatDate2 = (date: string) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();


    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-') + " " + d.getHours() + ":" + d.getMinutes();
}


export const hasPermission = (listPers: string[], ...perCodes: any) => {
    if (listPers) {
        for (let index = 0; index < perCodes.length; index++) {
            const perCode = perCodes[index];
            if (listPers.includes(perCode)) {
                return true;
            }
        }
        return false;
    } else {
        return false;
    }
}

export const hasRole = (listRoles: string[], ...roles: any) => {
    if (listRoles) {
        for (let index = 0; index < roles.length; index++) {
            const role = roles[index];
            if (listRoles.includes(role)) {
                return true;
            }
        }
        return false;
        
    } else {
        return false;
    }
}

export const show_error_msg = (res_data: any) => {
    if (res_data.message) {
        message.error(res_data.message);
    } else {
        message.error(getMessage(MSG_04));
    }
}

export const getCategoriesByIds = async (selectedStoreId: any, cateIds: any[]) => {

    const arr: any = []
    if (cateIds && cateIds.length > 0) {
        const qParam = { "store_id": selectedStoreId, "pageIndex": 1, "pageSize": 100, list_id: cateIds }
        const response = await api.post<ICategoryResponse>(`/api/categories/list/`, qParam);
        for (let index = 0; index < response.data.data.length; index++) {
            const element = response.data.data[index];
            console.log(element)
            arr.push(
                {
                    label: element.name,
                    value: element.id
                }
            )
        }
    }
    return arr
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage, show_error_msg } from "../../common/common_utils";
import { ITrendingResponse, TrendingModel } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface ITrending {
  requestStatus: boolean,
  listTrending: TrendingModel[],
}

const initialState: ITrending = {
  requestStatus: false,
  listTrending: [],
};

export const userSlice = createSlice({
  name: "trending",
  initialState,
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListTrending: (state, action: PayloadAction<TrendingModel[]>) => {
      state.listTrending = action.payload
    },
  }
});

export const trendingSelector = (state: RootState): ITrending => state.trending

// =========================
/// Actions
// =========================
export const {
  setListTrending,
  setRequestStatus
} = userSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListTrending = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.get<ITrendingResponse>(`/api/trending`, { params: data });
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setListTrending(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}


export const addTrending = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<ITrendingResponse>(`/api/trending/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["trending"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateTrending = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.patch<ITrendingResponse>(`/api/trending/${data.id}/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["trending"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteTrending = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.post<ITrendingResponse>(`/api/trending/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["trending"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}


export const updateTrendingStatus = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<ITrendingResponse>(`/api/trending/update_status/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["trending"]));
      dispatch(setRequestStatus(true));
    } else {
      show_error_msg(response.data)
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export default userSlice.reducer;

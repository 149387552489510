import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PER_ADD_USER, PER_DELETE_USER, PER_UPDATE_USER } from "../../common/cms_const";
import { buildSelectOptions, hasPermission } from "../../common/common_utils";
import { UserModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import { getListGroups, groupSelector } from "../../store/slices/group-slide";
import {
  addUser,
  deleteUser,
  getListUsers,
  setRequestStatus,
  updateUser,
  userSelector,
} from "../../store/slices/user-slide";

const User: React.FC = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<UserModel>>({});
  const dispatch = useDispatch();
  const { listUsers, requestStatus } = useSelector(userSelector);
  const { listGroups } = useSelector(groupSelector);
  const [groupOptions, setGroupOptions] = useState<SelectProps["options"]>([]);
  const [selectedGroup, setSelectedGroup] = useState<any[]>([]);
  const { appLoading, permissions } = useSelector(appSelector);
  const [formUpdate, setFormUpdate] = useState(false);

  useEffect(() => {
    dispatch(getListUsers());
  }, []);

  useEffect(() => {
    if (listGroups && listGroups.length > 0) {
      const options = buildSelectOptions(listGroups);
      setGroupOptions(options);
    }
  }, [listGroups]);

  useEffect(() => {
    if (requestStatus) {
      dispatch(getListUsers());
      setVisible(false);
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderStatus = (status: boolean) => {
    if (status) {
      return <Tag color={"green"}>Active</Tag>;
    } else {
      return <Tag color={"volcano"}>Inactive</Tag>;
    }
  };

  const renderGroup = (record: any) => {
    let data = "";
    for (let i = 0; i < record.groups.length; i++) {
      if (i == record.groups.length - 1) {
        data += record.groups[i].name;
      } else {
        data += record.groups[i].name + ", ";
      }
    }
    return data;
  };

  const onFinish = (user: UserModel) => {
    if (user.id) {
      dispatch(updateUser(user));
    } else {
      dispatch(addUser(user));
    }
  };

  const [form] = Form.useForm();
  const loadUpdate = (user: UserModel) => {
    if (!hasPermission(permissions, PER_UPDATE_USER)) {
      return;
    }
    setVisible(true);
    setFormUpdate(true);
    if (!listGroups || listGroups.length <= 0) {
      dispatch(getListGroups());
    }
    let groupIds = user.groups.map((a) => a.id);
    form.setFieldsValue({
      id: user.id,
      username: user.username,
      email: user.email,
      first_name: user.first_name,
      is_active: user.is_active,
      note: user.note,
      groups: groupIds[0],
    });
  };

  const columns: ColumnsType<UserModel> = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"],
    },
    {
      title: "Họ tên",
      dataIndex: "first_name",
      responsive: ["md"],
      key: "first_name",
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      sortOrder: sortedInfo.columnKey === "first_name" ? sortedInfo.order : null,
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "username",
      key: "username",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.username.length - b.username.length,
      sortOrder: sortedInfo.columnKey === "username" ? sortedInfo.order : null,
      render: (_, record) => (
        <>
          <a onClick={() => loadUpdate(record)}>{record.username}</a>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      responsive: ["md"],
    },
    {
      title: "Nhóm quyền",
      dataIndex: "group_name",
      render: (_, record) => <>{renderGroup(record)}</>,
    },
    {
      title: "Trạng thái",
      responsive: ["md"],
      render: (_, record) => <>{renderStatus(record.is_active)}</>,
    },
  ];

  const onChange: TableProps<UserModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setSortedInfo(sorter as SorterResult<UserModel>);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
    setFormUpdate(false);
    if (!listGroups || listGroups.length <= 0) {
      dispatch(getListGroups());
    }

    setSelectedGroup([]);
    form.setFieldsValue({
      id: "",
      username: "",
      email: "",
      first_name: "",
      is_active: "",
      note: "",
      groups: [],
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onGroupChange = (value: any) => {
    setSelectedGroup(value);
    console.log(selectedGroup);
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một user.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      //   content: 'Some descriptions',
      onOk() {
        console.log("OK");
        dispatch(deleteUser(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Quản lý user</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            {hasPermission(permissions, PER_ADD_USER) && (
              <Button
                onClick={showDrawer}
                type="primary"
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
              >
                Thêm user
              </Button>
            )}

            {hasPermission(permissions, PER_DELETE_USER) && (
              <Button
                type="primary"
                onClick={showConfirm}
                style={{ marginBottom: 16 }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            )}
          </Space>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listUsers}
            onChange={onChange}
          />

          <Drawer
            title="Thêm mới user"
            width={window.innerWidth > 900 ? 750 : 400}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="first_name"
                      label="Họ tên"
                      rules={[{ required: true, message: "Họ tên là thông tin bắt buộc." }]}
                    >
                      <Input placeholder="Họ tên" />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="username"
                      label="Username"
                      rules={[{ required: true, message: "Username là thông tin bắt buộc." }]}
                    >
                      <Input style={{ width: "100%" }} placeholder="Please enter" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        { required: true, message: "Email là thông tin bắt buộc." },
                        { type: "email", message: "Email không hợp lệ." },
                      ]}
                    >
                      <Input style={{ width: "100%" }} placeholder="Nhập email" />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item name="groups" label="Role">
                      <Select
                        placeholder="Chọn vai trò"
                        options={groupOptions}
                        value={selectedGroup}
                        onChange={onGroupChange}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                {!formUpdate && (
                  <Row gutter={16}>
                    <Col md={12} xs={24}>
                      <Form.Item
                        name="password"
                        label="Mật khẩu"
                        rules={[{ required: true, message: "Mật khẩu là thông tin bắt buộc." }]}
                      >
                        <Input.Password autoComplete="off" name="password" />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={16}>
                  <Col md={24} xs={24}>
                    <Form.Item name="description" label="Ghi chú">
                      <Input.TextArea rows={4} placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default User;

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FilterFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
  Tabs,
  Tag,
  Upload,
  message,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_IDEA } from "../../common/cms_const";
import { buildSelectUserOptions, hasRole } from "../../common/common_utils";
import { IdeaModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addIdea,
  deleteIdea,
  getlistIdea,
  ideaSelector,
  setRequestStatus,
  updateIdea,
  updateIdeaStatus,
} from "../../store/slices/idea-slide";
import { getlistNiche, nicheSelector } from "../../store/slices/niche-slide";
import { getlistProductType, productTypeSelector } from "../../store/slices/productType-slide";
import { getListUsers, userSelector } from "../../store/slices/user-slide";

const { Option } = Select;

const ListIdeas: React.FC = () => {
  const dispatch = useDispatch();
  const { appLoading, userInfo } = useSelector(appSelector);
  const { listIdea, requestStatus } = useSelector(ideaSelector);
  const [fileList, setFileList] = useState<any[]>([]);
  const [formTitle, setFormTitle] = useState("");
  const [nicheOptions, setNicheOptions] = useState<SelectProps["options"]>([]);
  const [productTypeOptions, setProductTypeOptions] = useState<SelectProps["options"]>([]);
  const { listNiche } = useSelector(nicheSelector);
  const { listProductType } = useSelector(productTypeSelector);
  const [selectedTab, setSelectedTab] = useState("spy");
  const [showFilter, setShowFilter] = useState(false);
  const [designFilterOptions, setDesignFilterOptions] = useState<any>([]);
  const [contentFilterOptions, setContentFilterOptions] = useState<any>([]);
  const [nicheFilterOptions, setNicheFilterOptions] = useState<any>([]);
  const { listUsers } = useSelector(userSelector);
  const [filterDesignerVal, setFilterDesignerVal] = useState("");
  const [filterContentVal, setfilterContentVal] = useState("");
  const [filterNichVal, setfilterNichVal] = useState("");
  const [filterTypeVal, setfilterTypeVal] = useState("");
  const [tabNewStatus, setTabNewStatus] = useState(true);
  const [tabSpyStatus, setTabSpyStatus] = useState(true);

  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
    status: "",
    designer: "",
    content_creator: "",
    niche: "",
    type: "",
  });

  useEffect(() => {
    if (listNiche) {
      const optionsData: SelectProps["options"] = [];
      const optionsData2: SelectProps["options"] = [];

      optionsData2.push({
        label: "Chọn Niche",
        value: "",
      });

      for (let i = 0; i < listNiche.length; i++) {
        optionsData.push({
          label: listNiche[i].name,
          value: listNiche[i].id,
        });

        optionsData2.push({
          label: listNiche[i].name,
          value: listNiche[i].id,
        });
      }
      setNicheOptions(optionsData);
      setNicheFilterOptions(optionsData2);
    }
  }, [listNiche]);

  useEffect(() => {
    if (listProductType) {
      const optionsData: SelectProps["options"] = [];
      for (let i = 0; i < listProductType.length; i++) {
        optionsData.push({
          label: listProductType[i].name,
          value: listProductType[i].id,
        });
      }
      setProductTypeOptions(optionsData);
    }
  }, [listProductType]);

  const handleFileChange = (uploadData: any) => {
    console.log(uploadData);
    setFileList(uploadData.fileList);
  };

  useEffect(() => {
    dispatch(getlistIdea(requestParams));
    if (!listNiche || listNiche.length <= 0) {
      dispatch(getlistNiche({}));
    }
  }, []);

  useEffect(() => {
    if (requestStatus) {
      setVisible(false);
      dispatch(getlistIdea(requestParams));
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderStatus = (row: any) => {
    if (row.status == 1) {
      return <Tag color={"green"}>Đã hoàn thiện</Tag>;
    } else {
      return <Tag color={"volcano"}>Chưa hoàn thiện</Tag>;
    }
  };

  const renderDesignStatus = (row: any) => {
    return (
      <>
        <Row>
          <Col>
            <Tag color={row.design_print_status == 1 ? "green" : "volcano"}>
              {row.design_print_status == 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
              File print
            </Tag>
          </Col>
        </Row>
        <Row style={{ marginTop: 3 }}>
          <Col>
            <Tag color={row.design_mockup_status == 1 ? "green" : "volcano"}>
              {row.design_mockup_status == 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
              Mockup
            </Tag>
          </Col>
        </Row>
      </>
    );
  };

  const renderApproveStatus = (record: any) => {
    if (record.approve_status) {
      return (
        <>
          <Row>
            <Col>
              <Tag color={"green"}>Đã duyệt</Tag>
            </Col>
          </Row>
          <Row>
            <Col>By: {record.approved_by_name}</Col>
          </Row>
        </>
      );
    } else {
      return <Tag color={"volcano"}>Chưa duyệt</Tag>;
    }
  };

  const renderImage = (record: any) => {
    if (record.images) {
      var listImages = record.images.split("|");
      return (
        <>
          {listImages.map((item: any) => {
            return (
              <img
                style={{ width: "50px", margin: "3px" }}
                src={process.env.REACT_APP_API_SERVER_URL + item}
              />
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  const renderSampleLink = (record: any) => {
    if (record.sample) {
      var separateLines = record.sample.split(/\r?\n|\r|\n/g);
      console.log(separateLines);
      let idx = 0;

      return (
        <>
          {separateLines.map((item: any) => {
            idx++;
            return (
              <>
                <a type="primary" target="_blank" href={item}>
                  Mẫu {idx}
                </a>{" "}
                <br />
              </>
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  const renderContentStatus = (record: any) => {
    if (record.content_approve_status) {
      return <CheckCircleOutlined style={{ color: "green" }} />;
    } else {
      return <CloseCircleOutlined style={{ color: "volcano" }} />;
    }
  };

  const renderCreatedDate = (record: any) => {
    return (
      <>
        <Row>
          <Col>{moment(record.created_date).format("DD/MM/YYYY")}</Col>
        </Row>
        <Row>
          <Col>By: {record.created_by_name}</Col>
        </Row>
      </>
    );
  };

  const onFinish = (data: any) => {
    console.log(data);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    if (data.images && data.images.fileList && data.images.fileList.length > 0) {
      let imgs = [];
      let listFilePath = "";
      for (const image of data.images.fileList) {
        if (image.originFileObj) {
          imgs.push(image.originFileObj);
        } else {
          listFilePath += image.name + "|";
        }
      }
      if (listFilePath.length > 0) {
        listFilePath = listFilePath.substring(0, listFilePath.length - 1);
      }
      data.images = listFilePath;
      data.files = imgs;
    }
    data.type = selectedTab;

    data.timing = moment(data.timing).format("YYYY-MM-DD");
    if (data.id) {
      dispatch(updateIdea(data, config));
    } else {
      dispatch(addIdea(data, config));
    }
  };

  const [form] = Form.useForm();
  const [formNew] = Form.useForm();
  const loadUpdate = (data: any) => {
    console.log(data);
    setVisible(true);
    setFormTitle("Update");
    loadDataDropdown();

    setSelectedTab(data.type);
    if (data.type == "spy") {
      setTabNewStatus(true);
      setTabSpyStatus(false);
    } else {
      setTabNewStatus(false);
      setTabSpyStatus(true);
    }
    //
    let listImg: any[] = [];
    if (data.images) {
      var listImages = data.images.split("|");
      for (let index = 0; index < listImages.length; index++) {
        const element = listImages[index];
        const fileData = {
          status: "done",
          name: element,
          thumbUrl: process.env.REACT_APP_API_SERVER_URL + element,
          url: process.env.REACT_APP_API_SERVER_URL + element,
        };
        listImg.push(fileData);
      }
    }
    setFileList(listImg);

    const formData = {
      id: data.id,
      old_title: data.old_title,
      new_title: data.new_title,
      sample: data.sample,
      niche: data.niche_id,
      product_type: data.product_type_id,
      image_type: data.image_type,
      copyright: data.copyright,
      old_description: data.old_description,
      new_description: data.new_description,
      note: data.note,
      status: String(data.status),
      approve_status: String(data.approve_status),
      images: data.images,
    };

    if (data.type == "spy") {
      form.setFieldsValue(formData);
    } else {
      formNew.setFieldsValue(formData);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"],
    },
    {
      title: "Action",
      render: (_, record) => (
        <>
          <EyeOutlined />
          {hasRole(userInfo?.groups, ROLE_IDEA) && (
            <EditOutlined style={{ marginLeft: 10 }} onClick={() => loadUpdate(record)} />
          )}
        </>
      ),
    },
    {
      title: "Tiêu đề cũ",
      dataIndex: "old_title",
    },
    {
      title: "Tiêu đề mới",
      dataIndex: "new_title",
    },
    {
      title: "Sản phẩm mẫu",
      dataIndex: "sample",
      render: (_, record) => <>{renderSampleLink(record)}</>,
    },
    {
      title: "Ảnh mẫu",
      dataIndex: "images",
      render: (_, record) => <>{renderImage(record)}</>,
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      render: (_, record) => <>{renderStatus(record)}</>,
    },
    {
      title: "Trạng thái",
      dataIndex: "approve_status",
      render: (_, record) => <>{renderApproveStatus(record)}</>,
    },
    {
      title: "Design status",
      dataIndex: "design_status",
      render: (_, record) => <>{renderDesignStatus(record)}</>,
    },
    {
      title: "Content",
      dataIndex: "content",
      render: (_, record) => <>{renderContentStatus(record)}</>,
    },
    {
      title: "Niche",
      dataIndex: "niche_name",
    },
    {
      title: "Copyright",
      dataIndex: "copyright",
    },
    {
      title: "Spy/New",
      dataIndex: "type",
    },
    {
      title: "Ngày tạo",
      render: (_, record) => <>{renderCreatedDate(record)}</>,
    },
  ];

  const onChange: TableProps<IdeaModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);

  const loadDataDropdown = () => {
    if (!listNiche || listNiche.length <= 0) {
      dispatch(getlistNiche({}));
    }
    if (!listProductType || listProductType.length <= 0) {
      dispatch(getlistProductType({}));
    }
  };

  const showDrawer = () => {
    setVisible(true);
    setFormTitle("Thêm mới");
    setFileList([]);

    setTabNewStatus(false);
    setTabSpyStatus(false);

    loadDataDropdown();

    form.setFieldsValue({
      id: "",
      old_title: "",
      new_title: "",
      sample: "",
      niche: "",
      product_type: "",
      image_type: "",
      copyright: "",
      old_description: "",
      new_description: "",
      note: "",
      status: "",
      approve_status: "",
      images: undefined,
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một item.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        dispatch(deleteIdea(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onSearch = (keyword: string) => {
    console.log("");
    const searchRequest = {
      ...requestParams,
      keyword: keyword,
    };
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onTabClick = (value: any) => {
    console.log(value);
    setSelectedTab(value);
  };

  const onApproveStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    const data = {
      list_id: [form.getFieldValue("id")],
      approve_status: value,
    };
    dispatch(updateIdeaStatus(data));
  };

  const onCompleteStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    const data = {
      list_id: [form.getFieldValue("id")],
      status: value,
    };
    dispatch(updateIdeaStatus(data));
  };

  const onClearFilter = () => {
    const searchRequest = {
      ...requestParams,
      designer: "",
      content_creator: "",
      niche: "",
      type: "",
    };

    setRequestParams(searchRequest);
    setFilterDesignerVal("");
    setfilterContentVal("");
    setfilterNichVal("");
    setfilterTypeVal("");
    dispatch(getlistIdea(searchRequest));
  };

  const onShowFilter = () => {
    setShowFilter(!showFilter);
    if (!listUsers || listUsers.length <= 0) {
      dispatch(getListUsers());
    }
  };

  const onFilterDesignChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      designer: value,
    };
    setFilterDesignerVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterContentChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      content_creator: value,
    };
    setRequestParams(searchRequest);
    setfilterContentVal(value);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterNicheChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      niche: value,
    };
    setfilterNichVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterTypeChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      type: value,
    };
    setfilterTypeVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  useEffect(() => {
    if (listUsers) {
      const first_option = {
        label: "Chọn degisner",
        value: "",
      };
      const options = buildSelectUserOptions(listUsers, first_option, "Designer");
      setDesignFilterOptions(options);

      // Content Creator
      const first_option_content = {
        label: "Chọn content creator",
        value: "",
      };
      const options_content = buildSelectUserOptions(
        listUsers,
        first_option_content,
        "Content Creator"
      );
      setContentFilterOptions(options_content);
    }
  }, [listUsers]);

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Danh sách Idea</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            {hasRole(userInfo?.groups, ROLE_IDEA) && (
              <>
                <Button
                  onClick={showDrawer}
                  type="primary"
                  style={{ marginBottom: 16 }}
                  icon={<PlusOutlined />}
                >
                  Thêm mới
                </Button>
                <Button
                  type="primary"
                  onClick={showConfirm}
                  style={{ marginBottom: 16 }}
                  icon={<DeleteOutlined />}
                >
                  Xóa
                </Button>
              </>
            )}
          </Space>
          <Space direction="horizontal" style={{ float: "right" }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
            <FilterFilled onClick={onShowFilter} />
          </Space>

          {showFilter && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Select
                  showSearch
                  defaultValue={""}
                  style={{ minWidth: 200 }}
                  value={filterDesignerVal}
                  onChange={onFilterDesignChange}
                  options={designFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  showSearch
                  defaultValue={""}
                  value={filterContentVal}
                  style={{ marginLeft: 15, minWidth: 200 }}
                  onChange={onFilterContentChange}
                  options={contentFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  showSearch
                  defaultValue={""}
                  value={filterNichVal}
                  placeholder="Niche"
                  style={{ marginLeft: 15, minWidth: 200 }}
                  onChange={onFilterNicheChange}
                  options={nicheFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Spy/New"
                  value={filterTypeVal}
                  defaultValue={""}
                  onChange={onFilterTypeChange}
                >
                  <Option value="">Spy/New</Option>
                  <Option value="spy">Spy</Option>
                  <Option value="new">New</Option>
                </Select>
                <Button type="link" onClick={onClearFilter}>
                  Xóa bộ lọc
                </Button>
              </Col>
            </Row>
          )}

          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listIdea}
            onChange={onChange}
            scroll={{ x: 2200 }}
          />

          <Drawer
            title={formTitle}
            width={1024}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Tabs defaultActiveKey={selectedTab} onTabClick={onTabClick}>
                <Tabs.TabPane tab="Idea spy" key="spy" disabled={tabSpyStatus}>
                  <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item hidden name="id">
                      <Input type="hidden" />
                    </Form.Item>

                    {formTitle == "Update" && (
                      <Row gutter={16}>
                        {userInfo.groups.indexOf("Idea") > -1 && (
                          <Col span={12}>
                            <Form.Item label="Tình trạng" name="status">
                              <Radio.Group onChange={onCompleteStatusChange}>
                                <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                                <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        )}
                        {userInfo.groups.indexOf("Admin") > -1 && (
                          <>
                            <Col span={12}>
                              <Form.Item label="Tình trạng" name="status">
                                <Radio.Group onChange={onCompleteStatusChange}>
                                  <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                                  <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label="Phê duyệt" name="approve_status">
                                <Radio.Group onChange={onApproveStatusChange}>
                                  <Radio.Button value="1">Duyệt</Radio.Button>
                                  <Radio.Button value="0">Chưa duyệt</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}

                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="old_title"
                          label="Tiêu đề cũ"
                          rules={[{ required: true, message: "Tiêu đề cũ là thông tin bắt buộc." }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="new_title"
                          label="Tiêu đề mới"
                          rules={[
                            { required: true, message: "Tiêu đề mới là thông tin bắt buộc." },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Form.Item name="sample" label="Link sản phẩm mẫu">
                          <Input.TextArea rows={2} placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <div className="clearfix">
                          <Form.Item name="images" label="Ảnh sản phẩm mẫu (Tối đa 4 ảnh)">
                            <Upload
                              listType="picture-card"
                              fileList={fileList}
                              beforeUpload={() => false}
                              onChange={handleFileChange}
                              multiple={true}
                            >
                              {fileList.length >= 4 ? null : (
                                <div>
                                  <PlusOutlined />
                                  <div className="ant-upload-text">Upload</div>
                                </div>
                              )}
                            </Upload>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="niche"
                          label="Niche"
                          rules={[{ required: true, message: "Niche là thông tin bắt buộc." }]}
                        >
                          <Select options={nicheOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="product_type"
                          label="Loại sản phẩm"
                          rules={[
                            { required: true, message: "Loại sản phẩm là thông tin bắt buộc." },
                          ]}
                        >
                          <Select options={productTypeOptions} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Loại hình ảnh"
                          name="image_type"
                          rules={[{ required: true, message: "Trạng thái là thông tin bắt buộc." }]}
                        >
                          <Radio.Group>
                            <Radio value="2D">2D</Radio>
                            <Radio value="3D">3D</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Copyright"
                          name="copyright"
                          rules={[{ required: true, message: "Copyright là thông tin bắt buộc." }]}
                        >
                          <Radio.Group>
                            <Radio value="Trademark">Trademark</Radio>
                            <Radio value="Patent">Patent</Radio>
                            <Radio value="N/A">N/A</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="old_description"
                          label="Mô tả cũ"
                          rules={[{ required: true, message: "Mô tả cũ là thông tin bắt buộc." }]}
                        >
                          <Input.TextArea rows={3} placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item name="new_description" label="Mô tả mới">
                          <Input.TextArea rows={3} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={24} xs={24}>
                        <Form.Item name="note" label="Mô tả yêu cầu cho bộ phận design">
                          <Input.TextArea rows={3} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ float: "right" }}>
                      <Col>
                        <Space>
                          <Button htmlType="submit" type="primary">
                            Submit
                          </Button>
                          <Button onClick={onClose}>Cancel</Button>
                        </Space>
                      </Col>
                    </Row>
                  </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab="New idea" key="new" disabled={tabNewStatus}>
                  <Form form={formNew} layout="vertical" onFinish={onFinish}>
                    <Form.Item hidden name="id">
                      <Input type="hidden" />
                    </Form.Item>

                    {formTitle == "Update" && (
                      <Row gutter={16}>
                        {userInfo.groups.indexOf("Idea") > -1 && (
                          <Col span={12}>
                            <Form.Item label="Tình trạng sản phẩm" name="status">
                              <Radio.Group onChange={onCompleteStatusChange}>
                                <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                                <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        )}
                        {userInfo.groups.indexOf("Admin") > -1 && (
                          <>
                            <Col span={12}>
                              <Form.Item label="Tình trạng sản phẩm" name="status">
                                <Radio.Group onChange={onCompleteStatusChange}>
                                  <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                                  <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label="Phê duyệt" name="approve_status">
                                <Radio.Group onChange={onApproveStatusChange}>
                                  <Radio.Button value="1">Duyệt</Radio.Button>
                                  <Radio.Button value="0">Chưa duyệt</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}

                    <Row gutter={16}>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="new_title"
                          label="Tiêu đề mới"
                          rules={[
                            { required: true, message: "Tiêu đề mới là thông tin bắt buộc." },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Form.Item name="sample" label="Link sản phẩm mẫu">
                          <Input.TextArea rows={2} placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <div className="clearfix">
                          <Form.Item name="images" label="Ảnh sản phẩm mẫu (Tối đa 4 ảnh)">
                            <Upload
                              listType="picture-card"
                              fileList={fileList}
                              beforeUpload={() => false}
                              onChange={handleFileChange}
                              multiple={true}
                            >
                              {fileList.length >= 4 ? null : (
                                <div>
                                  <PlusOutlined />
                                  <div className="ant-upload-text">Upload</div>
                                </div>
                              )}
                            </Upload>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="niche"
                          label="Niche"
                          rules={[{ required: true, message: "Niche là thông tin bắt buộc." }]}
                        >
                          <Select options={nicheOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="product_type"
                          label="Loại sản phẩm"
                          rules={[
                            { required: true, message: "Loại sản phẩm là thông tin bắt buộc." },
                          ]}
                        >
                          <Select options={productTypeOptions} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Loại hình ảnh"
                          name="image_type"
                          rules={[{ required: true, message: "Trạng thái là thông tin bắt buộc." }]}
                        >
                          <Radio.Group>
                            <Radio value="2D">2D</Radio>
                            <Radio value="3D">3D</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Copyright"
                          name="copyright"
                          rules={[{ required: true, message: "Copyright là thông tin bắt buộc." }]}
                        >
                          <Radio.Group>
                            <Radio value="Trademark">Trademark</Radio>
                            <Radio value="Patent">Patent</Radio>
                            <Radio value="N/A">N/A</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={24} xs={24}>
                        <Form.Item name="new_description" label="Mô tả mới">
                          <Input.TextArea rows={3} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={24} xs={24}>
                        <Form.Item name="note" label="Mô tả yêu cầu cho bộ phận design">
                          <Input.TextArea rows={3} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ float: "right" }}>
                      <Col>
                        <Space>
                          <Button htmlType="submit" type="primary">
                            Submit
                          </Button>
                          <Button onClick={onClose}>Cancel</Button>
                        </Space>
                      </Col>
                    </Row>
                  </Form>
                </Tabs.TabPane>
              </Tabs>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default ListIdeas;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { GuidelineModel, IGuidelineResponse } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IGuideline {
  requestStatus: boolean,
  listGuideline: GuidelineModel[],
  guideline: any,
}

// Khởi tạo state cho slice
const initialState: IGuideline = {
  requestStatus: false,
  listGuideline: [],
  guideline: undefined

};

// Cấu hình slice
export const userSlice = createSlice({
  name: "guideline",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListGuideline: (state, action: PayloadAction<GuidelineModel[]>) => {
      state.listGuideline = action.payload
    },
    setGuideline: (state, action: PayloadAction<GuidelineModel>) => {
      state.guideline = action.payload
    },
  }
});

export const guidelineSelector = (state: RootState): IGuideline => state.guideline

// =========================
/// Actions
// =========================
export const {
  setListGuideline,
  setGuideline,
  setRequestStatus
} = userSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListGuideline = (type: number): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<IGuidelineResponse>(`/api/guideline/?type=${type}`);
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setListGuideline(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const retrieveGuideline = (id: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get(`/api/guideline/` + id);
    console.log(response);
    dispatch(setGuideline(response.data.data))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}


export const addGuideline = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IGuidelineResponse>(`/api/guideline/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["guideline"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateGuideline = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.patch<IGuidelineResponse>(`/api/guideline/${data.id}/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["guideline"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteGuideline = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IGuidelineResponse>(`/api/guideline/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["guideline"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default userSlice.reducer;

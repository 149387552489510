import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { SettingStoreModel } from "../../models/models";
import api from "../../services/api";
import storeService from "../../services/store.service";
import { setLoading } from "./app-slice";

export interface ISettingStore {
  requestStatus: boolean,
  listStores: SettingStoreModel[]
}

// Khởi tạo state cho slice
const initialState: ISettingStore = {
  requestStatus: false,
  listStores: []

};

// Cấu hình slice
export const settingSlice = createSlice({
  name: "setting",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListStores: (state, action: PayloadAction<SettingStoreModel[]>) => {
      state.listStores = action.payload
    },
  }
});

export const storesSelector = (state: RootState): ISettingStore => state.setting

// =========================
/// Actions
// =========================
export const {
  setListStores,
  setRequestStatus
} = settingSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListStores = (params: any): AppThunk => async dispatch => {
  try {

    // Call api
    // dispatch(setLoading(true))
    const response = await storeService.getListStoresApi(params);
    const newData = response.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setListStores(newData))

  } catch (error) {
    console.log(error);
  } finally {
    // dispatch(setLoading(false))
  }
}

export const addStore = (data: any): AppThunk => async dispatch => {
  try {

    // Call api
    const response = await storeService.addStoreApi(data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["store"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
  }
}

export const updateStore = (data: any): AppThunk => async dispatch => {
  try {
    // Call api
    const response = await api.patch(`/api/store/${data.id}/`, data);;
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["store"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
  }
}

export const deleteStore = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await storeService.deleteStoreApi(data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["store"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default settingSlice.reducer;

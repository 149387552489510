import { Button, Card, Col, Form, Input, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { doLogin } from "../../store/slices/auth-slide";

const Login: React.FC = () => {
  const initialFormData = Object.freeze({
    username: "",
    password: "",
  });
  const dispatch = useDispatch();
  const [formData, updateFormData] = React.useState(initialFormData);

  const handleChange = (e: any) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    dispatch(doLogin(formData));
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col>
        <Card>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input name="username" onChange={handleChange} onKeyDown={handleKeyDown} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password name="password" onChange={handleChange} onKeyDown={handleKeyDown} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="button" onClick={handleLogin}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;

export const PER_VIEW_STORE = `view_settingstore`;
export const PER_ADD_STORE = `add_settingstore`;
export const PER_UPDATE_STORE = `change_settingstore`;
export const PER_DELETE_STORE = `delete_settingstore`;

export const PER_VIEW_USER = `view_user`;
export const PER_ADD_USER = `add_user`;
export const PER_UPDATE_USER = `change_user`;
export const PER_DELETE_USER = `delete_user`;

export const PER_VIEW_PRODUCT = `view_product`;
export const PER_ADD_PRODUCT = `add_product`;
export const PER_UPDATE_PRODUCT = `change_product`;
export const PER_DELETE_PRODUCT = `delete_product`;

export const PER_VIEW_FULLFILL = `view_fullfill`;
export const PER_ADD_FULLFILL = `add_fullfill`;
export const PER_UPDATE_FULLFILL = `change_fullfill`;
export const PER_DELETE_FULLFILL = `delete_fullfill`;

export const PER_VIEW_GUIDE = `view_product`;
export const PER_SUPPER_USER = `super_user`;


export const GUIDE_TYPE = 1;
export const TRAINING_TYPE = 2;

export const ROLE_ADMIN = `Admin`;
export const ROLE_MANAGER = `Manager`;
export const ROLE_DESIGNER = `Designer`;
export const ROLE_CONTENT_CREATOR = `Content Creator`;
export const ROLE_LISTING = `Listing`;
export const ROLE_IDEA = `Idea`;
export const ROLE_SUPPORT = `Support`;
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  FilterFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Image,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
  Tabs,
  Tag,
  Upload,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_IDEA } from "../../common/cms_const";
import { buildSelectUserOptions, renderHTML } from "../../common/common_utils";
import TextEditor from "../../components/TextEditor";
import { IdeaModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addIdea,
  getlistIdea,
  ideaSelector,
  setRequestStatus,
  updateIdea,
  updateIdeaStatus,
} from "../../store/slices/idea-slide";
import { getlistNiche, nicheSelector } from "../../store/slices/niche-slide";
import { getlistProductType, productTypeSelector } from "../../store/slices/productType-slide";
import { getListUsers, userSelector } from "../../store/slices/user-slide";

const { Option } = Select;

const IdeaSummary: React.FC = () => {
  const dispatch = useDispatch();
  const { appLoading, userInfo } = useSelector(appSelector);
  const { listIdea, requestStatus, updateStatus } = useSelector(ideaSelector);
  const [fileList, setFileList] = useState<any[]>([]);
  const [formTitle, setFormTitle] = useState("");
  const [nicheOptions, setNicheOptions] = useState<SelectProps["options"]>([]);
  const [productTypeOptions, setProductTypeOptions] = useState<SelectProps["options"]>([]);
  const { listNiche } = useSelector(nicheSelector);
  const { listProductType } = useSelector(productTypeSelector);
  const [selectedTab, setSelectedTab] = useState("spy");
  const [showFilter, setShowFilter] = useState(false);
  const [designFilterOptions, setDesignFilterOptions] = useState<any>([]);
  const [contentFilterOptions, setContentFilterOptions] = useState<any>([]);
  const [nicheFilterOptions, setNicheFilterOptions] = useState<any>([]);
  const { listUsers } = useSelector(userSelector);
  const [filterDesignerVal, setFilterDesignerVal] = useState("");
  const [filterContentVal, setfilterContentVal] = useState("");
  const [filterNichVal, setfilterNichVal] = useState("");
  const [filterTypeVal, setfilterTypeVal] = useState("");
  const [ideaInfo, setIdeaInfo] = useState<any>();
  const [startDate, setStartDate] = useState<any>();

  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
    status: "",
    designer: "",
    content_creator: "",
    niche: "",
    type: "",
    approve_status: "1",
    content_approve: "1",
    design_approve: "1",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    if (listNiche) {
      const optionsData: SelectProps["options"] = [];
      const optionsData2: SelectProps["options"] = [];

      optionsData2.push({
        label: "Chọn Niche",
        value: "",
      });

      for (let i = 0; i < listNiche.length; i++) {
        optionsData.push({
          label: listNiche[i].name,
          value: listNiche[i].id,
        });

        optionsData2.push({
          label: listNiche[i].name,
          value: listNiche[i].id,
        });
      }
      setNicheOptions(optionsData);
      setNicheFilterOptions(optionsData2);
    }
  }, [listNiche]);

  useEffect(() => {
    if (listProductType) {
      const optionsData: SelectProps["options"] = [];
      for (let i = 0; i < listProductType.length; i++) {
        optionsData.push({
          label: listProductType[i].name,
          value: listProductType[i].id,
        });
      }
      setProductTypeOptions(optionsData);
    }
  }, [listProductType]);

  const handleFileChange = (uploadData: any) => {
    console.log(uploadData);
    setFileList(uploadData.fileList);
  };

  useEffect(() => {
    var currentDate = moment(new Date());
    setStartDate(currentDate);
    const searchRequest = {
      ...requestParams,
      start_date: moment(currentDate).format("YYYY-MM-DD"),
    };
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
    if (!listNiche || listNiche.length <= 0) {
      dispatch(getlistNiche({}));
    }
  }, []);

  useEffect(() => {
    if (requestStatus) {
      if (!updateStatus) {
        setVisible(false);
      }
      dispatch(getlistIdea(requestParams));
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderDesignStatus = (row: any) => {
    let txt1 = "Chưa duyệt";
    let txt2 = "Chưa duyệt";
    let design_type = "New design";

    if (row.type == "spy") {
      design_type = "Redesign:";
    }

    if (row.design_print_status == 1) {
      txt1 = "Đã duyệt";
    }

    if (row.design_mockup_status == 1) {
      txt2 = "Đã duyệt";
    }

    return (
      <>
        <Row>
          <Col>
            <Tag color={row.design_print_status == 1 ? "green" : "volcano"}>
              {row.design_print_status == 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
              {design_type} {txt1}
            </Tag>
          </Col>
        </Row>
        <Row style={{ marginTop: 3 }}>
          <Col>
            <Tag color={row.design_mockup_status == 1 ? "green" : "volcano"}>
              {row.design_mockup_status == 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
              Mockup: {txt2}
            </Tag>
          </Col>
        </Row>
      </>
    );
  };

  const renderContentStatus = (row: any) => {
    let txt1 = "Chưa hoàn thiện";
    let txt2 = "Chưa duyệt";
    if (row.content_status == 1) {
      txt1 = "Đã hoàn thiện";
    }
    if (row.content_approve_status == 1) {
      txt2 = "Đã duyệt";
    }
    return (
      <>
        <Row>
          <Col>
            <Tag color={row.content_status == 1 ? "green" : "volcano"}>
              {row.content_status == 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />} {txt1}
            </Tag>
          </Col>
        </Row>
        <Row style={{ marginTop: 3 }}>
          <Col>
            <Tag color={row.content_approve_status == 1 ? "green" : "volcano"}>
              {row.content_approve_status == 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
              {txt2}
            </Tag>
          </Col>
        </Row>
      </>
    );
  };

  const renderImage = (record: any) => {
    if (record.images) {
      var listImages = record.images.split("|");
      return (
        <>
          {listImages.map((item: any) => {
            return (
              <img
                style={{ width: "50px", margin: "3px" }}
                src={process.env.REACT_APP_API_SERVER_URL + item}
              />
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  const renderCreatedDate = (record: any) => {
    return (
      <>
        <Row>
          <Col>{moment(record.created_date).format("DD/MM/YYYY")}</Col>
        </Row>
      </>
    );
  };

  const onFinish = (data: any) => {
    console.log(data);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    if (data.images.fileList && data.images.fileList.length > 0) {
      let imgs = [];
      let listFilePath = "";
      for (const image of data.images.fileList) {
        if (image.originFileObj) {
          imgs.push(image.originFileObj);
        } else {
          listFilePath += image.name + "|";
        }
      }
      if (listFilePath.length > 0) {
        listFilePath = listFilePath.substring(0, listFilePath.length - 1);
      }
      data.images = listFilePath;
      data.files = imgs;
    } else {
    }

    data.timing = moment(data.timing).format("YYYY-MM-DD");
    if (data.id) {
      dispatch(updateIdea(data, config));
    } else {
      dispatch(addIdea(data, config));
    }
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    setVisible(true);
    setFormTitle("Update");
    loadDataDropdown();

    let images = [];
    if (data.images) {
      images = data.images.split("|");
    }

    let mockups = [];
    if (data.images) {
      mockups = data.mockup_files.split("|");
    }

    let prints = [];
    if (data.images) {
      prints = data.print_files.split("|");
    }

    const ideaData = {
      new_content: data.new_content,
      mockup_files: mockups,
      images: images,
      new_title: data.new_title,
      print_files: prints,
    };
    setIdeaInfo(ideaData);

    let listImg: any[] = [];
    if (data.images) {
      var listImages = data.images.split("|");
      for (let index = 0; index < listImages.length; index++) {
        const element = listImages[index];
        const fileData = {
          status: "done",
          name: element,
          thumbUrl: process.env.REACT_APP_API_SERVER_URL + element,
          url: process.env.REACT_APP_API_SERVER_URL + element,
        };
        listImg.push(fileData);
      }
    }
    setFileList(listImg);

    form.setFieldsValue({
      id: data.id,
      old_title: data.old_title,
      new_title: data.new_title,
      sample: data.sample,
      niche: data.niche_id,
      product_type: data.product_type_id,
      image_type: data.image_type,
      copyright: data.copyright,
      old_description: data.old_description,
      new_description: data.new_description,
      note: data.note,
      status: String(data.status),
      approve_status: String(data.approve_status),
      images: data.images,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"],
    },
    {
      title: "Action",
      render: (_, record) => (
        <>
          <EyeOutlined onClick={() => loadUpdate(record)} />
        </>
      ),
    },
    {
      title: "Tiêu đề mới",
      dataIndex: "new_title",
    },
    {
      title: "Ảnh mẫu",
      dataIndex: "images",
      render: (_, record) => <>{renderImage(record)}</>,
    },
    {
      title: "Design",
      dataIndex: "design_status",
      render: (_, record) => <>{renderDesignStatus(record)}</>,
    },
    {
      title: "Content",
      dataIndex: "content",
      render: (_, record) => <>{renderContentStatus(record)}</>,
    },
    {
      title: "Người tạo",
      dataIndex: "created_by_name",
    },
    {
      title: "Ngày tạo",
      render: (_, record) => <>{renderCreatedDate(record)}</>,
    },
  ];

  const onChange: TableProps<IdeaModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);

  const loadDataDropdown = () => {
    if (!listNiche || listNiche.length <= 0) {
      dispatch(getlistNiche({}));
    }
    if (!listProductType || listProductType.length <= 0) {
      dispatch(getlistProductType());
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  const onSearch = (keyword: string) => {
    console.log("");
    const searchRequest = {
      ...requestParams,
      keyword: keyword,
    };
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onTabClick = (value: any) => {
    console.log(value);
    setSelectedTab(value);
  };

  const onApproveStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    const data = {
      list_id: [form.getFieldValue("id")],
      approve_status: value,
    };
    dispatch(updateIdeaStatus(data));
  };

  const onCompleteStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    const data = {
      list_id: [form.getFieldValue("id")],
      status: value,
    };
    dispatch(updateIdeaStatus(data));
  };

  const onClearFilter = () => {
    const searchRequest = {
      ...requestParams,
      designer: "",
      content_creator: "",
      niche: "",
      type: "",
    };

    setRequestParams(searchRequest);
    setFilterDesignerVal("");
    setfilterContentVal("");
    setfilterNichVal("");
    setfilterTypeVal("");
    dispatch(getlistIdea(searchRequest));
  };

  const onShowFilter = () => {
    setShowFilter(!showFilter);
    if (!listUsers || listUsers.length <= 0) {
      dispatch(getListUsers());
    }
  };

  const onFilterDesignChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      designer: value,
    };
    setFilterDesignerVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterContentChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      content_creator: value,
    };
    setRequestParams(searchRequest);
    setfilterContentVal(value);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterNicheChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      niche: value,
    };
    setfilterNichVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterTypeChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      type: value,
    };
    setfilterTypeVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  useEffect(() => {
    if (listUsers) {
      const first_option = {
        label: "Chọn degisner",
        value: "",
      };
      const options = buildSelectUserOptions(listUsers, first_option, "Designer");
      setDesignFilterOptions(options);

      // Content Creator
      const first_option_content = {
        label: "Chọn content creator",
        value: "",
      };
      const options_content = buildSelectUserOptions(
        listUsers,
        first_option_content,
        "Content Creator"
      );
      setContentFilterOptions(options_content);
    }
  }, [listUsers]);

  const onStartDateChange = (date: any) => {
    console.log(date);
    const searchRequest = {
      ...requestParams,
      start_date: date != null ? moment(date).format("YYYY-MM-DD") : "",
    };
    setRequestParams(searchRequest);
    setStartDate(date);
    dispatch(getlistIdea(searchRequest));
  };

  const onEndDateChange = (date: any) => {
    console.log(date);
    const searchRequest = {
      ...requestParams,
      end_date: date != null ? moment(date).format("YYYY-MM-DD") : "",
    };
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Tổng quan Idea</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            <DatePicker
              style={{ marginBottom: 16 }}
              placeholder="Ngày bắt đầu"
              value={startDate}
              onChange={onStartDateChange}
              format="DD/MM/YYYY"
            />
            <DatePicker
              placeholder="Ngày kết thúc"
              style={{ marginBottom: 16 }}
              onChange={onEndDateChange}
              format="DD/MM/YYYY"
            />
          </Space>
          <Space direction="horizontal" style={{ float: "right" }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
            <FilterFilled onClick={onShowFilter} />
          </Space>

          {showFilter && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Select
                  showSearch
                  defaultValue={""}
                  style={{ minWidth: 200 }}
                  value={filterDesignerVal}
                  onChange={onFilterDesignChange}
                  options={designFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  showSearch
                  defaultValue={""}
                  value={filterContentVal}
                  style={{ marginLeft: 15, minWidth: 200 }}
                  onChange={onFilterContentChange}
                  options={contentFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  showSearch
                  defaultValue={""}
                  value={filterNichVal}
                  placeholder="Niche"
                  style={{ marginLeft: 15, minWidth: 200 }}
                  onChange={onFilterNicheChange}
                  options={nicheFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Spy/New"
                  value={filterTypeVal}
                  defaultValue={""}
                  onChange={onFilterTypeChange}
                >
                  <Option value="">Spy/New</Option>
                  <Option value="spy">Spy</Option>
                  <Option value="new">New</Option>
                </Select>
                <Button type="link" onClick={onClearFilter}>
                  Xóa bộ lọc
                </Button>
              </Col>
            </Row>
          )}

          <Table columns={columns} dataSource={listIdea} onChange={onChange} />

          <Drawer
            title={formTitle}
            width={1024}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Tabs defaultActiveKey={selectedTab} onTabClick={onTabClick}>
                <Tabs.TabPane tab="Idea spy" key="spy">
                  <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item hidden name="id">
                      <Input type="hidden" />
                    </Form.Item>

                    {formTitle == "Update" && (
                      <Row gutter={16}>
                        {userInfo.groups.indexOf("Idea") > -1 && (
                          <Col span={12}>
                            <Form.Item label="Tình trạng sản phẩm" name="status">
                              <Radio.Group onChange={onCompleteStatusChange}>
                                <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                                <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        )}
                        {userInfo.groups.indexOf("Admin") > -1 && (
                          <>
                            <Col span={12}>
                              <Form.Item label="Tình trạng sản phẩm" name="status">
                                <Radio.Group onChange={onCompleteStatusChange}>
                                  <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                                  <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label="Phê duyệt" name="approve_status">
                                <Radio.Group onChange={onApproveStatusChange}>
                                  <Radio.Button value="1">Duyệt</Radio.Button>
                                  <Radio.Button value="0">Chưa duyệt</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}

                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="old_title"
                          label="Tiêu đề cũ"
                          rules={[{ required: true, message: "Tiêu đề cũ là thông tin bắt buộc." }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="new_title"
                          label="Tiêu đề mới"
                          rules={[
                            { required: true, message: "Tiêu đề mới là thông tin bắt buộc." },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Form.Item name="sample" label="Link sản phẩm mẫu">
                          <Input.TextArea rows={2} placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <div className="clearfix">
                          <Form.Item name="images" label="Ảnh sản phẩm mẫu (Tối đa 4 ảnh)">
                            <Upload
                              listType="picture-card"
                              fileList={fileList}
                              beforeUpload={() => false}
                              onChange={handleFileChange}
                              multiple={true}
                            >
                              {fileList.length >= 4 ? null : (
                                <div>
                                  <PlusOutlined />
                                  <div className="ant-upload-text">Upload</div>
                                </div>
                              )}
                            </Upload>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="niche"
                          label="Niche"
                          rules={[{ required: true, message: "Niche là thông tin bắt buộc." }]}
                        >
                          <Select options={nicheOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="product_type"
                          label="Loại sản phẩm"
                          rules={[
                            { required: true, message: "Loại sản phẩm là thông tin bắt buộc." },
                          ]}
                        >
                          <Select options={productTypeOptions} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Loại hình ảnh"
                          name="image_type"
                          rules={[{ required: true, message: "Trạng thái là thông tin bắt buộc." }]}
                        >
                          <Radio.Group>
                            <Radio value="2D">2D</Radio>
                            <Radio value="3D">3D</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Copyright"
                          name="copyright"
                          rules={[{ required: true, message: "Copyright là thông tin bắt buộc." }]}
                        >
                          <Radio.Group>
                            <Radio value="Trademark">Trademark</Radio>
                            <Radio value="Patent">Patent</Radio>
                            <Radio value="N/A">N/A</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="old_description"
                          label="Mô tả cũ"
                          rules={[{ required: true, message: "Mô tả cũ là thông tin bắt buộc." }]}
                        >
                          <Input.TextArea rows={3} placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item name="new_description" label="Mô tả mới">
                          <Input.TextArea rows={3} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col md={24} xs={24}>
                        <Form.Item name="note" label="Mô tả yêu cầu cho bộ phận design">
                          <Input.TextArea rows={3} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ float: "right" }}>
                      <Col>
                        <Space>
                          <Button htmlType="submit" type="primary">
                            Submit
                          </Button>
                          <Button onClick={onClose}>Cancel</Button>
                        </Space>
                      </Col>
                    </Row>
                  </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab="New idea" key="new">
                  Content of Tab Pane 2
                </Tabs.TabPane>
              </Tabs>
            </Spin>
          </Drawer>

          <Drawer
            title={formTitle}
            width={1024}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Descriptions bordered labelStyle={{ fontWeight: "600" }}>
                <Descriptions.Item label="Tiêu đề mới" span={3}>
                  {ideaInfo?.new_title}
                </Descriptions.Item>
                <Descriptions.Item label="Ảnh mẫu" span={3}>
                  <Image.PreviewGroup>
                    {ideaInfo?.images?.map((item: any) => {
                      return <Image width={80} src={process.env.REACT_APP_API_SERVER_URL + item} />;
                    })}
                  </Image.PreviewGroup>
                </Descriptions.Item>
                <Descriptions.Item label="File print" span={3}>
                  <Image.PreviewGroup>
                    {ideaInfo?.print_files?.map((item: any) => {
                      return <Image width={80} src={item} />;
                    })}
                  </Image.PreviewGroup>
                </Descriptions.Item>
                <Descriptions.Item label="File mockup" span={3}>
                  <Image.PreviewGroup>
                    {ideaInfo?.mockup_files?.map((item: any) => {
                      return <Image width={80} src={item} />;
                    })}
                  </Image.PreviewGroup>
                </Descriptions.Item>

                <Descriptions.Item label="Mô tả mới" span={3}>
                  <span>{renderHTML(ideaInfo?.new_content)}</span>
                </Descriptions.Item>
              </Descriptions>
              {/* <Divider /> */}

              <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                <Form.Item hidden name="content_id">
                  <Input type="hidden" readOnly />
                </Form.Item>

                {userInfo?.groups?.indexOf(ROLE_IDEA) > -1 && (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item name="content_description" label="Description">
                        <TextEditor value={""} placeholder={""} onChange={() => {}}></TextEditor>
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      {userInfo?.groups?.indexOf(ROLE_IDEA) > -1 && (
                        <>
                          <Button htmlType="submit" type="primary">
                            Submit
                          </Button>
                          <Button onClick={onClose}>Cancel</Button>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default IdeaSummary;

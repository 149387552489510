import { InboxOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import Dragger from "antd/lib/upload/Dragger";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SeachableCategory from "../../components/SeachableCategory";
import SeachableTag from "../../components/SeachableTag";
import TextEditor from "../../components/TextEditor";
import { ImportTaskModel, ProductCategoryModel, ProductTagModel } from "../../models/models";
import api from "../../services/api";
import { appSelector } from "../../store/slices/app-slice";
import { getListCategories } from "../../store/slices/category-slide";
import { descriptionSelector, getListDesciptions } from "../../store/slices/description-slide";
import { listTasksApi, productSelector } from "../../store/slices/product-slide";
import { getListTags } from "../../store/slices/tag-slide";

const { TabPane } = Tabs;

const ImportProductComponent: React.FC = () => {
  const [selectedCategoties, setSelectedCategoties] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [descriptionOptions, setDescriptionOptions] = useState<SelectProps["options"]>([]);
  const { selectedStoreId } = useSelector(appSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { listDescriptions } = useSelector(descriptionSelector);
  const { listTasks } = useSelector(productSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedStoreId && listDescriptions) {
      const optionsData: SelectProps["options"] = [];
      for (let i = 0; i < listDescriptions.length; i++) {
        if (listDescriptions[i].store_id === selectedStoreId) {
          optionsData.push({
            label: listDescriptions[i].title,
            value: listDescriptions[i].id,
          });
        }
      }
      setDescriptionOptions(optionsData);
    }
  }, [selectedStoreId, listDescriptions]);

  useEffect(() => {
    if (selectedStoreId) {
      const param = {
        pageIndex: 1,
        pageSize: 100,
        storeId: selectedStoreId,
      };
      dispatch(getListCategories(param));
      dispatch(getListTags(selectedStoreId));
      dispatch(getListDesciptions({ content_type: 2, storeId: selectedStoreId }));
    }
  }, [selectedStoreId]);

  const onCategoryChange = (value: number[]) => {
    setSelectedCategoties(value);
  };

  const onTagsChange = (value: number[]) => {
    setSelectedTags(value);
  };

  const onDesciptionChange = (value: number) => {
    console.log(value);
    for (let i = 0; i < listDescriptions.length; i++) {
      if (listDescriptions[i].id == value) {
        form.setFieldsValue({
          description: listDescriptions[i].content,
        });
        break;
      }
    }
  };

  const onFinish = async (product: any) => {
    console.log(product);

    if (!selectedStoreId) {
      message.warning("Please select a store.");
      return;
    }

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    setLoading(true);
    fmData.append("file", product.file.file);
    fmData.append("store_id", selectedStoreId);
    if (product.description || content) {
      fmData.append("description", content);
    }
    if (product.regular_price) {
      fmData.append("regular_price", product.regular_price);
    }
    if (product.sale_price) {
      fmData.append("sale_price", product.sale_price);
    }
    if (product.sku) {
      fmData.append("sku", product.sku);
    }

    const categories: ProductCategoryModel[] = [];
    for (let i = 0; i < selectedCategoties.length; i++) {
      const data: ProductCategoryModel = { id: selectedCategoties[i].value };
      categories.push(data);
    }

    if (categories && categories.length > 0) {
      fmData.append("categories", JSON.stringify(categories));
    }

    const tags: ProductTagModel[] = [];
    for (let i = 0; i < selectedTags.length; i++) {
      const tag = selectedTags[i];
      let tagObj: ProductTagModel = {};
      if (typeof tag.value === "string") {
        tagObj = { name: selectedTags[i].value };
      } else {
        tagObj = { id: selectedTags[i].value };
      }
      tags.push(tagObj);
    }
    // product.tags = tags;
    if (tags && tags.length > 0) {
      fmData.append("tags", JSON.stringify(tags));
    }

    // meta
    if (product.focus_keyword) {
      const rank = [];
      let rank_val = "";
      for (let i = 0; i < product.focus_keyword.length; i++) {
        if (i == product.focus_keyword.length - 1) {
          rank_val += product.focus_keyword[i];
        } else {
          rank_val += product.focus_keyword[i] + ",";
        }
      }
      const meta_data = {
        key: "rank_math_focus_keyword",
        value: rank_val,
      };
      rank.push(meta_data);
      // product.meta_data = rank;
      fmData.append("meta_data", JSON.stringify(rank));
    }
    let progress = true;
    try {
      const res = await api.post("/api/product/import/", fmData, config);
      progress = false;
      if (res.data.result == "ok") {
        message.success(res.data.message);
        form.resetFields(['file']);
      } else {
        message.error(res.data.error_message);
      }
      console.log("server res: ", res);
    } catch (err) {
      console.log("Eroor: ", err);
      message.error("ERROR");
    } finally {
      progress = false;
      setLoading(false);
    }
  };
  const handleChangeQuill = (html: any) => {
    setContent(html);
  };

  const [regularPrice, setRegularPrice] = useState();
  const handleInputChange = (value: any) => {
    setRegularPrice(value);
  };
  const [content, setContent] = useState("");

  const onChange = (key: string) => {
    console.log(key);
    if (key == "2") {
      dispatch(listTasksApi());
    }
  };

  const renderStatus = (row: ImportTaskModel) => {
    let color = "volcano";
    if (row.state == "SUCCESS") {
      color = "green";
    } else if (row.state == "FAILURE") {
      color = "volcano";
    } else {
      color = "cyan";
    }
    return (
      <Tag color={color} key={row.state}>
        {row.state.toUpperCase()}
      </Tag>
    );
  };

  const getFinishTime = (row: ImportTaskModel) => {
    if (row.succeeded) {
      return <>{moment.unix(row.succeeded).format("DD/MM/YYYY hh:mm:ss")}</>;
    } else {
      return <>{""}</>;
    }
  };

  const columns: ColumnsType<ImportTaskModel> = [
    {
      title: "ID",
      width: 350,
      dataIndex: "uuid",
    },
    {
      title: "File name",
      dataIndex: "file_name",
    },
    {
      title: "Received",
      dataIndex: "received",
      render: (_, record) => <>{moment.unix(record.received).format("DD/MM/YYYY hh:mm:ss")}</>,
    },
    {
      title: "Succeeded",
      dataIndex: "succeeded",
      render: (_, record) => <>{getFinishTime(record)}</>,
    },
    {
      title: "Status",
      dataIndex: "state",
      render: (_, record) => <>{renderStatus(record)}</>,
    },
  ];

  const [loadings, setLoadings] = useState<boolean[]>([]);

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const handleReloadTask = () => {
    console.log("reload");
    enterLoading(0);
    dispatch(listTasksApi());
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Import product</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360, paddingBottom: 80 }}
      >
        <Tabs defaultActiveKey="1" onChange={onChange}>
          <TabPane tab="Import" key="1">
            <Spin spinning={loading}>
              <Form
                form={form}
                layout="vertical"
                initialValues={{
                  ["sku"]: "{random}",
                }}
                hideRequiredMark
                onFinish={onFinish}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="file"
                      label=""
                      rules={[{ required: true, message: "Chọn zip" }]}
                    >
                      <Dragger
                        multiple={false}
                        accept=".zip"
                        maxCount={1}
                        // {...props}
                        beforeUpload={() => false}
                        // customRequest={uploadImage}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      </Dragger>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item name="categories" label="Categories">
                      <SeachableCategory
                        optionsVal={selectedCategoties}
                        onChange={onCategoryChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="tags" label="Tags">
                      <SeachableTag optionsVal={selectedTags} onChange={onTagsChange} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sku" label="SKU">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item name="regular_price" label="Regular price">
                      <InputNumber
                        value={regularPrice}
                        onChange={handleInputChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="sale_price"
                      label="Sale price"
                      rules={[
                        {
                          message: "Nhập Regular price",
                          validator: (_, value) => {
                            if (value) {
                              if (regularPrice) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("Some message here");
                              }
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                        {
                          message: "Sale price phải nhỏ hơn Regular price",
                          validator: (_, value) => {
                            if (value) {
                              if (regularPrice && value > regularPrice) {
                                return Promise.reject("Some message here");
                              } else {
                                return Promise.resolve();
                              }
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="focus_keyword" label="Focus keyword">
                      <Select mode="tags" style={{ width: "100%" }}></Select>
                    </Form.Item>
                  </Col>
                </Row>

                {selectedStoreId && (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Select
                        style={{ width: "100%", marginBottom: 5 }}
                        placeholder="Chọn description từ template"
                        options={descriptionOptions}
                        onChange={onDesciptionChange}
                      ></Select>
                      <Form.Item name="description" label="Mô tả">
                        <TextEditor
                          value={content}
                          placeholder={""}
                          onChange={handleChangeQuill}
                        ></TextEditor>
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Import
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </TabPane>
          <TabPane tab="List import" key="2">
            <Space direction="horizontal" style={{ float: "right", marginBottom: 16 }}>
              <Button
                type="primary"
                disabled={loadings[0]}
                onClick={handleReloadTask}
                icon={<ReloadOutlined />}
              >
                Refresh
              </Button>
            </Space>
            <Table columns={columns} dataSource={listTasks} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default ImportProductComponent;

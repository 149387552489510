class TokenService {
    getLocalRefreshToken() {
        // @ts-ignore
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.refresh;
    }
    getLocalAccessToken() {
        // @ts-ignore
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.access;
    }
    updateLocalAccessToken(token: string) {
        // @ts-ignore
      let user = JSON.parse(localStorage.getItem("user"));
      user.access = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
    getUser() {
        // @ts-ignore
      return JSON.parse(localStorage.getItem("user"));
    }

    setUserInfo(user:string) {
      localStorage.setItem("user_info", JSON.stringify(user));
    }

    getUserInfo() {
      // @ts-ignore
      return JSON.parse(localStorage.getItem("user_info"));
    }
    setUser(user:string) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    removeUser() {
      localStorage.removeItem("user");
      localStorage.removeItem("user_info");
      localStorage.removeItem("permissions");
      localStorage.removeItem("dropboxStatus");
      // localStorage.removeItem("selectedStore");
    }

    setUserPermissions(user:string) {
      localStorage.setItem("permissions", JSON.stringify(user));
    }

    setDropboxStatus(status:string) {
      localStorage.setItem("dropboxStatus", status);
    }

    getUserPermissions() {
      // @ts-ignore
      return JSON.parse(localStorage.getItem("permissions"));
    }
  }
  export default new TokenService();

  
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { INicheResponse, NicheModel } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface INiche {
  requestStatus: boolean,
  listNiche: NicheModel[],
}

const initialState: INiche = {
  requestStatus: false,
  listNiche: [],
};

export const userSlice = createSlice({
  name: "niche",
  initialState,
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setlistNiche: (state, action: PayloadAction<NicheModel[]>) => {
      state.listNiche = action.payload
    },
  }
});

export const nicheSelector = (state: RootState): INiche => state.niche

// =========================
/// Actions
// =========================
export const {
  setlistNiche,
  setRequestStatus
} = userSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getlistNiche = (data: any = {}): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.get<INicheResponse>(`/api/niche`, { params: data });
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setlistNiche(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}


export const addNiche = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<INicheResponse>(`/api/niche/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["niche"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateNiche = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.patch<INicheResponse>(`/api/niche/${data.id}/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["niche"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteNiche = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.post<INicheResponse>(`/api/niche/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["niche"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export default userSlice.reducer;

import { FilterFilled } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ROLE_ADMIN,
  ROLE_CONTENT_CREATOR,
  ROLE_DESIGNER,
  ROLE_MANAGER,
} from "../../common/cms_const";
import { MSG_06 } from "../../common/cms_message";
import { buildSelectUserOptions, hasRole } from "../../common/common_utils";
import { IdeaModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  assignTask,
  getlistIdea,
  ideaSelector,
  setRequestStatus,
} from "../../store/slices/idea-slide";
import { getlistNiche, nicheSelector } from "../../store/slices/niche-slide";
import { getlistProductType, productTypeSelector } from "../../store/slices/productType-slide";
import { getListUsers, userSelector } from "../../store/slices/user-slide";

const { Option } = Select;

const ApprovedIdea: React.FC = () => {
  const dispatch = useDispatch();
  const { appLoading, userInfo } = useSelector(appSelector);
  const { listIdea, requestStatus } = useSelector(ideaSelector);
  const [formTitle, setFormTitle] = useState("Assign task");
  const { listNiche } = useSelector(nicheSelector);
  const { listProductType } = useSelector(productTypeSelector);
  const [showFilter, setShowFilter] = useState(false);
  const [designFilterOptions, setDesignFilterOptions] = useState<any>([]);
  const [contentFilterOptions, setContentFilterOptions] = useState<any>([]);
  const [nicheFilterOptions, setNicheFilterOptions] = useState<any>([]);
  const { listUsers } = useSelector(userSelector);
  const [filterDesignerVal, setFilterDesignerVal] = useState("");
  const [filterContentVal, setfilterContentVal] = useState("");
  const [filterNichVal, setfilterNichVal] = useState("");
  const [filterTypeVal, setfilterTypeVal] = useState("");
  const [listImages, setListImages] = useState<any>([]);
  const [listSample, setListSample] = useState<any>([]);

  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
    status: "",
    designer: "",
    content_creator: "",
    niche: "",
    type: "",
    approve_status: "1",
  });

  useEffect(() => {
    if (listNiche) {
      const optionsData: SelectProps["options"] = [];
      const optionsData2: SelectProps["options"] = [];

      optionsData2.push({
        label: "Chọn Niche",
        value: "",
      });

      for (let i = 0; i < listNiche.length; i++) {
        optionsData.push({
          label: listNiche[i].name,
          value: listNiche[i].id,
        });

        optionsData2.push({
          label: listNiche[i].name,
          value: listNiche[i].id,
        });
      }
      setNicheFilterOptions(optionsData2);
    }
  }, [listNiche]);

  useEffect(() => {
    if (listProductType) {
      const optionsData: SelectProps["options"] = [];
      for (let i = 0; i < listProductType.length; i++) {
        optionsData.push({
          label: listProductType[i].name,
          value: listProductType[i].id,
        });
      }
    }
  }, [listProductType]);

  useEffect(() => {
    dispatch(getlistIdea(requestParams));
    if (!listNiche || listNiche.length <= 0) {
      dispatch(getlistNiche());
    }
  }, []);

  useEffect(() => {
    if (requestStatus) {
      setVisible(false);
      dispatch(getlistIdea(requestParams));
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderDesignStatus = (row: any) => {
    if (!row.design_pic_name) {
      return "None";
    }

    return (
      <>
        <Row>
          <Col>
            <b>To:</b> {row.design_pic_name}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Deadline:</b> {moment(row.deadline_design).format("DD/MM/YYYY")}
          </Col>
        </Row>
      </>
    );
  };

  const renderImage = (record: any) => {
    if (record.images) {
      var listImages = record.images.split("|");
      return (
        <>
          {listImages.map((item: any) => {
            return (
              <img
                style={{ width: "50px", margin: "3px" }}
                src={process.env.REACT_APP_API_SERVER_URL + item}
              />
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  const renderSampleLink = (record: any) => {
    if (record.sample) {
      var separateLines = record.sample.split(/\r?\n|\r|\n/g);
      console.log(separateLines);
      let idx = 0;

      return (
        <>
          {separateLines.map((item: any) => {
            idx++;
            return (
              <>
                <a type="primary" target="_blank" href={item}>
                  Mẫu {idx}
                </a>{" "}
                <br />
              </>
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  const renderContentStatus = (row: any) => {
    if (!row.content_pic_name) {
      return "None";
    }

    return (
      <>
        <Row>
          <Col>
            <b>To:</b> {row.content_pic_name}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Deadline:</b> {moment(row.deadline_content).format("DD/MM/YYYY")}
          </Col>
        </Row>
      </>
    );
  };

  const renderCreatedDate = (record: any) => {
    return (
      <>
        <Row>
          <Col>{moment(record.created_date).format("DD/MM/YYYY")}</Col>
        </Row>
        <Row>
          <Col>By: {record.created_by_name}</Col>
        </Row>
      </>
    );
  };

  const onFinish = (data: any) => {
    console.log(data);

    data.deadline_design = moment(data.deadline_design).format("YYYY-MM-DD");
    data.deadline_content = moment(data.deadline_content).format("YYYY-MM-DD");
    dispatch(assignTask(data));
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    setIdeaInfo(data);
    console.log(data);
    if (!hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER)) {
      message.warning(MSG_06);
      return;
    }

    if (!listUsers || listUsers.length <= 0) {
      dispatch(getListUsers());
    }
    setVisible(true);
    loadDataDropdown();

    let listImg: any[] = [];
    if (data.images) {
      var listImages = data.images.split("|");
      setListImages(listImages);
      for (let index = 0; index < listImages.length; index++) {
        const element = listImages[index];
        const fileData = {
          status: "done",
          name: element,
          thumbUrl: process.env.REACT_APP_API_SERVER_URL + element,
          url: process.env.REACT_APP_API_SERVER_URL + element,
        };
        listImg.push(fileData);
      }
    }
    var separateLines = data.sample.split(/\r?\n|\r|\n/g);
    setListSample(separateLines);

    form.setFieldsValue({
      id: data.id,
      design_pic: data.design_pic_id,
      content_pic: data.content_pic_id,
      deadline_design: moment(data.deadline_design),
      deadline_content: moment(data.deadline_content),
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"],
    },
    {
      title: "Action",
      render: (_, record) =>
        hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER) && (
          <>
            <Button type="link" onClick={() => loadUpdate(record)}>
              Assign
            </Button>
          </>
        ),
    },
    {
      title: "Tiêu đề cũ",
      dataIndex: "old_title",
    },
    {
      title: "Tiêu đề mới",
      dataIndex: "new_title",
    },
    {
      title: "Sản phẩm mẫu",
      dataIndex: "sample",
      render: (_, record) => <>{renderSampleLink(record)}</>,
    },
    {
      title: "Ảnh mẫu",
      dataIndex: "images",
      render: (_, record) => <>{renderImage(record)}</>,
    },
    {
      title: "Design assigned",
      dataIndex: "design_status",
      render: (_, record) => <>{renderDesignStatus(record)}</>,
    },
    {
      title: "Content assigned",
      dataIndex: "content",
      render: (_, record) => <>{renderContentStatus(record)}</>,
    },
    {
      title: "Niche",
      dataIndex: "niche_name",
    },
    {
      title: "Copyright",
      dataIndex: "copyright",
    },
    {
      title: "Spy/New",
      dataIndex: "type",
    },
    {
      title: "Ngày tạo",
      render: (_, record) => <>{renderCreatedDate(record)}</>,
    },
  ];

  const onChange: TableProps<IdeaModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);

  const loadDataDropdown = () => {
    if (!listNiche || listNiche.length <= 0) {
      dispatch(getlistNiche());
    }
    if (!listProductType || listProductType.length <= 0) {
      dispatch(getlistProductType());
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (keyword: string) => {
    console.log("");
    const searchRequest = {
      ...requestParams,
      keyword: keyword,
    };
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onClearFilter = () => {
    const searchRequest = {
      ...requestParams,
      designer: "",
      content_creator: "",
      niche: "",
      type: "",
    };

    setRequestParams(searchRequest);
    setFilterDesignerVal("");
    setfilterContentVal("");
    setfilterNichVal("");
    setfilterTypeVal("");
    dispatch(getlistIdea(searchRequest));
  };

  const onShowFilter = () => {
    setShowFilter(!showFilter);
    if (!listUsers || listUsers.length <= 0) {
      dispatch(getListUsers());
    }
  };

  const onFilterDesignChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      designer: value,
    };
    setFilterDesignerVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterContentChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      content_creator: value,
    };
    setRequestParams(searchRequest);
    setfilterContentVal(value);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterNicheChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      niche: value,
    };
    setfilterNichVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterTypeChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      type: value,
    };
    setfilterTypeVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  useEffect(() => {
    if (listUsers) {
      const options = buildSelectUserOptions(listUsers, null, ROLE_DESIGNER);
      setDesignFilterOptions(options);

      // Content Creator
      const options_content = buildSelectUserOptions(listUsers, null, ROLE_CONTENT_CREATOR);
      setContentFilterOptions(options_content);
    }
  }, [listUsers]);

  const [ideaInfo, setIdeaInfo] = useState<any>();
  const renderSampleLink2 = () => {
    let idx = 0;
    return listSample.map((item: any) => {
      idx++;
      return (
        <>
          <a type="primary" target="_blank" href={item}>
            Mẫu {idx}
          </a>{" "}
          <br />
        </>
      );
    });
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Idea đã được duyệt</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal" style={{ float: "right", marginBottom: 16 }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
            <FilterFilled onClick={onShowFilter} />
          </Space>

          {showFilter && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Select
                  showSearch
                  defaultValue={""}
                  style={{ minWidth: 200 }}
                  value={filterDesignerVal}
                  onChange={onFilterDesignChange}
                  options={designFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  showSearch
                  defaultValue={""}
                  value={filterContentVal}
                  style={{ marginLeft: 15, minWidth: 200 }}
                  onChange={onFilterContentChange}
                  options={contentFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  showSearch
                  defaultValue={""}
                  value={filterNichVal}
                  placeholder="Niche"
                  style={{ marginLeft: 15, minWidth: 200 }}
                  onChange={onFilterNicheChange}
                  options={nicheFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Spy/New"
                  value={filterTypeVal}
                  defaultValue={""}
                  onChange={onFilterTypeChange}
                >
                  <Option value="">Spy/New</Option>
                  <Option value="spy">Spy</Option>
                  <Option value="new">New</Option>
                </Select>
                <Button type="link" onClick={onClearFilter}>
                  Xóa bộ lọc
                </Button>
              </Col>
            </Row>
          )}

          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listIdea}
            onChange={onChange}
            scroll={{ x: 2200 }}
          />

          <Drawer
            title={formTitle}
            width={1024}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Descriptions>
                <Descriptions.Item label="Tiêu đề cũ" span={3}>
                  {ideaInfo?.old_title}
                </Descriptions.Item>
                <Descriptions.Item label="Tiêu đề mới" span={3}>
                  {ideaInfo?.new_title}
                </Descriptions.Item>
                <Descriptions.Item label="Niche">{ideaInfo?.niche_name}</Descriptions.Item>
                <Descriptions.Item label="Spy/New">{ideaInfo?.type}</Descriptions.Item>
                <Descriptions.Item label="Copyright">{ideaInfo?.copyright}</Descriptions.Item>
              </Descriptions>

              <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Ảnh mẫu">
                      <Image.PreviewGroup>
                        {listImages.map((item: any) => {
                          return (
                            <Image width={80} src={process.env.REACT_APP_API_SERVER_URL + item} />
                          );
                        })}
                      </Image.PreviewGroup>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Sản phẩm mẫu">{renderSampleLink2()}</Form.Item>
                  </Col>
                </Row>
              </Form>
              <Divider />

              <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" readOnly />
                </Form.Item>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="design_pic" label="Assign designer">
                      <Select placeholder="Chọn degisner" options={designFilterOptions}></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="deadline_design" label="Deadline">
                      <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="content_pic" label="Assign content creator">
                      <Select
                        placeholder="Chọn content creator"
                        options={contentFilterOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="deadline_content" label="Deadline">
                      <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default ApprovedIdea;

import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import api from "../services/api";
import { appSelector } from "../store/slices/app-slice";

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  value: string;
  placeholder: string;
  onChange: OnChangeHandler;
};

const TextEditor: React.FC<Props> = ({ value, onChange, placeholder }) => {
  const { selectedStoreId, appLoading } = useSelector(appSelector);

  const uploadImage = async (blobInfo: any, progress: any) => {
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
      },
    };
    fmData.append("status", "undefined");
    fmData.append("file", blobInfo.blob());
    fmData.append("store", selectedStoreId);
    let url = "";
    try {
      const res = await api.post("/api/products/media/", fmData, config);
      url = res.data.data.source_url;
      console.log("server res: ", res);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
    } finally {
      return Promise.resolve(url);
    }
  };

  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
        textareaName="description"
        onEditorChange={onChange}
        value={value}
        init={{
          height: 400,
          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | image code" +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          file_picker_types: "image",
          images_upload_handler: uploadImage,
        }}
      />
    </>
  );
};

export default TextEditor;

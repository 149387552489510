import { PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableProps,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PER_ADD_STORE, PER_DELETE_STORE, PER_UPDATE_STORE } from "../../common/cms_const";
import { buildSelectUserOptions, formatDate, hasPermission } from "../../common/common_utils";
import { SettingStoreModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addStore,
  deleteStore,
  getListStores,
  setRequestStatus,
  storesSelector,
  updateStore,
} from "../../store/slices/setting-slide";
import { getListUsers, userSelector } from "../../store/slices/user-slide";

const SettingStore: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<SettingStoreModel>>({});
  const { listStores, requestStatus } = useSelector(storesSelector);
  const { appLoading, permissions } = useSelector(appSelector);
  const [isUpdate, setUpdate] = useState(false);
  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
  });
  const [userOptions, setUserOptions] = useState<any>([]);
  const { listUsers } = useSelector(userSelector);

  useEffect(() => {
    dispatch(getListStores(requestParams));
    dispatch(getListUsers());
  }, []);

  useEffect(() => {
    if (requestStatus) {
      dispatch(setRequestStatus(false));
      dispatch(getListStores(requestParams));
      setLoading(false);
      setVisible(false);
    }
  }, [requestStatus]);

  useEffect(() => {
    if (listUsers) {
      const options = buildSelectUserOptions(listUsers, null, "");
      setUserOptions(options);
    }
  }, [listUsers]);

  const handleDeleteStore = (id: any) => {
    dispatch(deleteStore({ id: id }));
    dispatch(setRequestStatus(false));
    dispatch(getListStores(requestParams));
  };

  const columns: ColumnsType<SettingStoreModel> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Tên cửa hàng",
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name.length - b.store_name.length,
      sortOrder: sortedInfo.columnKey === "store_name" ? sortedInfo.order : null,
    },
    {
      title: "URL",
      dataIndex: "store_url",
      key: "store_url",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.store_url.length - b.store_url.length,
      sortOrder: sortedInfo.columnKey === "store_url" ? sortedInfo.order : null,
    },
    {
      title: "Ngày thêm",
      dataIndex: "created_date",
      render: (_, record) => <>{formatDate(record.created_date)}</>,
    },
    {
      title: "Người tạo",
      dataIndex: "created_user",
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {hasPermission(permissions, PER_UPDATE_STORE) && (
            <a onClick={() => loadUpdate(record)}>Update</a>
          )}

          {hasPermission(permissions, PER_DELETE_STORE) && (
            <Popconfirm
              title="Are you sure to delete this store?"
              onConfirm={() => handleDeleteStore(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Delete</a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const onChange: TableProps<SettingStoreModel>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
    setSortedInfo(sorter as SorterResult<SettingStoreModel>);
  };

  const onSearch = (val: string) => {
    console.log(val);
    const searchRequest = {
      ...requestParams,
      keyword: val,
    };
    setRequestParams(searchRequest);
    dispatch(getListStores(searchRequest));
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = (store: any) => {
    setVisible(true);
    setUpdate(false);
    form.setFieldsValue({
      id: "",
      store_name: "",
      store_url: "",
      key: "",
      secret: "",
      timezone: "",
      note: "",
      category_base: "",
      permalink_base: "",
      managers: [],
    });
  };

  const [form] = Form.useForm();

  const loadUpdate = (store: any) => {
    setVisible(true);
    setUpdate(true);
    form.setFieldsValue({
      id: store.id,
      store_name: store.store_name,
      store_url: store.store_url,
      key: store.key,
      secret: store.secret,
      timezone: store.timezone,
      note: store.note,
      category_base: store.category_base,
      permalink_base: store.permalink_base,
      managers: store.managers,
    });
  };

  const onClose = () => {
    setLoading(false);
    setVisible(false);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    console.log("Success:", values);

    if (values.id) {
      dispatch(updateStore(values));
    } else {
      dispatch(addStore(values));
    }
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Setting store</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Spin spinning={appLoading}>
            {hasPermission(permissions, PER_ADD_STORE) && (
              <Button
                onClick={showDrawer}
                type="primary"
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
              >
                Thêm store
              </Button>
            )}

            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />

            <Table columns={columns} dataSource={listStores} onChange={onChange} />

            <Drawer
              title="Thêm mới cửa hàng"
              width={720}
              onClose={onClose}
              visible={visible}
              maskClosable={false}
              bodyStyle={{ paddingBottom: 80 }}
            >
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="store_name"
                      label="Tên cửa hàng"
                      rules={[{ required: true, message: "Tên cửa hàng là trường bắt buộc." }]}
                    >
                      <Input placeholder="tshirtlowprice.com" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="store_url"
                      label="Url"
                      rules={[{ required: true, message: "URL là trường bắt buộc." }]}
                    >
                      <Input placeholder="https://tshirtlowprice.com" style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>

                {!isUpdate && (
                  <>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          name="key"
                          label="Consumer key"
                          rules={[{ required: true, message: "Consumer key là trường bắt buộc." }]}
                        >
                          <Input.Password style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          name="secret"
                          label="Consumer secret"
                          rules={[
                            { required: true, message: "Consumer secret là trường bắt buộc." },
                          ]}
                        >
                          <Input.Password style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="timezone"
                      label="Timezone"
                      rules={[{ required: true, message: "Timezone là trường bắt buộc." }]}
                    >
                      <Input type={"number"} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="category_base" label="Category base">
                      <Input style={{ width: "100%" }} placeholder="product-category" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="managers" label="Assign users">
                      <Select mode="multiple" options={userOptions}></Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name="permalink_base" label="Permalink base">
                      <Input style={{ width: "100%" }} placeholder="product" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="note" label="Ghi chú">
                      <Input.TextArea value={""} placeholder={""}></Input.TextArea>
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Đăng ký
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Drawer>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default SettingStore;

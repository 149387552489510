import { Breadcrumb, Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { renderHTML } from "../../common/common_utils";
import { guidelineSelector, retrieveGuideline } from "../../store/slices/guideline-slide";

const GuideDetailComponent: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { guideline } = useSelector(guidelineSelector);

  useEffect(() => {
    const id = searchParams.get("id");
    dispatch(retrieveGuideline(id));
  }, []);

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/guideline">Guideline</Breadcrumb.Item>
        <Breadcrumb.Item>{guideline?.title}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Title level={2}>{guideline?.title}</Title>
        <Row gutter={16}>
          <Col span={24}>{renderHTML(guideline?.content)}</Col>
        </Row>
      </div>
    </>
  );
};

export default GuideDetailComponent;

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  FilterFilled,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Drawer,
  Form,
  Image,
  Input,
  Radio,
  RadioChangeEvent,
  Rate,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_ADMIN, ROLE_MANAGER } from "../../common/cms_const";
import { renderDeadline, renderFinishedDate } from "../../common/common_render";
import { hasRole, renderHTML } from "../../common/common_utils";
import TextEditor from "../../components/TextEditor";
import { IdeaModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  approveContent,
  getlistIdea,
  ideaSelector,
  setRequestStatus,
  updateContent,
} from "../../store/slices/idea-slide";
import { getlistNiche, nicheSelector } from "../../store/slices/niche-slide";
import { getlistProductType, productTypeSelector } from "../../store/slices/productType-slide";
import { getListUsers, userSelector } from "../../store/slices/user-slide";

const { Option } = Select;

const ContentManager: React.FC = () => {
  const dispatch = useDispatch();
  const { appLoading, userInfo } = useSelector(appSelector);
  const { listIdea, requestStatus, updateStatus } = useSelector(ideaSelector);
  const [formTitle, setFormTitle] = useState("");
  const { listNiche } = useSelector(nicheSelector);
  const { listProductType } = useSelector(productTypeSelector);
  const [showFilter, setShowFilter] = useState(false);
  const [nicheFilterOptions, setNicheFilterOptions] = useState<any>([]);
  const { listUsers } = useSelector(userSelector);
  const [filterNichVal, setfilterNichVal] = useState("");
  const [filterTypeVal, setfilterTypeVal] = useState("");
  const [filterStatusVal, setfilterStatusVal] = useState("");
  const [filterRatingVal, setfilterRatingVal] = useState("");
  const [ideaInfo, setIdeaInfo] = useState<any>();
  const [listImages, setListImages] = useState<any>([]);
  const [listSample, setListSample] = useState<any>([]);
  const [contentData, setContentData] = useState<any>("");
  const [rating, setRating] = useState();
  const [approveStatus, setApproveStatus] = useState("");

  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
    status: "",
    designer: "",
    content_creator: "",
    niche: "",
    type: "",
    requestType: "content",
    content_status: "",
    content_rating: "",
  });

  useEffect(() => {
    if (listNiche) {
      const optionsData: SelectProps["options"] = [];
      const optionsData2: SelectProps["options"] = [];

      optionsData2.push({
        label: "Chọn Niche",
        value: "",
      });

      for (let i = 0; i < listNiche.length; i++) {
        optionsData.push({
          label: listNiche[i].name,
          value: listNiche[i].id,
        });

        optionsData2.push({
          label: listNiche[i].name,
          value: listNiche[i].id,
        });
      }
      setNicheFilterOptions(optionsData2);
    }
  }, [listNiche]);

  useEffect(() => {
    dispatch(getlistIdea(requestParams));
    if (!listNiche || listNiche.length <= 0) {
      dispatch(getlistNiche());
    }
  }, []);

  useEffect(() => {
    if (requestStatus) {
      if (!updateStatus) {
        setVisible(false);
      }
      dispatch(getlistIdea(requestParams));
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderContentStatus = (row: any) => {
    let text = "";
    if (row.content_approve_status == 1) {
      text = "Duyệt";
    } else {
      text = "Chưa duyệt";
    }

    return (
      <>
        <Tag color={row.content_approve_status == 1 ? "green" : "volcano"}>
          {row.content_approve_status == 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
          {text}
        </Tag>
      </>
    );
  };

  const renderContentStatus2 = (row: any) => {
    let text = "";
    let contentStatus = false;
    if (row.new_content) {
      text = "Đã hoàn thành";
      contentStatus = true;
    } else {
      text = "Chưa hoàn thành";
    }

    return (
      <>
        <Tag color={contentStatus ? "green" : "volcano"}>
          {contentStatus ? <CheckCircleOutlined /> : <CloseCircleOutlined />} {text}
        </Tag>
      </>
    );
  };

  const renderImage = (record: any) => {
    if (record.images) {
      var listImages = record.images.split("|");
      return (
        <>
          {listImages.map((item: any) => {
            return (
              <img
                style={{ width: "50px", margin: "3px" }}
                src={process.env.REACT_APP_API_SERVER_URL + item}
              />
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  const renderSampleLink = (record: any) => {
    if (record.sample) {
      var separateLines = record.sample.split(/\r?\n|\r|\n/g);
      console.log(separateLines);
      let idx = 0;

      return (
        <>
          {separateLines.map((item: any) => {
            idx++;
            return (
              <>
                <a type="primary" target="_blank" href={item}>
                  Mẫu {idx}
                </a>{" "}
                <br />
              </>
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  const renderRating = (record: any) => {
    if (record.content_rating) {
      let txtColor = "";
      let txt = "";
      if (record.content_rating == 3) {
        txt = "Tốt";
        txtColor = "green";
      } else if (record.content_rating == 2) {
        txt = "Đạt yêu cầu";
        txtColor = "#dbd400";
      } else {
        txt = "Chưa đạt";
        txtColor = "red";
      }
      return (
        <>
          <span style={{ color: txtColor }}>{txt}</span>
        </>
      );
    } else {
      return "";
    }
  };

  const onFinish = (data: any) => {
    console.log(data);
    dispatch(updateContent(data));
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    setIdeaInfo(data);
    setVisible(true);
    setFormTitle("Update");
    loadDataDropdown();

    if (data.images) {
      var listImages = data.images.split("|");
      setListImages(listImages);
    }

    var separateLines = data.sample.split(/\r?\n|\r|\n/g);
    setListSample(separateLines);
    setContentData(data.new_content);
    setRating(data.content_rating);
    setApproveStatus(String(data.content_approve_status));

    form.setFieldsValue({
      content_id: data.content_id,
      content_description: data.new_content,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"],
    },
    {
      title: "Action",
      render: (_, record) => (
        <>
          <EditOutlined style={{ marginLeft: 10 }} onClick={() => loadUpdate(record)} />
        </>
      ),
    },
    {
      title: "Tiêu đề cũ",
      dataIndex: "old_title",
    },
    {
      title: "Tiêu đề mới",
      dataIndex: "new_title",
    },
    {
      title: "Sản phẩm mẫu",
      dataIndex: "sample",
      render: (_, record) => <>{renderSampleLink(record)}</>,
    },
    {
      title: "Ảnh mẫu",
      dataIndex: "images",
      render: (_, record) => <>{renderImage(record)}</>,
    },
    {
      title: "Tình trạng content",
      dataIndex: "design_status",
      render: (_, record) => <>{renderContentStatus2(record)}</>,
    },
    {
      title: "Trạng thái content",
      dataIndex: "design_status",
      render: (_, record) => <>{renderContentStatus(record)}</>,
    },
    {
      title: "Niche",
      dataIndex: "niche_name",
    },
    {
      title: "Copyright",
      dataIndex: "copyright",
    },
    {
      title: "Spy/New",
      dataIndex: "type",
    },
    {
      title: "Deadline",
      render: (_, record) => <>{renderDeadline(record.deadline_content, record.finished_date)}</>,
    },
    {
      title: "Ngày hoàn thành",
      render: (_, record) => (
        <>{renderFinishedDate(record.deadline_content, record.finished_date)}</>
      ),
    },
    {
      title: "Người thực hiện",
      dataIndex: "content_pic_name",
    },
    {
      title: "Đánh giá",
      render: (_, record) => <>{renderRating(record)}</>,
    },
  ];

  const onChange: TableProps<IdeaModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);

  const loadDataDropdown = () => {
    if (!listNiche || listNiche.length <= 0) {
      dispatch(getlistNiche());
    }
    if (!listProductType || listProductType.length <= 0) {
      dispatch(getlistProductType());
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (keyword: string) => {
    console.log("");
    const searchRequest = {
      ...requestParams,
      keyword: keyword,
    };
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onApproveStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    const data = {
      content_id: form.getFieldValue("content_id"),
      content_approve_status: value,
    };
    dispatch(approveContent(data));
  };

  const onClearFilter = () => {
    const searchRequest = {
      ...requestParams,
      designer: "",
      content_creator: "",
      niche: "",
      type: "",
      content_status: "",
      content_rating: "",
    };

    setRequestParams(searchRequest);
    setfilterNichVal("");
    setfilterTypeVal("");
    setfilterStatusVal("");
    setfilterRatingVal("");
    dispatch(getlistIdea(searchRequest));
  };

  const onShowFilter = () => {
    setShowFilter(!showFilter);
    if (!listUsers || listUsers.length <= 0) {
      dispatch(getListUsers());
    }
  };

  const onFilterStatusChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      content_status: value,
    };
    setfilterStatusVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterRatingChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      content_rating: value,
    };
    setRequestParams(searchRequest);
    setfilterRatingVal(value);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterNicheChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      niche: value,
    };
    setfilterNichVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const onFilterTypeChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      type: value,
    };
    setfilterTypeVal(value);
    setRequestParams(searchRequest);
    dispatch(getlistIdea(searchRequest));
  };

  const renderSampleLink2 = () => {
    let idx = 0;
    return listSample.map((item: any) => {
      idx++;
      return (
        <>
          <a type="primary" target="_blank" href={item}>
            Mẫu {idx}
          </a>{" "}
          <br />
        </>
      );
    });
  };

  const onRateChange = (val: any) => {
    console.log(val);
    const data = {
      content_id: form.getFieldValue("content_id"),
      rating: val,
    };
    dispatch(approveContent(data));
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Content</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal" style={{ float: "right", marginBottom: 16 }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
            <FilterFilled onClick={onShowFilter} />
          </Space>

          {showFilter && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Select
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Content status"
                  value={filterStatusVal}
                  defaultValue={""}
                  onChange={onFilterStatusChange}
                >
                  <Option value="">Content status</Option>
                  <Option value="0">Chưa hoàn thành</Option>
                  <Option value="1">Đã hoàn thành</Option>
                </Select>

                <Select
                  showSearch
                  defaultValue={""}
                  value={filterNichVal}
                  placeholder="Niche"
                  style={{ marginLeft: 15, minWidth: 200 }}
                  onChange={onFilterNicheChange}
                  options={nicheFilterOptions}
                  optionFilterProp={"label"}
                  filterOption={(input, option: any) => {
                    const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                    if (cOption) {
                      return cOption.toLowerCase().includes(input.toLowerCase());
                    } else {
                      return false;
                    }
                  }}
                ></Select>
                <Select
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Spy/New"
                  value={filterTypeVal}
                  defaultValue={""}
                  onChange={onFilterTypeChange}
                >
                  <Option value="">Spy/New</Option>
                  <Option value="spy">Spy</Option>
                  <Option value="new">New</Option>
                </Select>

                <Select
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Đánh giá"
                  value={filterRatingVal}
                  defaultValue={""}
                  onChange={onFilterRatingChange}
                >
                  <Option value="">Đánh giá</Option>
                  <Option value="1">Chưa đạt</Option>
                  <Option value="2">Đạt yêu cầu</Option>
                  <Option value="3">Tốt</Option>
                </Select>
                <Button type="link" onClick={onClearFilter}>
                  Xóa bộ lọc
                </Button>
              </Col>
            </Row>
          )}

          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listIdea}
            onChange={onChange}
            scroll={{ x: 2200 }}
          />

          <Drawer
            title={formTitle}
            width={1024}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Row>
                {hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER) && (
                  <>
                    <Col span={14}></Col>
                    <Col span={10}>
                      <Form.Item
                        label="Phê duyệt"
                        name="content_approve_status"
                        style={{ float: "right" }}
                      >
                        <Radio.Group onChange={onApproveStatusChange} value={approveStatus}>
                          <Radio.Button value="1">Duyệt</Radio.Button>
                          <Radio.Button value="0">Chưa duyệt</Radio.Button>
                        </Radio.Group>
                        <Rate
                          count={3}
                          style={{ marginLeft: 10 }}
                          onChange={onRateChange}
                          value={rating}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              <Descriptions bordered labelStyle={{ fontWeight: "600" }}>
                <Descriptions.Item label="Tiêu đề cũ" span={3}>
                  {ideaInfo?.old_title}
                </Descriptions.Item>
                <Descriptions.Item label="Tiêu đề mới" span={3}>
                  {ideaInfo?.new_title}
                </Descriptions.Item>
                <Descriptions.Item label="Niche">{ideaInfo?.niche_name}</Descriptions.Item>
                <Descriptions.Item label="Spy/New">{ideaInfo?.type}</Descriptions.Item>
                <Descriptions.Item label="Copyright">{ideaInfo?.copyright}</Descriptions.Item>
                <Descriptions.Item label="Ảnh mẫu" span={3}>
                  <Image.PreviewGroup>
                    {listImages.map((item: any) => {
                      return <Image width={80} src={process.env.REACT_APP_API_SERVER_URL + item} />;
                    })}
                  </Image.PreviewGroup>
                </Descriptions.Item>
                <Descriptions.Item label="Sản phẩm mẫu" span={3}>
                  {renderSampleLink2()}
                </Descriptions.Item>
              </Descriptions>
              {/* <Divider /> */}

              <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                <Form.Item hidden name="content_id">
                  <Input type="hidden" readOnly />
                </Form.Item>

                {userInfo?.id == ideaInfo?.content_pic_id && (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item name="content_description" label="Description">
                        <TextEditor value={""} placeholder={""} onChange={() => {}}></TextEditor>
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER) && (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item label="Description">
                        <span>{renderHTML(contentData)}</span>
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      {userInfo?.id == ideaInfo?.content_pic_id && (
                        <>
                          <Button htmlType="submit" type="primary">
                            Submit
                          </Button>
                          <Button onClick={onClose}>Cancel</Button>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default ContentManager;

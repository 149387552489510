import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '..'
export interface IApp {
  appLoading?: boolean;
  selectedStoreURL?: string;
  selectedStoreId: string;
  userInfo: any;
  permissions: string[]
  permalinkBase: string;
  dropboxStatus: string;
}

const initialState: IApp = {
  appLoading: false,
  selectedStoreURL: '',
  selectedStoreId: '',
  userInfo: {},
  permissions: [],
  permalinkBase: "",
  dropboxStatus: ""
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload
    },
    setSelectedStore: (state, action: PayloadAction<string>) => {
      state.selectedStoreURL = action.payload
    },
    setSelectedStoreID: (state, action: PayloadAction<string>) => {
      state.selectedStoreId = action.payload
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload
    },
    setPermissions: (state, action: PayloadAction<any>) => {
      state.permissions = action.payload
    },
    setPermalinkBase: (state, action: PayloadAction<any>) => {
      state.permalinkBase = action.payload
    },
    setDropboxStatus: (state, action: PayloadAction<any>) => {
      state.dropboxStatus = action.payload
    },
  },
})

export const {
  setLoading,
  setPermissions,
  setSelectedStore,
  setSelectedStoreID,
  setUserInfo,
  setPermalinkBase,
  setDropboxStatus
} = appSlice.actions

export const appSelector = (state: RootState): IApp => state.app

export default appSlice.reducer

// =========================
/// Dispatch Actions
// =========================
export const updateSelectedStore = (storeUrl: string, storeId: string): AppThunk => async dispatch => {
  dispatch(setSelectedStore(storeUrl))
  dispatch(setSelectedStoreID(storeId))
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { IProductTypeResponse, ProductTypeModel } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IProductType {
  requestStatus: boolean,
  listProductType: ProductTypeModel[],
}

const initialState: IProductType = {
  requestStatus: false,
  listProductType: [],
};

export const userSlice = createSlice({
  name: "productType",
  initialState,
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setlistProductType: (state, action: PayloadAction<ProductTypeModel[]>) => {
      state.listProductType = action.payload
    },
  }
});

export const productTypeSelector = (state: RootState): IProductType => state.productType

// =========================
/// Actions
// =========================
export const {
  setlistProductType,
  setRequestStatus
} = userSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getlistProductType = (data: any = {}): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.get<IProductTypeResponse>(`/api/product-type`, { params: data });
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setlistProductType(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}


export const addProductType = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IProductTypeResponse>(`/api/product-type/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["product type"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateProductType = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.patch<IProductTypeResponse>(`/api/product-type/${data.id}/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["product type"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteProductType = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.post<IProductTypeResponse>(`/api/product-type/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["product type"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export default userSlice.reducer;

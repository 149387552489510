import { Breadcrumb, Button, Form, Input, message, Result, Space } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { appSelector } from "../../store/slices/app-slice";

const SettingDropbox: React.FC = () => {
  const [step, setStep] = useState<any>(1);
  const { dropboxStatus } = useSelector(appSelector);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onClick = async () => {
    const res = await api.get<any>(`/api/dropbox`);
    console.log(res.data.data);
    openInNewTab(res.data.data);
    setStep(2);
  };

  const onFinish = async (data: any) => {
    const res = await api.post<any>(`/api/dropbox/`, data);
    if (res.data.result == "ok") {
      console.log(res.data.result);
      setStep(3);
    } else {
      message.error(res.data.message);
    }
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Bill</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        {dropboxStatus == "OK" && <Result status="success" title="Setting dropbox successful" />}

        {dropboxStatus != "OK" && (
          <>
            {step == 1 && (
              <Button type="primary" onClick={onClick}>
                Login dropbox
              </Button>
            )}

            {step == 2 && (
              <Form layout="vertical" onFinish={onFinish} autoComplete="off">
                <Form.Item name="auth_code" label="Dropbox auth code" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            )}

            {step == 3 && <Result status="success" title="Setting dropbox successful" />}
          </>
        )}
      </div>
    </>
  );
};

export default SettingDropbox;

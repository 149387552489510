import { ExportOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Pagination,
  PaginationProps,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
} from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import Paragraph from "antd/lib/typography/Paragraph";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderHTML } from "../../common/common_utils";
import { OrderModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import { fullfillSelector, getListFullfill } from "../../store/slices/fullfill-slide";
import {
  exportOrder,
  getListOrders,
  orderSelector,
  setRequestStatus,
  updateOrder,
} from "../../store/slices/order-slide";

const { Option } = Select;
const { RangePicker } = DatePicker;

export interface RecordType {
  key: string;
  title: string;
  description: string;
}

const OrderComponent: React.FC = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<OrderModel>>({});
  const dispatch = useDispatch();
  const { listOrders, totalRecords, requestStatus } = useSelector(orderSelector);
  const { listFullfill } = useSelector(fullfillSelector);
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const { selectedStoreId } = useSelector(appSelector);
  const [selectedFullfill, setSelectedFullfill] = useState({ value: "", label: "" });
  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
    status: "1",
    storeId: "",
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "createTime",
    created_date: "",
    sortOrder: "DESC",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [orderInfo, setOrderInfo] = useState<OrderModel>();

  useEffect(() => {
    getFullfillAndOrders();
  }, []);

  useEffect(() => {
    getFullfillAndOrders();
  }, [selectedStoreId]);

  useEffect(() => {
    if (selectedStoreId && requestStatus) {
      setVisible(false);
      dispatch(getListOrders(requestParams));
      dispatch(setRequestStatus(false));
      setSelectedRowKeys([]);
    }
  }, [requestStatus]);

  const getFullfillAndOrders = () => {
    if (!listFullfill || listFullfill.length <= 0) {
      dispatch(getListFullfill());
    }
    if (selectedStoreId) {
      const searchRequest = {
        ...requestParams,
        storeId: selectedStoreId,
      };
      setRequestParams(searchRequest);
      dispatch(getListOrders(searchRequest));
    }
  };

  useEffect(() => {
    if (listFullfill && listFullfill.length > 0) {
      const optionsData: SelectProps["options"] = [];
      for (let i = 0; i < listFullfill.length; i++) {
        optionsData.push({
          label: listFullfill[i].full_fill_name,
          value: listFullfill[i].id,
        });
      }
      setOptions(optionsData);
    }
  }, [listFullfill]);

  const buildProductList = (values: any[]) => {
    let data = "";
    for (let i = 0; i < values.length; i++) {
      data += "- " + values[i].name + "<br/>";
      data += "<b>Sku:</b> " + values[i].sku + "<br/>";
    }
    return data;
  };

  const renderStatusOrder = (row: OrderModel) => {
    let bg_color = "";
    let text_color = "";
    if (row.status == "processing") {
      bg_color = "#c6e1c6";
      text_color = "#5b841b";
    } else if (row.status == "pending") {
      bg_color = "#e5e5e5";
      text_color = "#777";
    } else if (row.status == "completed") {
      bg_color = "#c8d7e1";
      text_color = "#2e4453";
    } else if (row.status == "failed") {
      bg_color = "#eba3a3";
      text_color = "#761919";
    }
    return (
      <Tag color={bg_color} key={row.status}>
        <div style={{ color: text_color }}>{row.status.toUpperCase()}</div>
      </Tag>
    );
  };

  const renderStatus = (row: OrderModel) => {
    let status = row.status2;
    if (!row.status2 && row.status == "processing") {
      status = "New Order";
    }

    if (!status) {
      return "";
    }

    let bg_color = "";
    let text_color = "";
    if (status == "New Order") {
      bg_color = "volcano";
      text_color = "";
    } else if (status == "Fulfilled") {
      bg_color = "#fcfc97";
      text_color = "#ebd40c";
    } else if (status == "Shipped") {
      bg_color = "green";
      text_color = "";
    }
    return (
      <Tag color={bg_color} key={status}>
        <div style={{ color: text_color }}>{status.toUpperCase()}</div>
      </Tag>
    );
  };

  const columns: ColumnsType<OrderModel> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Customer name",
      dataIndex: "note",
      render: (_, record) => (
        <a onClick={() => loadUpdate(record)}>
          {record.billing.first_name + " " + record.billing.last_name}
        </a>
      ),
    },
    {
      title: "Created date",
      dataIndex: "date_created",
      key: "date_created",
      sortOrder: sortedInfo.columnKey === "date_created" ? sortedInfo.order : null,
      render: (_, record) => <>{moment(record.date_created).format("DD/MM/YYYY hh:mm:ss")}</>,
    },
    {
      title: "Product",
      width: 500,
      dataIndex: "note",
      render: (_, record) => <span>{renderHTML(buildProductList(record.line_items))}</span>,
    },
    {
      title: "Total ($)",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Order Status",
      dataIndex: "status",
      render: (_, record) => <>{renderStatusOrder(record)}</>,
    },
    {
      title: "Status",
      dataIndex: "note",
      render: (_, record) => <>{renderStatus(record)}</>,
    },
    {
      title: "Fulfillment",
      dataIndex: "full_fill",
    },
    {
      title: "Mã in",
      dataIndex: "note",
      render: (_, record) => (
        <>{record.print_code && <Paragraph copyable>{record.print_code}</Paragraph>}</>
      ),
    },
    {
      title: "Note",
      dataIndex: "note",
    },
  ];

  const onChange: TableProps<OrderModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setSortedInfo(sorter as SorterResult<OrderModel>);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onFullfillChange = (value: { value: string; label: string }) => {
    if (value) {
      console.log(value);
      setSelectedFullfill(value);
    }
  };

  const onExportOrder = () => {
    if (!selectedFullfill.value) {
      message.warning("Chọn 1 fullfill.");
      return;
    }

    console.log(exportDate);
    const req = {
      list_order_id: selectedRowKeys,
      store_id: selectedStoreId,
      template_id: selectedFullfill.value,
      fullfill_name: selectedFullfill.label,
      created_date: exportDate,
      status: "processing",
      from_date: exportDate ? exportDate[0] : "",
      to_date: exportDate ? exportDate[1] : "",
    };
    dispatch(exportOrder(req));
  };

  const handlePageChange: PaginationProps["onChange"] = (pageNumber, pageSize) => {
    console.log("Page: ", pageNumber);
    const searchRequest = {
      ...requestParams,
      pageIndex: pageNumber == 0 ? 1 : pageNumber,
      storeId: selectedStoreId,
      pageSize: pageSize,
    };
    setRequestParams(searchRequest);
    dispatch(getListOrders(searchRequest));
    setCurrentPage(pageNumber);
  };

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { appLoading } = useSelector(appSelector);

  const loadUpdate = (order: OrderModel) => {
    setVisible(true);
    console.log(order);

    let ids = [];
    if (order.full_fill) {
      const fullFills = order.full_fill.split(",");
      for (let i = 0; i < listFullfill.length; i++) {
        for (let j = 0; j < fullFills.length; j++) {
          if (listFullfill[i].full_fill_name == fullFills[j].trim()) {
            ids.push(listFullfill[i].id);
            break;
          }
        }
      }
    }

    let tracking = "";

    for (let index = 0; index < order.line_items.length; index++) {
      const element1 = order.line_items[index];
      for (let index = 0; index < element1.meta_data.length; index++) {
        const element = element1.meta_data[index];
        if (element.key == "_vi_wot_order_item_tracking_data") {
          const obj = JSON.parse(element.value);
          console.log(obj[0].tracking_number);
          tracking += obj[0].tracking_number + ", ";
          break;
        }
      }
    }

    const or2 = { ...order };

    or2.tracking_number = tracking.substring(0, tracking.length - 2);
    setOrderInfo(or2);

    form.setFieldsValue({
      id: order.id,
      order_id: order.id,
      customer_name: order.billing.first_name + " " + order.billing.last_name,
      created_date: order.date_created,
      note: order.note,
      print_code: order.print_code,
      full_fill: ids,
      full_fill_fee: order.full_fill_fee,
      design_fee: order.design_fee,
      status2: order.status2 ? order.status2 : "New Order",
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = (formData: any) => {
    formData.store_id = selectedStoreId;
    dispatch(updateOrder(formData));
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [exportDate, setExportDate] = useState<any>();

  const onChangeDate: RangePickerProps["onChange"] = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      console.log("Clear");
    }
    setExportDate(dateStrings);
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Export order</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360, paddingBottom: 80 }}
      >
        <div>
          <Modal
            title="Cài đặt export"
            visible={isModalVisible}
            onOk={onExportOrder}
            okText="Export"
            onCancel={() => setIsModalVisible(false)}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item required name="ownerx" label="Order từ ngày">
                  <RangePicker style={{ width: "100%" }} showTime format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item required name="xx" label="Status">
                  <Select defaultValue={""}>
                    <Option value="">New Order</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Modal>

          <Space direction="horizontal">
            <RangePicker
              style={{ marginBottom: 16, width: 300 }}
              // placeholder="Order by date"
              format="DD/MM/YYYY"
              onChange={onChangeDate}
            />
            <Select
              labelInValue
              placeholder="Chọn Fullfill"
              style={{ marginBottom: 16, width: 200 }}
              onChange={onFullfillChange}
              options={options}
            ></Select>
            <Button
              type="primary"
              onClick={onExportOrder}
              style={{ marginBottom: 16 }}
              icon={<ExportOutlined />}
            >
              Export to CSV
            </Button>
          </Space>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listOrders}
            onChange={onChange}
            pagination={false}
          />
          <Pagination
            style={{ marginTop: 15, marginBottom: 20, float: "right" }}
            showSizeChanger={true}
            showTotal={(total) => `Total ${totalRecords} items`}
            defaultCurrent={1}
            total={totalRecords}
            onChange={handlePageChange}
            current={currentPage}
          />
          <Drawer
            title="Update order"
            width={900}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Descriptions title="Thông tin đơn hàng">
                <Descriptions.Item label="ID">{orderInfo?.id}</Descriptions.Item>
                <Descriptions.Item label="Customer">
                  {orderInfo?.billing.first_name + " " + orderInfo?.billing.last_name}
                </Descriptions.Item>
                <Descriptions.Item label="Status">{orderInfo?.status}</Descriptions.Item>
                <Descriptions.Item label="Ngày đặt hàng">
                  {moment(orderInfo?.date_created).format("DD/MM/YYYY hh:mm:ss")}
                </Descriptions.Item>
                <Descriptions.Item label="Ngày update">
                  {moment(orderInfo?.date_modified).format("DD/MM/YYYY hh:mm:ss")}
                </Descriptions.Item>
                <Descriptions.Item label="Tiền đặt hàng">{orderInfo?.total}</Descriptions.Item>
                <Descriptions.Item label="Đơn vị in">{orderInfo?.full_fill}</Descriptions.Item>
                <Descriptions.Item label="Mã in">
                  {orderInfo?.print_code && <Paragraph copyable>{orderInfo?.print_code}</Paragraph>}
                </Descriptions.Item>
                <Descriptions.Item label="Mã tracking">
                  {orderInfo?.tracking_number}
                </Descriptions.Item>
              </Descriptions>

              <Divider />

              {/* <Title level={5}>Cập nhật</Title> */}
              <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                <Form.Item hidden name="order_id">
                  <Input type="hidden" readOnly />
                </Form.Item>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item name="full_fill" label="Fullfill">
                      <Select options={options} mode="multiple"></Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="print_code" label="Mã in">
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="status2" label="Status">
                      <Select>
                        <Option value="New Order">New Order</Option>
                        <Option value="Fulfilled">Fulfilled</Option>
                        <Option value="Shipped">Shipped</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="full_fill_fee" label="Phí Fullfill">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="design_fee" label="Phí design">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="note" label="Ghi chú">
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default OrderComponent;

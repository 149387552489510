import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { GroupModel, IGroupResponse, IPermissionResponse, IUserResponse, PermissionModel } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IGroup {
  requestStatus: boolean,
  listGroups: GroupModel[],
  listPermissions: PermissionModel[]
}

// Khởi tạo state cho slice
const initialState: IGroup = {
  requestStatus: false,
  listGroups: [],
  listPermissions: []

};

// Cấu hình slice
export const groupSlice = createSlice({
  name: "group",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListGroups: (state, action: PayloadAction<GroupModel[]>) => {
      state.listGroups = action.payload
    },
    setListPermission: (state, action: PayloadAction<any[]>) => {
      state.listPermissions = action.payload
    },
  }
});


export const groupSelector = (state: RootState): IGroup => state.group

// =========================
/// Actions
// =========================
export const {
  setListGroups,
  setListPermission,
  setRequestStatus
} = groupSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListGroups = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<IGroupResponse>(`/api/groups/`);
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setListGroups(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const addGroup = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IUserResponse>(`/api/groups/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["group"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateGroup = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.patch<IUserResponse>(`/api/groups/${data.id}/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["group"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteGroup = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IUserResponse>(`/api/groups/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["group"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const getListPermission = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<IPermissionResponse>(`/api/permission/`);
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    console.log(response);
    dispatch(setListPermission(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default groupSlice.reducer;

import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PER_ADD_FULLFILL, PER_DELETE_FULLFILL, PER_UPDATE_FULLFILL } from "../../common/cms_const";
import { hasPermission, renderHTML } from "../../common/common_utils";
import { mockData } from "../../data/master-data";
import { FullFillModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addFullfill,
  deleteFullfill,
  fullfillSelector,
  getListFullfill,
  updateFullfill,
} from "../../store/slices/fullfill-slide";
import { setRequestStatus } from "../../store/slices/user-slide";

export interface RecordType {
  key: string;
  title: string;
  description: string;
}

const getFullfillOptions = () => {
  const optionsData: SelectProps["options"] = [];
  for (let i = 0; i < mockData.length; i++) {
    optionsData.push({
      label: mockData[i].title,
      value: mockData[i].key,
    });
  }
  return optionsData;
};

const FullFillComponent: React.FC = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<FullFillModel>>({});
  const dispatch = useDispatch();
  const { listFullfill, requestStatus } = useSelector(fullfillSelector);
  const { appLoading, permissions } = useSelector(appSelector);
  const [fullfillOptions, setFullfillOptions] = useState(getFullfillOptions());

  useEffect(() => {
    dispatch(getListFullfill());
  }, []);

  useEffect(() => {
    if (requestStatus) {
      dispatch(getListFullfill());
      setVisible(false);
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const onFinish = (fullfill: FullFillModel) => {
    console.log(fullfill);

    let col_data = "";
    let col_data_txt = "";
    for (let i = 0; i < fullfill.templateItems.length; i++) {
      if (i == fullfill.templateItems.length - 1) {
        col_data += fullfill.templateItems[i];
      } else {
        col_data += fullfill.templateItems[i] + "|";
      }
    }

    const keys = col_data.split("|");
    for (let i = 0; i < keys.length; i++) {
      for (let j = 0; j < mockData.length; j++) {
        if (mockData[j].key == keys[i]) {
          col_data_txt += mockData[j].title + "|";
        }
      }
    }

    console.log(col_data);
    console.log(col_data_txt.substring(0, col_data_txt.length - 1));

    fullfill.template_col = col_data;
    fullfill.template_col_text = col_data_txt.substring(0, col_data_txt.length - 1);

    if (fullfill.id) {
      dispatch(updateFullfill(fullfill));
    } else {
      dispatch(addFullfill(fullfill));
    }
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    if (!hasPermission(permissions, PER_UPDATE_FULLFILL)) {
      return;
    }
    setVisible(true);
    const targets = data.template_col.split("|");
    form.setFieldsValue({
      id: data.id,
      full_fill_name: data.full_fill_name,
      template_col_text: data.template_col_text,
      email_support: data.email_support,
      note: data.note,
      templateItems: targets,
    });
  };

  const columns: ColumnsType<FullFillModel> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "full_fill_name",
      key: "full_fill_name",

      sortOrder: sortedInfo.columnKey === "full_fill_name" ? sortedInfo.order : null,
      render: (_, record) => (
        <>
          <a onClick={() => loadUpdate(record)}>{record.full_fill_name}</a>
        </>
      ),
    },
    {
      title: "Format",
      dataIndex: "template_col_text",
      key: "template_col_text",
      defaultSortOrder: "descend",
      width: 600,
      ellipsis: true,
    },
    {
      title: "Email support",
      dataIndex: "email_support",
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      render: (_, record) => <span>{renderHTML(record.note ? record.note : "")}</span>,
    },
  ];

  const onChange: TableProps<FullFillModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setSortedInfo(sorter as SorterResult<FullFillModel>);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
    form.setFieldsValue({
      id: "",
      full_fill_name: "",
      template_col_text: "",
      email_support: "",
      note: "",
      templateItems: [],
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Tranfer:
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một fullfill.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      //   content: 'Some descriptions',
      onOk() {
        console.log("OK");
        dispatch(deleteFullfill(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Quản lý Fullfill</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            {hasPermission(permissions, PER_ADD_FULLFILL) && (
              <Button
                onClick={showDrawer}
                type="primary"
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
              >
                Thêm template fullfill
              </Button>
            )}

            {hasPermission(permissions, PER_DELETE_FULLFILL) && (
              <Button
                type="primary"
                onClick={showConfirm}
                style={{ marginBottom: 16 }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            )}
          </Space>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listFullfill}
            onChange={onChange}
          />

          <Drawer
            title="Thêm mới Fullfill"
            width={1024}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="full_fill_name"
                      label="Tên nhà fullfill"
                      rules={[{ required: true, message: "Tên fullfill là thông tin bắt buộc." }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email_support"
                      label="Email"
                      rules={[
                        { required: true, message: "Email là thông tin bắt buộc." },
                        { type: "email", message: "Email không hợp lệ." },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="templateItems" label="Chọn items">
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        options={fullfillOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="note" label="Ghi chú">
                      <Input.TextArea rows={4} placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default FullFillComponent;

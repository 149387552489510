import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Switch,
  Table,
  TableProps,
  message,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_ADMIN } from "../../common/cms_const";
import { hasRole, renderHTML } from "../../common/common_utils";
import SeachableCategory from "../../components/SeachableCategory";
import TextEditor from "../../components/TextEditor";
import { DescriptionModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addDesciption,
  deleteDesciption,
  descriptionSelector,
  getListDesciptions,
  setRequestStatus,
  updateDesciption,
  updateDesciptionStatus,
} from "../../store/slices/description-slide";
import { getlistProductType, productTypeSelector } from "../../store/slices/productType-slide";

const { Option } = Select;

const DescriptionComponent: React.FC = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DescriptionModel>>({});
  const dispatch = useDispatch();
  const { listDescriptions, requestStatus } = useSelector(descriptionSelector);
  const { appLoading } = useSelector(appSelector);
  const [productTypeOptions, setProductTypeOptions] = useState<SelectProps["options"]>([]);
  const [selectedCategoties, setSelectedCategoties] = useState<any[]>([]);
  const { listProductType } = useSelector(productTypeSelector);
  const { selectedStoreId, userInfo } = useSelector(appSelector);
  const [showFilter, setShowFilter] = useState(false);
  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
    productType: "",
    content_type: "",
    categories: "",
    storeId: null,
  });

  useEffect(() => {
    if (!selectedStoreId) {
      message.warning("Chọn 1 store.");
      return;
    }
    let reqData = { ...requestParams, storeId: selectedStoreId };
    dispatch(getListDesciptions(reqData));
  }, [selectedStoreId]);

  useEffect(() => {
    if (requestStatus) {
      if (!selectedStoreId) {
        message.warning("Chọn 1 store.");
        return;
      }
      let reqData = { ...requestParams, storeId: selectedStoreId };
      dispatch(getListDesciptions(reqData));
      setVisible(false);
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  useEffect(() => {
    if (listProductType) {
      dispatch(getlistProductType());
    }
  }, []);

  useEffect(() => {
    if (listProductType) {
      const optionsData: SelectProps["options"] = listProductType.map(pt => ({
        label: pt.name,
        value: pt.id,
      }));
      setProductTypeOptions(optionsData);
    }
  }, [listProductType]);

  const onFinish = (data: any) => {
    if (!selectedStoreId) {
      message.warning("Chọn 1 store.");
      return;
    }
    data.store = selectedStoreId;
    if (data.category && data.category.length > 0) {
      data.category = data.category[0].label;
    }
    if (data.id) {
      dispatch(updateDesciption(data));
    } else {
      dispatch(addDesciption(data));
    }
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    setVisible(true);
    console.log(data);
    form.setFieldsValue({
      id: data.id,
      title: data.title,
      content: data.content,
      category: data.category,
      product_type: data.product_type_id,
      content_type: String(data.content_type),
    });
  };

  const renderContentType = (code: any) => {
    switch (code) {
      case 1:
        return "Mô tả niche";
      case 2:
        return "Mô tả sản phẩm";
      case 3:
        return "Content trust site";
      case 4:
        return "Video";
      default:
        return "Hình ảnh";
    }
  };

  const handleChangeStatus = (item_id: any, checked: boolean) => {
    const data = { id: item_id, status: checked };
    dispatch(updateDesciptionStatus(data));
  };

  const renderStatus = (row: any) => {
    const isChecked = row.status === 1;
    const isDisabled = !hasRole(userInfo?.groups, ROLE_ADMIN);
    return (
      <Switch
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        onChange={(checked) => handleChangeStatus(row.id, checked)}
        defaultChecked={isChecked}
        disabled={isDisabled}
      />
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      width: 90,
      dataIndex: "id",
    },
    {
      title: "Template name",
      defaultSortOrder: "descend",
      width: 400,
      sorter: (a, b) => a.title.length - b.title.length,
      sortOrder: sortedInfo.columnKey === "username" ? sortedInfo.order : null,
      render: (_, record) => (
        <>
          <a onClick={() => loadUpdate(record)}>{record.title}</a>
        </>
      ),
    },
    {
      title: "Content",
      render: (_, record) => (
        <>
          <Popover
            content={renderHTML(record.content)}
            overlayStyle={{
              width: "30vw",
            }}
            title="Description"
          >
            <a type="primary">View detail</a>
          </Popover>
        </>
      ),
    },
    {
      title: "Store",
      dataIndex: "store_name",
    },
    {
      title: "Product type",
      dataIndex: "product_type_name",
    },
    {
      title: "Type",
      dataIndex: "content_type",
      render: (_, record) => <>{renderContentType(record.content_type)}</>,
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Trạng thái",
      render: (_, record) => <>{renderStatus(record)}</>,
    },
    {
      title: "Ngày tạo",
      dataIndex: "store",
      render: (_, record) => <>{moment(record.created_date).format("DD/MM/YYYY")}</>,
    },
  ];

  const onChange: TableProps<DescriptionModel>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
    setSortedInfo(sorter as SorterResult<DescriptionModel>);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
    form.setFieldsValue({
      id: "",
      title: "",
      content: "",
      category: "",
      product_type: "",
      content_type: "",
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangeQuill = (html: any) => {};

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một item.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        dispatch(deleteDesciption(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onSearch = (keyword: string) => {
    if (!selectedStoreId) {
      message.warning("Chọn 1 store.");
      return;
    }
    const searchRequest = {
      keyword: keyword,
      storeId: selectedStoreId,
    };
    dispatch(getListDesciptions(searchRequest));
  };

  const onShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [contentTypeFilter, setContentTypeFilter] = useState("");
  const [filterCategories, setFilterCategories] = useState<any[]>([]);

  const onFilterProductTypeChange = (value: string) => {
    if (!selectedStoreId) {
      message.warning("Chọn 1 store.");
      return;
    }
    const searchRequest = {
      ...requestParams,
      productType: value,
      storeId: selectedStoreId,
    };
    setProductTypeFilter(value);
    setRequestParams(searchRequest);
    dispatch(getListDesciptions(searchRequest));
  };

  const onFilterContentTypeChange = (value: string) => {
    if (!selectedStoreId) {
      message.warning("Chọn 1 store.");
      return;
    }
    const searchRequest = {
      ...requestParams,
      content_type: value,
      storeId: selectedStoreId,
    };
    setContentTypeFilter(value);
    setRequestParams(searchRequest);
    dispatch(getListDesciptions(searchRequest));
  };

  const onFilterCategoryChange = (value: any) => {
    if (!selectedStoreId) {
      message.warning("Chọn 1 store.");
      return;
    }
    let arrCategories = value.map((item: any) => item.label).join("|");
    const searchRequest = {
      ...requestParams,
      categories: arrCategories,
      storeId: selectedStoreId,
    };
    setFilterCategories(value);
    setRequestParams(searchRequest);
    dispatch(getListDesciptions(searchRequest));
  };

  const onClearFilter = () => {
    if (!selectedStoreId) {
      message.warning("Chọn 1 store.");
      return;
    }
    const searchRequest = {
      ...requestParams,
      keyword: "",
      productType: "",
      content_type: "",
      categories: "",
      storeId: selectedStoreId,
    };
    setFilterCategories([]);
    setContentTypeFilter("");
    setProductTypeFilter("");
    setRequestParams(searchRequest);
    dispatch(getListDesciptions(searchRequest));
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Danh sách mô tả sản phẩm</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            <Button
              onClick={showDrawer}
              type="primary"
              style={{ marginBottom: 16 }}
              icon={<PlusOutlined />}
            >
              Thêm description
            </Button>

            <Button
              type="primary"
              onClick={showConfirm}
              style={{ marginBottom: 16 }}
              icon={<DeleteOutlined />}
            >
              Xóa
            </Button>
          </Space>
          <Space direction="horizontal" style={{ float: "right" }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
            <FilterFilled onClick={onShowFilter} />
          </Space>
          {showFilter && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <SeachableCategory
                  customStyle={{ minWidth: 200 }}
                  optionsVal={filterCategories}
                  onChange={onFilterCategoryChange}
                />

                <Select
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Chọn loại sản phẩm"
                  onChange={onFilterProductTypeChange}
                  value={productTypeFilter}
                  options={productTypeOptions}
                ></Select>

                <Select
                  placeholder="Chọn loại nội dung"
                  style={{ marginLeft: 15, minWidth: 150 }}
                  onChange={onFilterContentTypeChange}
                  value={contentTypeFilter}
                >
                  <Option value="1">Mô tả niche</Option>
                  <Option value="2">Mô tả sản phẩm</Option>
                  <Option value="3">Content trust site</Option>
                  <Option value="4">Video</Option>
                  <Option value="5">Hình ảnh</Option>
                </Select>
                <Button type="link" onClick={onClearFilter}>
                  Xóa bộ lọc
                </Button>
              </Col>
            </Row>
          )}
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listDescriptions}
            onChange={onChange}
          />

          <Drawer
            title="Thêm mới description"
            width={1024}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="title"
                      label="Template name"
                      rules={[{ required: true, message: "Tên template là thông tin bắt buộc." }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="category" label="Categories">
                      <SeachableCategory
                        optionsVal={selectedCategoties}
                        onChange={(val) => {
                          setSelectedCategoties(val);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="product_type"
                      label="Loại sản phẩm"
                      rules={[{ required: true, message: "Loại sản phẩm là thông tin bắt buộc." }]}
                    >
                      <Select
                        placeholder="Chọn loại sản phẩm"
                        options={productTypeOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="content_type"
                      label="Loại nội dung"
                      rules={[{ required: true, message: "Loại nội dung là thông tin bắt buộc." }]}
                    >
                      <Select placeholder="Chọn loại nội dung">
                        <Option value="1">Mô tả niche</Option>
                        <Option value="2">Mô tả sản phẩm</Option>
                        <Option value="3">Content trust site</Option>
                        <Option value="4">Video</Option>
                        <Option value="5">Hình ảnh</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="content" label="Description">
                      <TextEditor
                        value={""}
                        placeholder={""}
                        onChange={handleChangeQuill}
                      ></TextEditor>
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default DescriptionComponent;

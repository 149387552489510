import { Breadcrumb, Button, Descriptions, Divider, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tokenService from "../../services/token.service";
import { changePassword, setRequestStatus, userSelector } from "../../store/slices/user-slide";

const ProfileComponent: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { requestStatus } = useSelector(userSelector);
  const [userInfo, setUserInfo] = useState<any>({});

  const onFinish = (data: any) => {
    console.log("Success:", data);
    dispatch(changePassword(data));
  };

  useEffect(() => {
    if (requestStatus) {
      form.setFieldsValue({
        current_passwd: "",
        new_passwd: "",
        confirm_new_passwd: "",
      });
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  useEffect(() => {
    const userInfo = tokenService.getUserInfo();
    setUserInfo(userInfo);
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const renderGroups = (groups: any) => {
    let data = "";
    for (let i = 0; i < groups.length; i++) {
      if (i == groups.length - 1) {
        data += groups[i];
      } else {
        data += groups[i] + ", ";
      }
    }
    return data;
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>My profile</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Divider plain>Thông tin tài khoản</Divider>
        <Descriptions layout="vertical">
          <Descriptions.Item label="Fullname">{userInfo.first_name}</Descriptions.Item>
          <Descriptions.Item label="Username">{userInfo.username}</Descriptions.Item>
          <Descriptions.Item label="Email">{userInfo.email}</Descriptions.Item>
          <Descriptions.Item label="Role">
            {userInfo.groups && renderGroups(userInfo.groups)}
          </Descriptions.Item>
        </Descriptions>

        <Divider plain>Đổi mật khẩu</Divider>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Mật khẩu hiện tại"
            name="current_passwd"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Mật khẩu mới"
            name="new_passwd"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Xác nhận mật khẩu mới"
            name="confirm_new_passwd"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Đổi mật khẩu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ProfileComponent;

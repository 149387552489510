// import { DataType, IStores } from '../components/setting/setting_store';
import { SettingStoreModel } from '../models/models';
import api from './api';
class StoreService {
  getListStoresApi(params: any) {
    return api.get<SettingStoreModel[]>('/api/store/', { params: params });
  }

  addStoreApi(data: any) {
    return api.post('/api/store/', data);
  }

  deleteStoreApi(data: any) {
    return api.delete(`/api/store/${data.id}/`, data);
  }

  loginApi(data: any) {
    return api.post('/api/token/', data);
  }
}
export default new StoreService();

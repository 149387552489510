import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { ITagResponse, ProductTag } from "../../models/models";
import api from "../../services/api";
import storeService from "../../services/store.service";
import { setLoading } from "./app-slice";

export interface IProduct {
  requestStatus: boolean,
  listTags: ProductTag[]
}

// Khởi tạo state cho slice
const initialState: IProduct = {
  requestStatus: false,
  listTags: []

};

// Cấu hình slice
export const tagSlice = createSlice({
  name: "tag",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    // setRequestStatus: (state, action: PayloadAction<boolean>) => {
    //   state.requestStatus = action.payload
    // },
    setListTags: (state, action: PayloadAction<ProductTag[]>) => {
      state.listTags = action.payload
    },
  }
});

export const tagSelector = (state: RootState): IProduct => state.tag

// =========================
/// Actions
// =========================
export const {
  setListTags,
  // setRequestStatus
} = tagSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListTags = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<ITagResponse>(`/api/product/tags/${data}`);
    dispatch(setListTags(response.data.data))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const addTag = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await storeService.addStoreApi(data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["tag"]));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteTag = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await storeService.deleteStoreApi(data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["tag"]));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default tagSlice.reducer;

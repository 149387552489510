import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  Upload,
  message,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PER_ADD_USER, PER_DELETE_USER } from "../../common/cms_const";
import { hasPermission, renderHTML } from "../../common/common_utils";
import { NicheModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addNiche,
  deleteNiche,
  getlistNiche,
  nicheSelector,
  setRequestStatus,
  updateNiche,
} from "../../store/slices/niche-slide";

const Niche: React.FC = () => {
  const dispatch = useDispatch();
  const { appLoading, permissions } = useSelector(appSelector);
  const { listNiche, requestStatus } = useSelector(nicheSelector);
  const [fileList, setFileList] = useState<any[]>([]);
  const [formTitle, setFormTitle] = useState("");
  const [detailInfo, setDetailInfo] = useState<any>();
  const [viewVisible, setViewVisible] = useState(false);
  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
  });

  const handleFileChange = (uploadData: any) => {
    console.log(uploadData);
    setFileList(uploadData.fileList);
  };

  useEffect(() => {
    dispatch(getlistNiche(requestParams));
  }, []);

  useEffect(() => {
    if (requestStatus) {
      dispatch(getlistNiche(requestParams));
      setVisible(false);
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderStatus = (status: number) => {
    if (status) {
      return <Tag color={"green"}>Active</Tag>;
    } else {
      return <Tag color={"volcano"}>Inactive</Tag>;
    }
  };

  const renderImage = (record: any) => {
    if (record.images) {
      return (
        <img style={{ width: "50px" }} src={process.env.REACT_APP_API_SERVER_URL + record.images} />
      );
    } else {
      return "";
    }
  };

  const onFinish = (data: any) => {
    console.log(data);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    if (data.images) {
      data.images = data.images.file;
    } else {
      if (fileList.length > 0) {
        data.images = fileList[0].name;
      } else {
        data.images = undefined;
      }
    }

    data.timing = moment(data.timing).format("YYYY-MM-DD");
    if (data.id) {
      dispatch(updateNiche(data, config));
    } else {
      dispatch(addNiche(data, config));
    }
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    setVisible(true);
    setFormTitle("Update");

    let listImg: any[] = [];
    if (data.images) {
      const fileData = {
        status: "done",
        name: data.images,
        thumbUrl: process.env.REACT_APP_API_SERVER_URL + data.images,
        url: process.env.REACT_APP_API_SERVER_URL + data.images,
      };
      listImg.push(fileData);
    }
    setFileList(listImg);

    form.setFieldsValue({
      id: data.id,
      name: data.name,
      description: data.description,
      copyright: data.copyright,
      status: String(data.status),
      images: undefined,
    });
  };

  const columns: ColumnsType<NicheModel> = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"],
      width: 80,
    },
    {
      title: "Action",
      width: 80,
      render: (_, record) => (
        <>
          <EyeOutlined onClick={() => showDetail(record)} />
          <EditOutlined style={{ marginLeft: 10 }} onClick={() => loadUpdate(record)} />
        </>
      ),
    },
    {
      title: "Tên",
      dataIndex: "name",
      render: (_, record) => (
        <>
          <a onClick={() => loadUpdate(record)}>{record.name}</a>
        </>
      ),
    },
    {
      width: 600,
      title: "Mô tả",
      dataIndex: "description",
    },

    {
      title: "Ảnh mãu",
      render: (_, record) => <>{renderImage(record)}</>,
    },
    {
      title: "Copyright",
      dataIndex: "copyright",
    },
    {
      title: "Ngày tạo",
      render: (_, record) => <>{moment(record.created_date).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Trạng thái",
      render: (_, record) => <>{renderStatus(record.status)}</>,
    },
  ];

  const onChange: TableProps<NicheModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
    setFormTitle("Thêm mới");
    setFileList([]);
    form.setFieldsValue({
      id: "",
      name: "",
      status: "",
      description: "",
      copyright: "",
      images: undefined,
    });
  };

  const onClose = () => {
    setVisible(false);
    setViewVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một item.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        dispatch(deleteNiche(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onSearch = (keyword: string) => {
    console.log("");
    const searchRequest = {
      keyword: keyword,
    };
    dispatch(getlistNiche(searchRequest));
  };

  const showDetail = (data: any) => {
    setViewVisible(true);
    setFormTitle("Chi tiết");
    setDetailInfo(data);
    console.log(data);
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Danh sách niche</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            {hasPermission(permissions, PER_ADD_USER) && (
              <Button
                onClick={showDrawer}
                type="primary"
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
              >
                Thêm mới
              </Button>
            )}

            {hasPermission(permissions, PER_DELETE_USER) && (
              <Button
                type="primary"
                onClick={showConfirm}
                style={{ marginBottom: 16 }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            )}
          </Space>
          <Space direction="horizontal" style={{ float: "right" }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
          </Space>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listNiche}
            onChange={onChange}
            scroll={{ x: 2200 }}
          />

          <Drawer
            title={formTitle}
            width={900}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col md={24} xs={24}>
                    <Form.Item
                      name="name"
                      label="Tên"
                      rules={[{ required: true, message: "Tên là thông tin bắt buộc." }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Copyright"
                      name="copyright"
                      rules={[{ required: true, message: "Copyright là thông tin bắt buộc." }]}
                    >
                      <Radio.Group>
                        <Radio value="Trademark">Trademark</Radio>
                        <Radio value="Patent">Patent</Radio>
                        <Radio value="N/A">N/A</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Trạng thái"
                      name="status"
                      rules={[{ required: true, message: "Trạng thái là thông tin bắt buộc." }]}
                    >
                      <Radio.Group>
                        <Radio value="1">Kích hoạt</Radio>
                        <Radio value="0">Chưa kích hoạt</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col md={24} xs={24}>
                    <Form.Item name="description" label="Ghi chú">
                      <Input.TextArea rows={4} placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <div className="clearfix">
                      <Form.Item name="images" label="Ảnh mẫu">
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          beforeUpload={() => false}
                          onChange={handleFileChange}
                        >
                          {fileList.length >= 1 ? null : (
                            <div>
                              <PlusOutlined />
                              <div className="ant-upload-text">Upload</div>
                            </div>
                          )}
                        </Upload>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>

          <Drawer
            title={formTitle}
            width={1024}
            onClose={onClose}
            visible={viewVisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Descriptions bordered labelStyle={{ fontWeight: "600" }}>
              <Descriptions.Item label="Tên">{detailInfo?.name}</Descriptions.Item>
              <Descriptions.Item label="Copyright">{detailInfo?.copyright}</Descriptions.Item>
              <Descriptions.Item label="Ngày tạo">
                {moment(detailInfo?.timing).format("DD/MM/YYYY")}
              </Descriptions.Item>
              <Descriptions.Item label="Trạng thái">
                {renderStatus(detailInfo?.status)}
              </Descriptions.Item>
              <Descriptions.Item label="Ảnh mẫu" span={3}>
                <Image width={80} src={process.env.REACT_APP_API_SERVER_URL + detailInfo?.images} />
              </Descriptions.Item>
              <Descriptions.Item label="Mô tả" span={3}>
                {renderHTML(detailInfo?.description)}
              </Descriptions.Item>
            </Descriptions>
            {/* {renderHTML(detailInfo)} */}
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default Niche;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { IUserResponse, UserModel } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IUser {
  requestStatus: boolean,
  listUsers: UserModel[]
}

// Khởi tạo state cho slice
const initialState: IUser = {
  requestStatus: false,
  listUsers: []

};

// Cấu hình slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListUsers: (state, action: PayloadAction<UserModel[]>) => {
      state.listUsers = action.payload
    },
  }
});

export const userSelector = (state: RootState): IUser => state.user

// =========================
/// Actions
// =========================
export const {
  setListUsers,
  setRequestStatus
} = userSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListUsers = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<IUserResponse>(`/api/users/`);
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setListUsers(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const addUser = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IUserResponse>(`/api/users/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["user"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateUser = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.patch<IUserResponse>(`/api/users/${data.id}/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["user"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const changePassword = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IUserResponse>(`/api/users/change_passwd/`, data);
    if (response.data.result == "ok") {
      message.success('Thay đổi mật khẩu thành công.');
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteUser = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IUserResponse>(`/api/users/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["user"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default userSlice.reducer;

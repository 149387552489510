import axios from "axios";
import TokenService from "./token.service";
import jwt_decode from "jwt-decode";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // @ts-ignore
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/api/token/" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const refreshToken = TokenService.getLocalRefreshToken();
          if (!refreshToken) {
            return Promise.reject("_error");
          }
          const rs = await instance.post("/api/token/refresh/", {
            refresh: refreshToken,
          });
          const accessToken = rs.data.access;
          TokenService.updateLocalAccessToken(accessToken);
          const decoded: any = jwt_decode(accessToken);
          localStorage.setItem("user_info", JSON.stringify(decoded.user_info));
          return instance(originalConfig);
        } catch (_error) {
          TokenService.removeUser();
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;

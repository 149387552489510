import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { ICategoryResponse, ProductCategory } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IProduct {
  requestStatus: boolean,
  listCategories: ProductCategory[]
  listCategoriesTree: ProductCategory[]
  totalRecords: number;
}

// Khởi tạo state cho slice
const initialState: IProduct = {
  requestStatus: false,
  listCategories: [],
  listCategoriesTree: [],
  totalRecords: 0
};

// Cấu hình slice
export const categorySlice = createSlice({
  name: "category",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListCategories: (state, action: PayloadAction<ProductCategory[]>) => {
      state.listCategories = action.payload
    },
    setListCategoriesTree: (state, action: PayloadAction<ProductCategory[]>) => {
      state.listCategoriesTree = action.payload
    },
    setTotalRecords: (state, action: PayloadAction<number>) => {
      state.totalRecords = action.payload
    },
  }
});

export const categorySelector = (state: RootState): IProduct => state.category

// =========================
/// Actions
// =========================
export const {
  setListCategories,
  setListCategoriesTree,
  setRequestStatus,
  setTotalRecords
} = categorySlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListCategories = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<ICategoryResponse>(`/api/product/categories/${data.storeId}`, {params: data});
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setTotalRecords(response.data.total))
    
    dispatch(setListCategories(newData))
    dispatch(setListCategoriesTree(response.data.data2))
    // console.log(response.data.data2);
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const addCategory = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<ICategoryResponse>(`/api/product/categories/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["category"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateCategory = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.patch<ICategoryResponse>(`/api/product/categories/${data.id}/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["category"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteCategory = (selectedStore: string, data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.post<ICategoryResponse>(`/api/categories/bulk_delete/`, { store_id: selectedStore, list_id: data });
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["category"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default categorySlice.reducer;

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FilterFilled,
  InboxOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Comment,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  List,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  message,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import Dragger from "antd/lib/upload/Dragger";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_ADMIN, ROLE_MANAGER } from "../../common/cms_const";
import { renderDeadline, renderFinishedDate } from "../../common/common_render";
import { buildSelectUserOptions, hasRole, renderHTML } from "../../common/common_utils";
import TextEditor from "../../components/TextEditor";
import { TrendingModel } from "../../models/models";
import api from "../../services/api";
import { appSelector } from "../../store/slices/app-slice";
import {
  addTask,
  deleteTask,
  getListTasks,
  setRequestStatus,
  taskSelector,
  updateTask,
  updateTaskStatus,
} from "../../store/slices/task-slide";
import { getListUsers, userSelector } from "../../store/slices/user-slide";

const { Option } = Select;

const { TextArea } = Input;

interface CommentItem {
  author: string;
  avatar: string;
  content: React.ReactNode;
  datetime: string;
}

interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  submitting: boolean;
  value: string;
}

const CommentList = ({ comments }: { comments: CommentItem[] }) => (
  <List
    dataSource={comments}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const CommentEditor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </>
);

const CommonTask: React.FC = () => {
  const dispatch = useDispatch();
  const { appLoading, userInfo } = useSelector(appSelector);
  const { listTasks, requestStatus } = useSelector(taskSelector);
  const [fileList, setFileList] = useState<any[]>([]);
  const [formTitle, setFormTitle] = useState("");
  const [userOptions, setUserOptions] = useState<any>([]);
  const { listUsers } = useSelector(userSelector);
  const [taskInfo, setTaskInfo] = useState<any>();
  const [selectedTaskId, setSelectedTaskId] = useState<any>();
  const [showFilter, setShowFilter] = useState(false);
  const [filterTaskType, setFilterTaskType] = useState("");
  const [worker, setWorker] = useState("");
  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
    type: "",
    worker: "",
  });

  useEffect(() => {
    dispatch(getListTasks(requestParams));
  }, []);

  useEffect(() => {
    if (listUsers) {
      const first_option = {
        label: "Chọn người thực hiện",
        value: "",
      };
      const options = buildSelectUserOptions(listUsers, first_option, "");
      setUserOptions(options);
    }
  }, [listUsers]);

  useEffect(() => {
    if (requestStatus) {
      dispatch(getListTasks(requestParams));
      setVisible(false);
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderStatus = (row: any) => {
    if (row.status == 1) {
      return <Tag color={"green"}>Đã hoàn thiện</Tag>;
    } else {
      return <Tag color={"volcano"}>Chưa hoàn thiện</Tag>;
    }
  };

  const renderApproveStatus = (record: any) => {
    if (record.approve_status) {
      return (
        <>
          <Row>
            <Col>
              <Tag color={"green"}>Đã duyệt</Tag>
            </Col>
          </Row>
        </>
      );
    } else {
      return <Tag color={"volcano"}>Chưa duyệt</Tag>;
    }
  };

  const renderType = (row: any) => {
    if (!row) {
      return "";
    }

    let text = "";
    let color = "";
    if (row?.type == 1) {
      color = "#73d13d";
      text = "Định kỳ";
    } else {
      color = "#40a9ff";
      text = "Phát sinh";
    }

    if (row?.type) {
      return <Tag color={color}>{text}</Tag>;
    } else {
      return "";
    }
  };

  const renderPriority = (row: any) => {
    if (!row) {
      return "";
    }

    let text = "";
    let color = "";
    if (row?.priority == 1) {
      color = "#ff4d4f";
      text = "Gấp";
    } else if (row.priority == 2) {
      color = "#ff7a45";
      text = "Cao";
    } else if (row.priority == 3) {
      color = "#ffa940";
      text = "Trung bình";
    } else if (row.priority == 4) {
      color = "#36cfc9";
      text = "Thấp";
    }
    if (row?.priority) {
      return <Tag color={color}>{text}</Tag>;
    } else {
      return "";
    }
  };

  const onFinish = (data: any) => {
    console.log(data);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    if (data.attachments && data.attachments.fileList && data.attachments.fileList.length > 0) {
      let imgs = [];
      let listFilePath = "";
      for (const image of data.attachments.fileList) {
        if (image.originFileObj) {
          imgs.push(image.originFileObj);
        } else {
          listFilePath += image.name + "|";
        }
      }
      if (listFilePath.length > 0) {
        listFilePath = listFilePath.substring(0, listFilePath.length - 1);
      }
      data.attachments = listFilePath;
      data.files = imgs;
    }
    data.start_date = moment(data.start_date).format("YYYY-MM-DD");
    data.deadline = moment(data.deadline).format("YYYY-MM-DD");
    data.pic = String(data.pic);
    if (data.id) {
      dispatch(updateTask(data, config));
    } else {
      dispatch(addTask(data, config));
    }
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    if (!listUsers || listUsers.length <= 0) {
      dispatch(getListUsers());
    }

    setVisible(true);
    setFormTitle("Update");

    let listImg: any[] = [];
    if (data.attachments) {
      var listImages = data.attachments.split("|");
      for (let index = 0; index < listImages.length; index++) {
        const element = listImages[index];
        const fileData = {
          status: "done",
          name: element,
          thumbUrl: process.env.REACT_APP_API_SERVER_URL + element,
          url: process.env.REACT_APP_API_SERVER_URL + element,
        };
        listImg.push(fileData);
      }
    }
    setFileList(listImg);

    form.setFieldsValue({
      id: data.id,
      description: data.description,
      note: data.note,
      type: String(data.type),
      start_date: moment(data.start_date),
      pic: data.pic_id,
      priority: String(data.priority),
      status: String(data.status),
      approve_status: String(data.approve_status),
      deadline: data.deadline ? moment(data.deadline) : null,
    });
  };

  const loadViewMode = (data: any) => {
    setSelectedTaskId(data.id);
    setViewVisible(true);
    setTaskInfo(data);
    getComments(data.id);
    form.setFieldsValue({
      id: data.id,
      description: data.description,
      note: data.note,
      type: String(data.type),
      start_date: moment(data.start_date),
      pic: data.pic_id,
      priority: String(data.priority),
      status: String(data.status),
      approve_status: String(data.approve_status),
      deadline: data.deadline ? moment(data.deadline) : null,
    });
  };

  const renderRating = (record: any) => {
    if (record.content_rating) {
      let txtColor = "";
      let txt = "";
      if (record.content_rating == 3) {
        txt = "Tốt";
        txtColor = "green";
      } else if (record.content_rating == 2) {
        txt = "Đạt yêu cầu";
        txtColor = "#dbd400";
      } else {
        txt = "Chưa đạt";
        txtColor = "red";
      }
      return (
        <>
          <span style={{ color: txtColor }}>{txt}</span>
        </>
      );
    } else {
      return "";
    }
  };

  const renderAction = (record: any) => {
    const actions = [];
    if (
      hasRole(userInfo?.groups, ROLE_ADMIN) ||
      hasRole(userInfo?.groups, ROLE_MANAGER) ||
      record.pic_id == userInfo.id
    ) {
      actions.push(<EyeOutlined onClick={() => loadViewMode(record)} />);
    }
    if (hasRole(userInfo?.groups, ROLE_ADMIN) || hasRole(userInfo?.groups, ROLE_MANAGER)) {
      actions.push(<EditOutlined style={{ marginLeft: 10 }} onClick={() => loadUpdate(record)} />);
    }
    return actions;
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"],
    },
    {
      title: "Action",
      render: (_, record) => <>{renderAction(record)}</>,
    },
    {
      title: "Mô tả công việc",
      dataIndex: "description",
      render: (_, record) => <span>{renderHTML(record.description)}</span>,
    },
    {
      title: "Loại công việc",
      render: (_, record) => <>{renderType(record)}</>,
    },
    {
      title: "Độ ưu tiên",
      dataIndex: "note",
      render: (_, record) => <>{renderPriority(record)}</>,
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      render: (_, record) => <>{renderStatus(record)}</>,
    },
    {
      title: "Trạng thái",
      dataIndex: "approve_status",
      render: (_, record) => <>{renderApproveStatus(record)}</>,
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "keyword",
      render: (_, record) => <>{moment(record.start_date).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Deadline",
      render: (_, record) => <>{renderDeadline(record.deadline, record.end_date)}</>,
    },
    {
      title: "Ngày hoàn thành",
      render: (_, record) => <>{renderFinishedDate(record.deadline, record.end_date)}</>,
    },
    {
      title: "Người thực hiện",
      dataIndex: "pic_name",
    },
    {
      title: "Đánh giá",
      render: (_, record) => <>{renderRating(record)}</>,
    },
    {
      title: "Ngày tạo",
      render: (_, record) => (
        <>{record.created_date ? moment(record.created_date).format("DD/MM/YYYY") : ""}</>
      ),
    },
  ];

  const onChange: TableProps<TrendingModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);

  const showDrawer = () => {
    if (!listUsers || listUsers.length <= 0) {
      dispatch(getListUsers());
    }
    setVisible(true);
    setFormTitle("Thêm mới");
    setFileList([]);
    form.setFieldsValue({
      id: "",
      description: "",
      note: "",
      type: "",
      start_date: "",
      pic: "",
      priority: "",
    });
  };

  const onClose = () => {
    setVisible(false);
    setViewVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một item.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        dispatch(deleteTask(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onCompleteStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    const data = {
      task_id: form.getFieldValue("id"),
      status: value,
    };
    dispatch(updateTaskStatus(data));
  };

  const onApproveStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    const data = {
      task_id: form.getFieldValue("id"),
      approve_status: value,
    };
    dispatch(updateTaskStatus(data));
  };

  // COmment
  const [comments, setComments] = useState<CommentItem[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");

  const getComments = async (topicId: any) => {
    if (!topicId) {
      return;
    }
    const getParam = { topic_id: topicId };
    const response = await api.get(`/api/comments`, { params: getParam });
    const newData = response.data.data.map((obj: { created_date: moment.MomentInput }) => ({
      ...obj,
      avatar: "https://joeschmoe.io/api/v1/random",
      datetime: moment(obj.created_date, "YYYY-MM-DD HH:mm:ss").fromNow(),
    }));
    setComments(newData);
  };

  const handleSubmit = async () => {
    if (!value) return;

    setSubmitting(true);
    const data = { topic_id: selectedTaskId, content: value };
    await api.post(`/api/comments/`, data);

    setValue("");
    getComments(selectedTaskId);
    setSubmitting(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const onSearch = (keyword: string) => {
    const searchRequest = {
      ...requestParams,
      keyword: keyword,
    };
    setRequestParams(searchRequest);
    dispatch(getListTasks(searchRequest));
  };

  const onShowFilter = () => {
    setShowFilter(!showFilter);
    if (!listUsers || listUsers.length <= 0) {
      dispatch(getListUsers());
    }
  };

  const onClearFilter = () => {
    const searchRequest = {
      ...requestParams,
      worker: "",
      type: "",
    };

    setRequestParams(searchRequest);
    setFilterTaskType("");
    setWorker("");
    dispatch(getListTasks(searchRequest));
  };

  const onFilterTypeChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      type: value,
    };
    setFilterTaskType(value);
    setRequestParams(searchRequest);
    dispatch(getListTasks(searchRequest));
  };

  const onFilterWorkerChange = (value: string) => {
    const searchRequest = {
      ...requestParams,
      worker: value,
    };
    setWorker(value);
    setRequestParams(searchRequest);
    dispatch(getListTasks(searchRequest));
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Quản lý công việc</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            {hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER) && (
              <>
                <Button
                  onClick={showDrawer}
                  type="primary"
                  style={{ marginBottom: 16 }}
                  icon={<PlusOutlined />}
                >
                  Thêm mới
                </Button>
                <Button
                  type="primary"
                  onClick={showConfirm}
                  style={{ marginBottom: 16 }}
                  icon={<DeleteOutlined />}
                >
                  Xóa
                </Button>
              </>
            )}
          </Space>
          <Space direction="horizontal" style={{ float: "right", marginBottom: 16 }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
            <FilterFilled onClick={onShowFilter} />
          </Space>

          {showFilter && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                {hasRole(userInfo?.groups, ROLE_ADMIN) && (
                  <Select
                    showSearch
                    defaultValue={""}
                    style={{ minWidth: 200 }}
                    value={worker}
                    onChange={onFilterWorkerChange}
                    options={userOptions}
                    optionFilterProp={"label"}
                    filterOption={(input, option: any) => {
                      const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                      if (cOption) {
                        return cOption.toLowerCase().includes(input.toLowerCase());
                      } else {
                        return false;
                      }
                    }}
                  ></Select>
                )}

                <Select
                  style={{ marginLeft: 15, minWidth: 150 }}
                  placeholder="Loại công việc"
                  value={filterTaskType}
                  defaultValue={""}
                  onChange={onFilterTypeChange}
                >
                  <Option value="">Loại công việc</Option>
                  <Option value="1">Công việc định kỳ</Option>
                  <Option value="2">Công việc phát sinh</Option>
                </Select>
                <Button type="link" onClick={onClearFilter}>
                  Xóa bộ lọc
                </Button>
              </Col>
            </Row>
          )}
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listTasks}
            onChange={onChange}
            scroll={{ x: 2200 }}
          />

          <Drawer
            title={formTitle}
            width={900}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>

                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="pic"
                      label="Người thực hiện"
                      rules={[
                        { required: true, message: "Người thực hiện là thông tin bắt buộc." },
                      ]}
                    >
                      <Select
                        showSearch
                        defaultValue={""}
                        style={{ minWidth: 200 }}
                        // value={filterDesignerVal}
                        options={userOptions}
                        optionFilterProp={"label"}
                        filterOption={(input, option: any) => {
                          const cOption = option.label.props?.dangerouslySetInnerHTML.__html;
                          if (cOption) {
                            return cOption.toLowerCase().includes(input.toLowerCase());
                          } else {
                            return false;
                          }
                        }}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="start_date"
                      label="Ngày bắt đầu"
                      rules={[{ required: true, message: "Ngày bắt đầu là thông tin bắt buộc." }]}
                    >
                      <DatePicker
                        placeholder="Chọn thời điểm"
                        format={"YYYY/MM/DD"}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="deadline"
                      label="Deadline"
                      rules={[{ required: true, message: "Deadline là thông tin bắt buộc." }]}
                    >
                      <DatePicker
                        placeholder="Chọn thời điểm"
                        format={"YYYY/MM/DD"}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="type"
                      label="Loại công việc"
                      rules={[{ required: true, message: "Loại công việc là thông tin bắt buộc." }]}
                    >
                      <Radio.Group>
                        <Radio value="1">Công việc định kỳ</Radio>
                        <Radio value="2">Công việc phát sinh</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="priority"
                      label="Độ ưu tiên"
                      rules={[{ required: true, message: "Độ ưu tiên là thông tin bắt buộc." }]}
                    >
                      <Radio.Group>
                        <Radio value="1">Làm gấp</Radio>
                        <Radio value="2">Cao</Radio>
                        <Radio value="3">Trung bình</Radio>
                        <Radio value="4">Thấp</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="attachments" label="File đính kèm">
                      <Dragger
                        multiple={true}
                        // maxCount={1}
                        fileList={fileList}
                        beforeUpload={() => false}
                        onChange={(uploadData) => {
                          setFileList(uploadData.fileList);
                        }}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      </Dragger>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={24} xs={24}>
                    <Form.Item
                      name="description"
                      label="Mô tả công việc"
                      rules={[
                        { required: true, message: "Mô tả công việc là thông tin bắt buộc." },
                      ]}
                    >
                      {/* <Input.TextArea rows={4} placeholder="" /> */}
                      <TextEditor value={""} placeholder={""} onChange={() => {}}></TextEditor>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={24} xs={24}>
                    <Form.Item name="note" label="Ghi chú">
                      <Input.TextArea rows={4} placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>

          <Drawer
            title={formTitle}
            width={1024}
            onClose={onClose}
            visible={viewVisible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>

                <Row gutter={16}>
                  {taskInfo?.pic_id == userInfo?.id && (
                    <Col span={12}>
                      <Form.Item label="Tình trạng" name="status">
                        <Radio.Group onChange={onCompleteStatusChange}>
                          <Radio.Button value="1">Đã hoàn thành</Radio.Button>
                          <Radio.Button value="0">Chưa hoàn thành</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  )}
                  {hasRole(userInfo?.groups, ROLE_ADMIN, ROLE_MANAGER) && (
                    <>
                      <Col span={12}></Col>
                      <Col span={12}>
                        <Form.Item
                          label="Phê duyệt"
                          name="approve_status"
                          style={{ float: "right" }}
                        >
                          <Radio.Group onChange={onApproveStatusChange}>
                            <Radio.Button value="1">Duyệt</Radio.Button>
                            <Radio.Button value="0">Chưa duyệt</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>

              <Descriptions labelStyle={{ fontWeight: "600" }}>
                <Descriptions.Item label="Người thực hiện" span={3}>
                  {taskInfo?.pic_name}
                </Descriptions.Item>
                <Descriptions.Item label="Ngày bắt đầu">
                  {moment(taskInfo?.start_date).format("DD/MM/YYYY")}
                </Descriptions.Item>
                <Descriptions.Item label="Loại công việc">{renderType(taskInfo)}</Descriptions.Item>
                <Descriptions.Item label="Độ ưu tiên">{renderPriority(taskInfo)}</Descriptions.Item>
                <Descriptions.Item label="Description" span={3}>
                  {renderHTML(taskInfo?.description)}
                </Descriptions.Item>
                <Descriptions.Item label="Note" span={3}>
                  {taskInfo?.note}
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              {comments.length > 0 && <CommentList comments={comments} />}
              <Comment
                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />}
                content={
                  <CommentEditor
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                    value={value}
                  />
                }
              />
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default CommonTask;

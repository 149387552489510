import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { ICommonTaskResponse, CommonTaskModel } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface ICommonTask {
  requestStatus: boolean,
  listTasks: CommonTaskModel[],
}

const initialState: ICommonTask = {
  requestStatus: false,
  listTasks: [],
};

export const taskSlide = createSlice({
  name: "task",
  initialState,
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListTasks: (state, action: PayloadAction<CommonTaskModel[]>) => {
      state.listTasks = action.payload
    },
  }
});

export const taskSelector = (state: RootState): ICommonTask => state.task

// =========================
/// Actions
// =========================
export const {
  setListTasks,
  setRequestStatus
} = taskSlide.actions


// =========================
/// Dispatch Actions
// =========================
export const getListTasks = (param: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.get<ICommonTaskResponse>(`/api/tasks`, { params: param });
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setListTasks(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}


export const addTask = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<ICommonTaskResponse>(`/api/tasks/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["task"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateTask = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.patch<ICommonTaskResponse>(`/api/tasks/${data.id}/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["task"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteTask = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.post<ICommonTaskResponse>(`/api/tasks/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["task"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateTaskStatus = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<any>(`/api/tasks/update-status/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["task"]));
      // dispatch(setUpdateStatus(true));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }
  } catch (error) {
    console.log(error);
    message.error("Unexpected error.");
  } finally {
    dispatch(setLoading(false))
  }
}

export default taskSlide.reducer;

import thunk, { ThunkAction } from 'redux-thunk';
import { Action, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import settingSlide from './slices/setting-slide';
import authSlice from './slices/auth-slide';
import appSlice from './slices/app-slice';
import productSlice from './slices/product-slide';
import categorySlice from './slices/category-slide';
import tagSlide from './slices/tag-slide';
import userSlice from './slices/user-slide';
import groupSlice from './slices/group-slide';
import fullfillSlice from './slices/fullfill-slide';
import orderSlice from './slices/order-slide';
import guidelineSelector from './slices/guideline-slide';
import descriptionSlide from './slices/description-slide';
import trendingSelector from './slices/trending-slide';
import holidaySelector from './slices/holiday-slide';
import nicheSelector from './slices/niche-slide';
import productTypeSelector from './slices/productType-slide';
import ideaSelector from './slices/idea-slide';
import taskSelector from './slices/task-slide';

export const history = createBrowserHistory();

const middlewares = [routerMiddleware(history), thunk];

const store = configureStore({
  reducer: {
    app: appSlice,
    auth: authSlice,
    setting: settingSlide,
    product: productSlice,
    category: categorySlice,
    tag: tagSlide,
    user: userSlice,
    group: groupSlice,
    guideline: guidelineSelector,
    fullfillSlice: fullfillSlice,
    order: orderSlice,
    description: descriptionSlide,
    trending: trendingSelector,
    holiday: holidaySelector,
    niche: nicheSelector,
    productType: productTypeSelector,
    idea: ideaSelector,
    task: taskSelector,
    router: connectRouter(history),
  },
  middleware: middlewares,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>

export default store;

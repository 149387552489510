import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  TableProps,
  message,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PER_ADD_USER, PER_DELETE_USER, PER_UPDATE_USER } from "../../common/cms_const";
import { hasPermission } from "../../common/common_utils";
import { GroupModel, PermissionModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addGroup,
  deleteGroup,
  getListGroups,
  getListPermission,
  groupSelector,
  setRequestStatus,
  updateGroup,
} from "../../store/slices/group-slide";

const GroupComponent: React.FC = () => {
  const dispatch = useDispatch();
  const { listGroups, requestStatus, listPermissions } = useSelector(groupSelector);
  const [selectedPermissions, setSelectedPermissions] = useState<Set<number>>(new Set());
  const { appLoading, permissions } = useSelector(appSelector);
  const [listPers, setListPers] = useState<PermissionModel[]>([]);

  useEffect(() => {
    dispatch(getListGroups());
    if (!listPermissions || listPermissions.length <= 0) {
      dispatch(getListPermission());
    }
  }, []);

  useEffect(() => {
    if (requestStatus) {
      dispatch(getListGroups());
      setVisible(false);
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const onFinish = (formData: any) => {
    console.log(formData);

    let arrPers = [];
    for (let i = 0; i < listPers.length; i++) {
      for (let j = 0; j < listPers[i].permissions.length; j++) {
        if (listPers[i].permissions[j].checked == true) {
          arrPers.push(listPers[i].permissions[j].id);
        }
      }
    }

    formData.permissions = arrPers; //Array.from(selectedPermissions);

    if (formData.id) {
      dispatch(updateGroup(formData));
    } else {
      dispatch(addGroup(formData));
    }
  };

  const columns: ColumnsType<GroupModel> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <>
          <a onClick={() => loadUpdate(record)}>{record.name}</a>
        </>
      ),
    },
  ];

  const [form] = Form.useForm();
  const loadUpdate = (group: GroupModel) => {
    if (!hasPermission(permissions, PER_UPDATE_USER)) {
      return;
    }
    setVisible(true);
    if (!listPermissions || listPermissions.length <= 0) {
      dispatch(getListPermission());
    }
    let data: PermissionModel[] = [];
    for (let i = 0; i < listPermissions.length; i++) {
      const temp = JSON.parse(JSON.stringify(listPermissions[i]));
      for (let j = 0; j < listPermissions[i].permissions.length; j++) {
        temp.permissions[j].checked = false;
        group.permissions?.map((property) => {
          if (listPermissions[i].permissions[j].id == property.id) {
            temp.permissions[j].checked = true;
          }
        });
      }
      data.push(temp);
    }
    setListPers(data);
    console.log(listPers[0]);

    form.setFieldsValue({
      id: group.id,
      name: group.name,
    });
  };

  const onChange: TableProps<GroupModel>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
    form.setFieldsValue({
      id: "",
      name: "",
    });
    if (!listPermissions || listPermissions.length <= 0) {
      dispatch(getListPermission());
    }
    setListPers(listPermissions);
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      selectedPermissions.add(e.target.value);
    } else {
      selectedPermissions.delete(e.target.value);
    }
    setSelectedPermissions(selectedPermissions);

    console.log(listPers[0]);
    let data: PermissionModel[] = [];
    for (let i = 0; i < listPers.length; i++) {
      const temp = JSON.parse(JSON.stringify(listPers[i]));
      for (let j = 0; j < listPers[i].permissions.length; j++) {
        if (listPers[i].permissions[j].id == e.target.value) {
          if (e.target.checked) {
            temp.permissions[j].checked = true;
          } else {
            temp.permissions[j].checked = false;
          }
        }
      }
      data.push(temp);
    }
    setListPers(data);

    console.log(selectedPermissions);
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một role.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        dispatch(deleteGroup(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Quản lý quyền</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            {hasPermission(permissions, PER_ADD_USER) && (
              <Button
                onClick={showDrawer}
                type="primary"
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
              >
                Thêm nhóm quyền
              </Button>
            )}
            {hasPermission(permissions, PER_DELETE_USER) && (
              <Button
                type="primary"
                onClick={showConfirm}
                style={{ marginBottom: 16 }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            )}
          </Space>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listGroups}
            onChange={onChange}
          />

          <Drawer
            title="Thêm mới nhóm quyền"
            // width={720}
            width={window.innerWidth > 900 ? 720 : 400}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="name"
                      label="Tên nhóm quyền"
                      rules={[{ required: true, message: "Tên quyền là thông tin bắt buộc." }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  {listPers.map((property) => {
                    return (
                      <Col md={12} xs={24}>
                        <Card title={property.title} style={{ width: 300 }}>
                          {property.permissions.map((item) => {
                            return (
                              <Checkbox
                                checked={item.checked}
                                onChange={onCheckboxChange}
                                value={item.id}
                              >
                                {item.name}
                              </Checkbox>
                            );
                          })}
                        </Card>
                      </Col>
                    );
                  })}
                </Row>

                <Row style={{ float: "right", marginTop: 30 }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default GroupComponent;

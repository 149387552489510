import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { IOrderResponse, OrderModel } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IOrder {
  requestStatus: boolean,
  listOrders: OrderModel[],
  totalRecords: number
}

// Khởi tạo state cho slice
const initialState: IOrder = {
  requestStatus: false,
  listOrders: [],
  totalRecords: 0

};

// Cấu hình slice
export const orderSlice = createSlice({
  name: "order",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListOrders: (state, action: PayloadAction<OrderModel[]>) => {
      state.listOrders = action.payload
    },
    setTotalRecords: (state, action: PayloadAction<number>) => {
      state.totalRecords = action.payload
    },
  }
});

export const orderSelector = (state: RootState): IOrder => state.order

// =========================
/// Actions
// =========================
export const {
  setListOrders,
  setRequestStatus,
  setTotalRecords
} = orderSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListOrders = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<IOrderResponse>(`/api/orders/?store_id=${data.storeId}`, {params: data});
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setTotalRecords(response.data.total))
    dispatch(setListOrders(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const addOrder = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IOrderResponse>(`/api/orders/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["order"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}


export const updateOrder = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IOrderResponse>(`/api/orders/update_order/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["order"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const exportOrder = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post(`/api/orders/do_export/`, data);
    const fileName = data.fullfill_name + `_${(new Date().toJSON().slice(0, 10))}.csv`
    const path = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = path
    link.setAttribute('download', decodeURIComponent(fileName))
    document.body.appendChild(link)
    link.click()

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteOrder = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IOrderResponse>(`/api/orders/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["order"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default orderSlice.reducer;

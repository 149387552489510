import { RecordType } from "../pages/order/fullfill";

export const mockData: RecordType[] = [

    {
        key: 'total',
        title: `Total`,
        description: `description of content`,
    },
    {
        key: 'date_created',
        title: `Date`,
        description: `description of content`,
    },
    {
        key: 'id',
        title: `Order ID`,
        description: `description of content`,
    },
    {
        key: 'fullname',
        title: `Full Name`,
        description: `description of content`,
    },
    {
        key: 'first_name',
        title: `First Name`,
        description: `description of content`,
    },
    {
        key: 'last_name',
        title: `Last Name`,
        description: `description of content`,
    },
    {
        key: 'shipping_address_1',
        title: `Address 1`,
        description: `description of content`,
    },
    {
        key: 'shipping_address_2',
        title: `Address 2`,
        description: `description of content`,
    },
    {
        key: 'country_code',
        title: `Country Code`,
        description: `description of content`,
    },
    {
        key: 'country_name',
        title: `Country Name`,
        description: `description of content`,
    },
    {
        key: 'city',
        title: `City`,
        description: `description of content`,
    },
    {
        key: 'state_code',
        title: `State Code`,
        description: `description of content`,
    },
    {
        key: 'postcode',
        title: `Postcode`,
        description: `description of content`,
    },
    {
        key: 'phone',
        title: `Phone`,
        description: `description of content`,
    },
    {
        key: 'product_name',
        title: `Product Name`,
        description: `description of content`,
    },
    {
        key: 'variantion',
        title: `Variantion`,
        description: `description of content`,
    },
    {
        key: 'quantity',
        title: `Quantity`,
        description: `description of content`,
    },
    {
        key: 'sku',
        title: `SKU`,
        description: `description of content`,
    },
    {
        key: 'note',
        title: `Note`,
        description: `description of content`,
    },
    {
        key: 'email',
        title: `Email`,
        description: `description of content`,
    },
    {
        key: 'product_image',
        title: `Product Image`,
        description: `description of content`,
    },
    {
        key: 'empty1',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty2',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty3',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty4',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty5',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty6',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty7',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty8',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty9',
        title: `Empty`,
        description: `description of content`,
    },
    {
        key: 'empty10',
        title: `Empty`,
        description: `description of content`,
    },
];

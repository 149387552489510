import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { FullFillModel, IFullFillResponse } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IFullFill {
  requestStatus: boolean,
  listFullfill: FullFillModel[]
}

// Khởi tạo state cho slice
const initialState: IFullFill = {
  requestStatus: false,
  listFullfill: []

};

// Cấu hình slice
export const fullfillSlice = createSlice({
  name: "user",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListFullfill: (state, action: PayloadAction<FullFillModel[]>) => {
      state.listFullfill = action.payload
    },
  }
});

export const fullfillSelector = (state: RootState): IFullFill => state.fullfillSlice

// =========================
/// Actions
// =========================
export const {
  setListFullfill,
  setRequestStatus
} = fullfillSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListFullfill = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<IFullFillResponse>(`/api/fullfill/`);
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setListFullfill(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const addFullfill = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IFullFillResponse>(`/api/fullfill/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["fullfill"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateFullfill = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.patch<IFullFillResponse>(`/api/fullfill/${data.id}/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["fullfill"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteFullfill = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IFullFillResponse>(`/api/fullfill/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["fullfill"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default fullfillSlice.reducer;

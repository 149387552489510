import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage } from "../../common/common_utils";
import { IHolidayResponse, HolidayModel } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IHoliday {
  requestStatus: boolean,
  listHoliday: HolidayModel[],
}

const initialState: IHoliday = {
  requestStatus: false,
  listHoliday: [],
};

export const userSlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setlistHoliday: (state, action: PayloadAction<HolidayModel[]>) => {
      state.listHoliday = action.payload
    },
  }
});

export const holidaySelector = (state: RootState): IHoliday => state.holiday

// =========================
/// Actions
// =========================
export const {
  setlistHoliday,
  setRequestStatus
} = userSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getlistHoliday = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.get<IHolidayResponse>(`/api/holiday`, { params: data });
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setlistHoliday(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}


export const addHoliday = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IHolidayResponse>(`/api/holiday/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["holiday"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateHoliday = (data: any, config: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.patch<IHolidayResponse>(`/api/holiday/${data.id}/`, data, config);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["holiday"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteHoliday = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    const response = await api.post<IHolidayResponse>(`/api/holiday/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["holiday"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export default userSlice.reducer;

import { Tag } from "antd";
import moment from "moment";

export const renderStatus = (status: number) => {
    if (status) {
        return (<Tag color={"green"}>
            Active
        </Tag>)
    } else {
        return (<Tag color={"volcano"}>
            Inactive
        </Tag>)
    }
};


export const renderDeadline = (deadline_date: any, finished_date: any) => {
    if (deadline_date) {
        if (finished_date) {
            return moment(deadline_date).format('DD/MM/YYYY');
        }
        const deadline = moment(deadline_date)
        const today = moment(new Date())
        const days = today.diff(deadline, 'days')
        let text = ''

        let color = 'green'
        if (days > 0) {
            text = `Quá hạn ${days} ngày`;
            color = 'volcano'
        }
        return (
            <>
                {moment(deadline_date).format('DD/MM/YYYY')} <br />
                {text && (
                    <Tag color={color}>
                        {text}
                    </Tag>
                )}
            </>
        )
    } else {
        return "";
    }
}

export const renderFinishedDate = (deadline_date: any, finished_date: any) => {
    if (finished_date) {
        const deadline = moment(deadline_date)
        const today = moment(finished_date)
        const days = today.diff(deadline, 'days')
        let text = ''

        let color = 'green'
        if (days > 0) {
            text = `Quá hạn ${days} ngày`;
            color = 'volcano'
        } else if (days < 0) {
            text = `Trước hạn ${Math.abs(days)} ngày`;
        } else {
            text = `Đúng hạn`;
        }
        return (
            <>
                {moment(finished_date).format('DD/MM/YYYY')}<br />
                <Tag color={color}>
                    {text}
                </Tag>
            </>
        )
    } else {
        return "";
    }
}
import React from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { ICategoryResponse } from "../models/models";
import api from "../services/api";
import { appSelector } from "../store/slices/app-slice";
import DebounceSelect from "./debounceSelect";

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  optionsVal: any[];
  onChange: OnChangeHandler;
};

interface UserValue {
  label: string;
  value: string;
}

const SeachableTag: React.FC<Props> = ({ optionsVal, onChange }) => {
  const { selectedStoreId } = useSelector(appSelector);

  async function fetchTagsList(keyword: string): Promise<UserValue[]> {
    const data = { store_id: selectedStoreId, pageIndex: 1, pageSize: 20, keyword: keyword };
    const response = await api.post<ICategoryResponse>(`/api/tags/list/`, data);

    const arr: any = [];
    for (let index = 0; index < response.data.data.length; index++) {
      const element = response.data.data[index];
      console.log(element);
      arr.push({
        label: element.name,
        value: element.id,
      });
    }
    return arr;
  }
  return (
    <DebounceSelect
      mode="tags"
      value={optionsVal}
      onChange={onChange}
      placeholder="Search tags"
      fetchOptions={fetchTagsList}
      style={{ width: "100%" }}
    />
  );
};

export default SeachableTag;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, RootState } from "..";
import { MSG_01, MSG_02, MSG_03, MSG_04 } from "../../common/cms_message";
import { getMessage, show_error_msg } from "../../common/common_utils";
import { DescriptionModel, IDescriptionResponse } from "../../models/models";
import api from "../../services/api";
import { setLoading } from "./app-slice";

export interface IDescription {
  requestStatus: boolean,
  listDescriptions: DescriptionModel[]
}

// Khởi tạo state cho slice
const initialState: IDescription = {
  requestStatus: false,
  listDescriptions: []

};

// Cấu hình slice
export const userSlice = createSlice({
  name: "description",
  initialState,
  // Reducers chứa các hàm xử lý cập nhật state
  reducers: {
    setRequestStatus: (state, action: PayloadAction<boolean>) => {
      state.requestStatus = action.payload
    },
    setListDescriptions: (state, action: PayloadAction<DescriptionModel[]>) => {
      state.listDescriptions = action.payload
    },
  }
});

export const descriptionSelector = (state: RootState): IDescription => state.description

// =========================
/// Actions
// =========================
export const {
  setListDescriptions,
  setRequestStatus
} = userSlice.actions


// =========================
/// Dispatch Actions
// =========================
export const getListDesciptions = (data: any = {}): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.get<IDescriptionResponse>(`/api/descriptions/`, { params: data });
    const newData = response.data.data.map(obj => ({ ...obj, key: obj.id }))
    dispatch(setListDescriptions(newData))

  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false))
  }
}

export const addDesciption = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IDescriptionResponse>(`/api/descriptions/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_01, ["description"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateDesciption = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    // Call api
    const response = await api.patch<IDescriptionResponse>(`/api/descriptions/${data.id}/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["description"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(getMessage(MSG_04));
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteDesciption = (data: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true))
    // Call api
    const response = await api.post<IDescriptionResponse>(`/api/descriptions/bulk_delete/`, {
      list_id: data
    });

    if (response.data.result == "ok") {
      message.success(getMessage(MSG_03, ["description"]));
      dispatch(setRequestStatus(true));
    } else {
      message.error(response.data.message);
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateDesciptionStatus = (data: any): AppThunk => async dispatch => {
  try {

    dispatch(setLoading(true))
    const response = await api.post<IDescriptionResponse>(`/api/descriptions/update_status/`, data);
    if (response.data.result == "ok") {
      message.success(getMessage(MSG_02, ["description"]));
      dispatch(setRequestStatus(true));
    } else {
      show_error_msg(response.data)
    }

  } catch (error) {
    console.log(error);
    message.error(error + "");
  } finally {
    dispatch(setLoading(false))
  }
}

// Export reducer để nhúng vào Store
export default userSlice.reducer;

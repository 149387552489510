import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  MessageOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Col, Drawer, Form, Input, List, Modal, Row, Space, Spin } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { TRAINING_TYPE } from "../../common/cms_const";
import { formatDate, renderHTML } from "../../common/common_utils";
import TextEditor from "../../components/TextEditor";
import { GuidelineModel } from "../../models/models";
import { appSelector, setLoading } from "../../store/slices/app-slice";
import {
  addGuideline,
  deleteGuideline,
  getListGuideline,
  guidelineSelector,
  setRequestStatus,
  updateGuideline,
} from "../../store/slices/guideline-slide";

const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const TrainingComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appLoading } = useSelector(appSelector);
  const { listGuideline, requestStatus } = useSelector(guidelineSelector);

  useEffect(() => {
    dispatch(getListGuideline(TRAINING_TYPE));
  }, []);

  useEffect(() => {
    if (requestStatus) {
      setLoading(false);
      setVisible(false);
      dispatch(getListGuideline(TRAINING_TYPE));
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const showDrawer = (data: any) => {
    setVisible(true);
    form.setFieldsValue({
      title: "",
      content: "",
    });
    console.log(data);
  };

  const [visible, setVisible] = useState(false);
  const onClose = () => {
    setLoading(false);
    setVisible(false);
  };

  const onFinish = (guide: GuidelineModel) => {
    guide.type = TRAINING_TYPE;
    if (guide.id) {
      dispatch(updateGuideline(guide));
    } else {
      dispatch(addGuideline(guide));
    }
  };

  const [form] = Form.useForm();

  const handleUpdate = (id: number) => {
    setVisible(true);
    for (let i = 0; i < listGuideline.length; i++) {
      if (id == listGuideline[i].id) {
        form.setFieldsValue({
          id: listGuideline[i].id,
          title: listGuideline[i].title,
          content: listGuideline[i].content,
        });
        break;
      }
    }
  };

  const handleDetail = (id: number) => {
    navigate("/training-detail?id=" + id);
  };

  const handleChangeQuill = (html: any) => {};

  const { confirm } = Modal;
  const showConfirm = (id: any) => {
    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        dispatch(deleteGuideline([id]));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Training</Breadcrumb.Item>
      </Breadcrumb>

      <Button
        onClick={showDrawer}
        type="primary"
        style={{ marginBottom: 16 }}
        icon={<PlusOutlined />}
      >
        Thêm mới
      </Button>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 3,
          }}
          dataSource={listGuideline}
          renderItem={(item) => (
            <List.Item
              key={item.title}
              actions={[
                <a onClick={() => handleDetail(item.id)}>
                  <IconText
                    icon={MessageOutlined}
                    text="Xem chi tiết"
                    key="list-vertical-message"
                  />
                </a>,
                <a onClick={() => handleUpdate(item.id)}>
                  <IconText icon={FormOutlined} text="Chỉnh sửa" key="list-vertical-message" />
                </a>,
                <a onClick={() => showConfirm(item.id)}>
                  <IconText icon={DeleteOutlined} text="Xóa" key="list-vertical-message" />
                </a>,
              ]}
            >
              <List.Item.Meta
                style={{ color: "red" }}
                title={
                  <h1>
                    <a href={"/training-detail?id=" + item.id}>{item.title}</a>
                  </h1>
                }
                description={formatDate(item.created_date)}
              />
              <Paragraph ellipsis={{ rows: 2 }}>{renderHTML(item.content)}</Paragraph>
            </List.Item>
          )}
        />

        <Drawer
          title="Thêm mới"
          width={1024}
          onClose={onClose}
          visible={visible}
          maskClosable={false}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Spin spinning={appLoading}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[{ required: true, message: "Title là thông tin bắt buộc." }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="content" label="Description">
                    <TextEditor
                      value={""}
                      placeholder={""}
                      onChange={handleChangeQuill}
                    ></TextEditor>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item hidden name="id">
                <Input type="hidden" />
              </Form.Item>

              <Row style={{ float: "right" }}>
                <Col>
                  <Space>
                    <Button id="" htmlType="submit" type="primary">
                      Submit
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </div>
    </>
  );
};

export default TrainingComponent;

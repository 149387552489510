import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Pagination,
  PaginationProps,
  Popover,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TableProps,
  Upload,
  message,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { TreeNode } from "antd/lib/tree-select";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PER_ADD_PRODUCT, PER_DELETE_PRODUCT, PER_UPDATE_PRODUCT } from "../../common/cms_const";
import { getCategoriesByIds, hasPermission, renderHTML } from "../../common/common_utils";
import SeachableCategory from "../../components/SeachableCategory";
import TextEditor from "../../components/TextEditor";
import { ProductCategory } from "../../models/models";
import api from "../../services/api";
import { appSelector } from "../../store/slices/app-slice";
import {
  addCategory,
  categorySelector,
  deleteCategory,
  getListCategories,
  setRequestStatus,
  updateCategory,
} from "../../store/slices/category-slide";
import { descriptionSelector, getListDesciptions } from "../../store/slices/description-slide";

const CategoryComponent: React.FC = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ProductCategory>>({});
  const dispatch = useDispatch();
  const { listCategories, requestStatus, totalRecords } = useSelector(categorySelector);
  const { selectedStoreId } = useSelector(appSelector);
  const [selectedCategoties, setSelectedCategoties] = useState<any[]>([]);
  const { appLoading, permissions } = useSelector(appSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
    status: "1",
    storeId: "",
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "createTime",
    sortOrder: "DESC",
  });
  const [descriptionOptions, setDescriptionOptions] = useState<SelectProps["options"]>([]);
  const { listDescriptions } = useSelector(descriptionSelector);

  useEffect(() => {
    if (selectedStoreId) {
      const searchRequest = {
        ...requestParams,
        pageIndex: 1,
        storeId: selectedStoreId,
      };
      setRequestParams(searchRequest);
      dispatch(getListCategories(searchRequest));
      dispatch(getListDesciptions({ content_type: 1, storeId: selectedStoreId }));
    }
  }, [selectedStoreId]);

  useEffect(() => {
    if (listDescriptions) {
      const optionsData: SelectProps["options"] = [];
      for (let i = 0; i < listDescriptions.length; i++) {
        optionsData.push({
          label: listDescriptions[i].title,
          value: listDescriptions[i].id,
        });
      }
      setDescriptionOptions(optionsData);
    }
  }, [listDescriptions]);

  useEffect(() => {
    if (requestStatus) {
      dispatch(getListCategories(requestParams));
      setVisible(false);
      dispatch(setRequestStatus(false));
      setFileUpload([]);
      setFileList([]);
    }
  }, [requestStatus]);

  const onFinish = (category: ProductCategory) => {
    category.store_id = selectedStoreId;

    if (category.parent.length > 1) {
      message.error("Chỉ được chọn 1 parrent category.");
      return;
    }
    if (category.parent.length > 0) {
      category.parent = category.parent[0].value;
    }

    if (fileUpload && fileUpload.length > 0) {
      category.image = { id: fileUpload[0].id, src: fileUpload[0].url };
    } else {
      category.image = {};
    }

    if (category.id) {
      dispatch(updateCategory(category));
    } else {
      dispatch(addCategory(category));
    }
  };

  const loadCategories = async (cateIds: any[]) => {
    const result: any[] = await getCategoriesByIds(selectedStoreId, cateIds);
    setSelectedCategoties(result);
  };

  const [form] = Form.useForm();
  const loadUpdate = (row: any) => {
    if (!hasPermission(permissions, PER_UPDATE_PRODUCT)) {
      return;
    }
    setVisible(true);
    if (row.parent != 0) {
      loadCategories([row.parent]);
    }

    form.setFieldsValue({
      id: row.id,
      description: row.description,
      name: decodeURI(row.name),
      // parent: row.parent != 0 ? String(row.parent) : ""
    });

    if (row.parent != 0) {
      setSelectedCategoties(row.parent);
    } else {
      setSelectedCategoties([]);
    }

    let listImg: any[] = [];
    const element = row.image;
    const fileData = {
      status: "done",
      name: element.name,
      thumbUrl: element.src,
      id: element.id,
      position: element.position,
      url: element.src,
    };
    listImg.push(fileData);
    setFileUpload(listImg);
    setFileList(listImg);
  };

  const columns: ColumnsType<ProductCategory> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Image",
      render: (_, record) => <img style={{ width: "50px" }} src={record.image?.src} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      render: (_, record) => <a onClick={() => loadUpdate(record)}>{renderHTML(record.name)}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (_, record) => (
        <Popover
          content={renderHTML(record.description)}
          overlayStyle={{
            width: "30vw",
          }}
          title="Description"
        >
          <a type="primary">Detail</a>
        </Popover>
      ),
    },
    {
      title: "URL",
      dataIndex: "email",
      render: (_, record) => (
        <a target="_blank" href={record.url}>
          {record.url}
        </a>
      ),
    },
    {
      title: "Count",
      dataIndex: "count",
    },
  ];

  const onChange: TableProps<ProductCategory>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
    setSortedInfo(sorter as SorterResult<ProductCategory>);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    if (selectedStoreId) {
      setVisible(true);
      form.setFieldsValue({
        id: "",
        description: "",
        name: "",
        parent: "",
      });
    } else {
      message.warning("Please select a store.");
    }
    setSelectedCategoties([]);
    setFileUpload([]);
    setFileList([]);
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangeQuill = (html: any) => {};

  const renderTree = (treeData: ProductCategory[]) => {
    treeData.map((property) => {
      return (
        <TreeNode value={property.id} title={property.name}>
          {property.child && property.child.length > 0 && renderTree(property.child)}
        </TreeNode>
      );
    });
    return <TreeNode value="xx" title="xxx" />;
  };

  const [progress, setProgress] = useState(0);
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileUpload, setFileUpload] = useState<any[]>();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  let lastFile = useRef(undefined);
  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    fmData.append("status", file.status);
    fmData.append("store", selectedStoreId);
    try {
      const res = await api.post("/api/products/media/", fmData, config);
      onSuccess("Ok");

      const new2 = { ...lastFile, url: res.data.data.source_url, id: res.data.data.id };
      if (fileUpload) {
        fileUpload.push(new2);
        setFileUpload(fileUpload);
      } else {
        const arr = [];
        arr.push(new2);
        setFileUpload(arr);
      }
      console.log("server res: ", res);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const handleFileChange = (uploadData: any) => {
    console.log(uploadData);
    // fileList.file
    setFileList(uploadData.fileList);
    lastFile = uploadData.file;
    if (uploadData.fileList.length == 0) {
      setFileUpload([]);
    }
  };

  const handlePageChange: PaginationProps["onChange"] = (pageNumber, pageSize) => {
    console.log("Page: ", pageNumber);
    const searchRequest = {
      ...requestParams,
      pageIndex: pageNumber == 0 ? 1 : pageNumber,
      storeId: selectedStoreId,
      pageSize: pageSize,
    };
    dispatch(getListCategories(searchRequest));
    setCurrentPage(pageNumber);
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedStoreId) {
      message.warning("Please select a store.");
      return;
    } else if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một item.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      //   content: 'Some descriptions',
      onOk() {
        console.log("OK");
        dispatch(deleteCategory(selectedStoreId, selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onSearch = (val: string) => {
    console.log(val);
    const searchRequest = {
      ...requestParams,
      keyword: val,
    };
    setRequestParams(searchRequest);
    dispatch(getListCategories(searchRequest));
  };

  const onDesciptionChange = (value: number) => {
    console.log(value);
    for (let i = 0; i < listDescriptions.length; i++) {
      if (listDescriptions[i].id == value) {
        form.setFieldsValue({
          description: listDescriptions[i].content,
        });
        break;
      }
    }
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Quản lý category</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360, paddingBottom: 50 }}
      >
        <div>
          <Space direction="horizontal">
            {hasPermission(permissions, PER_ADD_PRODUCT) && (
              <Button
                onClick={showDrawer}
                type="primary"
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
              >
                Thêm category
              </Button>
            )}

            {hasPermission(permissions, PER_DELETE_PRODUCT) && (
              <Button
                type="primary"
                onClick={showConfirm}
                style={{ marginBottom: 16 }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            )}
          </Space>
          <Search
            placeholder="Tìm kiếm"
            allowClear
            onSearch={onSearch}
            style={{ width: 200, float: "right" }}
          />
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listCategories}
            onChange={onChange}
            pagination={false}
          />
          <Pagination
            style={{ marginTop: 15, marginBottom: 20, float: "right" }}
            showSizeChanger={true}
            showTotal={(total) => `Total ${totalRecords} items`}
            defaultCurrent={1}
            total={totalRecords}
            current={currentPage}
            onChange={handlePageChange}
          />

          <Drawer
            title="Thêm mới category"
            width={720}
            onClose={onClose}
            maskClosable={false}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[{ required: true, message: "Tên category là thông tin bắt buộc." }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="parent" label="Parent">
                      <SeachableCategory
                        optionsVal={selectedCategoties}
                        onChange={(value) => {
                          setSelectedCategoties(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Select
                      style={{ width: "100%", marginBottom: 5 }}
                      placeholder="Chọn description từ template"
                      options={descriptionOptions}
                      onChange={onDesciptionChange}
                    ></Select>
                    <Form.Item name="description" label="Description">
                      <TextEditor
                        value={""}
                        placeholder={""}
                        onChange={handleChangeQuill}
                      ></TextEditor>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <div className="clearfix">
                      <Upload
                        customRequest={uploadImage}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleFileChange}
                      >
                        {fileList.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div className="ant-upload-text">Upload</div>
                          </div>
                        )}
                      </Upload>
                      <Modal visible={previewVisible} footer={null}>
                        <img alt="example" style={{ width: "100%" }} src={previewImage} />
                      </Modal>
                    </div>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default CategoryComponent;

import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  message,
} from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PER_ADD_USER, PER_DELETE_USER } from "../../common/cms_const";
import { hasPermission, renderHTML } from "../../common/common_utils";
import { ProductTypeModel } from "../../models/models";
import { appSelector } from "../../store/slices/app-slice";
import {
  addProductType,
  deleteProductType,
  getlistProductType,
  productTypeSelector,
  setRequestStatus,
  updateProductType,
} from "../../store/slices/productType-slide";

const ProductType: React.FC = () => {
  const dispatch = useDispatch();
  const { appLoading, permissions } = useSelector(appSelector);
  const { listProductType, requestStatus } = useSelector(productTypeSelector);
  const [fileList, setFileList] = useState<any[]>([]);
  const [formTitle, setFormTitle] = useState("");
  const [requestParams, setRequestParams] = useState<any>({
    keyword: "",
  });

  useEffect(() => {
    dispatch(getlistProductType(requestParams));
  }, []);

  useEffect(() => {
    if (requestStatus) {
      dispatch(getlistProductType(requestParams));
      setVisible(false);
      dispatch(setRequestStatus(false));
    }
  }, [requestStatus]);

  const renderStatus = (status: number) => {
    if (status) {
      return <Tag color={"green"}>Active</Tag>;
    } else {
      return <Tag color={"volcano"}>Inactive</Tag>;
    }
  };

  const onFinish = (data: any) => {
    console.log(data);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    if (data.images) {
      data.images = data.images.file;
    } else {
      if (fileList.length > 0) {
        data.images = fileList[0].name;
      } else {
        data.images = undefined;
      }
    }

    data.timing = moment(data.timing).format("YYYY-MM-DD");
    if (data.id) {
      dispatch(updateProductType(data, config));
    } else {
      dispatch(addProductType(data, config));
    }
  };

  const [form] = Form.useForm();
  const loadUpdate = (data: any) => {
    setVisible(true);
    setFormTitle("Update");

    let listImg: any[] = [];
    if (data.images) {
      const fileData = {
        status: "done",
        name: data.images,
        thumbUrl: process.env.REACT_APP_API_SERVER_URL + data.images,
        url: process.env.REACT_APP_API_SERVER_URL + data.images,
      };
      listImg.push(fileData);
    }
    setFileList(listImg);

    form.setFieldsValue({
      id: data.id,
      name: data.name,
      status: String(data.status),
      fulfillment: data.fulfillment,
    });
  };

  const columns: ColumnsType<ProductTypeModel> = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"],
    },
    {
      title: "Tên",
      dataIndex: "name",
      render: (_, record) => (
        <>
          <a onClick={() => loadUpdate(record)}>{record.name}</a>
        </>
      ),
    },
    {
      title: "Fulfillment",
      dataIndex: "name",
      width: 600,
      render: (_, record) => (
        <>
          <Paragraph ellipsis={{ rows: 2 }}>{renderHTML(record.fulfillment)}</Paragraph>
        </>
      ),
    },
    {
      title: "Ngày tạo",
      render: (_, record) => <>{moment(record.created_date).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Trạng thái",
      dataIndex: "username",
      render: (_, record) => <>{renderStatus(record.status)}</>,
    },
  ];

  const onChange: TableProps<ProductTypeModel>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
    setFormTitle("Thêm mới");
    setFileList([]);
    form.setFieldsValue({
      id: "",
      name: "",
      status: "",
      fulfillment: "",
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // table checkbox
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    if (!selectedRowKeys || selectedRowKeys.length <= 0) {
      message.warning("Chọn ít nhất một item.");
      return;
    }

    let msgConfirm = "Bạn chắc muốn xóa không?";
    confirm({
      title: msgConfirm,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        dispatch(deleteProductType(selectedRowKeys));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onSearch = (keyword: string) => {
    console.log("");
    const searchRequest = {
      keyword: keyword,
    };
    dispatch(getlistProductType(searchRequest));
  };

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Danh sách loại sản phẩm</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div>
          <Space direction="horizontal">
            {hasPermission(permissions, PER_ADD_USER) && (
              <Button
                onClick={showDrawer}
                type="primary"
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
              >
                Thêm mới
              </Button>
            )}

            {hasPermission(permissions, PER_DELETE_USER) && (
              <Button
                type="primary"
                onClick={showConfirm}
                style={{ marginBottom: 16 }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            )}
          </Space>
          <Space direction="horizontal" style={{ float: "right" }}>
            <Search
              placeholder="Tìm kiếm"
              allowClear
              onSearch={onSearch}
              style={{ width: 200, float: "right" }}
            />
          </Space>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={listProductType}
            onChange={onChange}
          />

          <Drawer
            title={formTitle}
            width={900}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Spin spinning={appLoading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item hidden name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>
                  <Col md={24} xs={24}>
                    <Form.Item
                      name="name"
                      label="Tên loại sản phẩm"
                      rules={[
                        { required: true, message: "Tên loại sản phẩm là thông tin bắt buộc." },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Trạng thái"
                      name="status"
                      rules={[{ required: true, message: "Trạng thái là thông tin bắt buộc." }]}
                    >
                      <Radio.Group>
                        <Radio value="1">Kích hoạt</Radio>
                        <Radio value="0">Chưa kích hoạt</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="fulfillment" label="Fulfillment">
                      <Input.TextArea rows={4} placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ float: "right" }}>
                  <Col>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        Submit
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default ProductType;
